import {createGlobalStyle} from "styled-components"

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;1,700&display=swap');

*,*::before,*::after{
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Poppins', sans-serif;
}
html {
  /* 1rem = 10px */
  font-size: 62.5%;
}
body {
  font-size: 11px;
}

`;
