import styled from "styled-components";

export const ModalButtonWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 300px;
margin: 0 auto 60px;
`;

export const P = styled.p`
text-align: center;
margin: 50px auto;
font-size: 1.6rem;
color: ${({theme: {colors: {red}}}) => red};
font-weight: 600;
`
