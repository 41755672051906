import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import styled from "styled-components";
import {FileButton, Input, Label, Select} from "../../components/Form";
import {ClientContactInfo} from "../../components/ClientContactInfo";
import {VehicleInfo} from "../../components/VehicleInfo";
import {ToDoInfo} from "../../components/ToDoInfo";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {StyledTextarea} from "../../components/styled/inputs";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  margin-right: 60px;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 240px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
  grid-template-columns: 480px;
  align-items: center;
`;

const InputWrapperWithCheckbox = styled(InputWrapper)`
  align-items: center;
  justify-content: flex-start;
`;

const InputWithCheckbox = styled(InputWrapper)`
  grid-template-columns: 40px 200px;
  align-items: center;
`;

const InputFileWrapper = styled.label`
  display: grid;
  grid-template-columns: 160px auto auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
  }
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
`;

const ClientInfoWrapper = styled.div`
  margin-left: 20px;
`;

const LabelWithCheckboxRight = styled(Label)`
  display: flex;

  span {
    margin-left: 5px;
  }
`

const LabelWithCheckboxLeft = styled(Label)`
  display: flex;

  span {
    margin-right: 5px;
  }
`

export const InsurancePoliciesForm = ({id, initialValues, vehicle, client, toDo, update}) => {
    const [signed, setSigned] = useState(null);
    const [pictures, setPictures] = useState(null);
    const [paymentConfirmation, setPaymentConfirmation] = useState(null);
    const [companies, setCompanies] = useState([]);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios.get(`${apiUrl}/companies/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({
                     data: {
                         companies: insuranceCompanies
                     }
                 }) => {
            setCompanies(insuranceCompanies);
        });
    }, [token]);


    return (
        <>
            <Grid>
                <Form
                    initialValues={{...initialValues}}
                    onSubmit={values => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            let value = values[key] ?? '';
                            if (value === true) {
                                value = 1;
                            } else if (value === false) {
                                value = 0;
                            }
                            formData.append(key, value)
                        });
                        if (signed) {
                            formData.append('signed', signed);
                        }
                        if (pictures) {
                            formData.append('pictures', pictures);
                        }
                        if (paymentConfirmation) {
                            formData.append('paymentConfirmation', paymentConfirmation);
                        }
                        formData.append('_method', 'PUT')
                        axios.post(`${apiUrl}/policy/${id}`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Dane zostały zapisane.");
                            setSigned(null);
                            setPictures(null);
                            setPaymentConfirmation(null);
                            update();
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <h2>Polisa</h2>
                                <Field
                                    name='number'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Polisa nr*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}
                                />
                                <Field
                                    name='issuedAt'
                                    type="date"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Data wystawienia*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='insurancePremium'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Składka finalna*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='startedAt'
                                    type="date"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Data rozpoczęcia polisy*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='endedAt'
                                    type="date"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Data zakończenia polisy*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='refinanced'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Składka refinansowana*</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Składka refinansowana</option>
                                                    <option value="Tak">Tak</option>
                                                    <option value="Nie">Nie</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='paidAt'
                                    type="date"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Data zapłaty*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='denunciation'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Wypowiedzenie*</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Wypowiedzenie</option>
                                                    <option value="Tak">Tak</option>
                                                    <option value="Nie">Nie</option>
                                                    <option value="Nie dotyczy">Nie dotyczy</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='payment_deadline'
                                    type="date"
                                    render={({input}) => (
                                        <InputWrapper>
                                            <Label>Termin płatności</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    )}
                                />
                                <Field
                                    name='confirmation_leasing'
                                    type="date"
                                    render={({input}) => (
                                        <InputWrapper>
                                            <Label>Potwierdzenie do leasingu</Label>
                                            <Input {...input} id={input.name} placeholder=""
                                                   onChange={(e) => {
                                                       input.onChange(e);
                                                       const tempDate = e.target.value;
                                                       const year  = new Date(tempDate).getFullYear();
                                                       const month = new Date(tempDate).getMonth();
                                                       const day   = new Date(tempDate).getDate();
                                                       const d  = new Date(year + 1, month, day);
                                                       const datestring =d.getFullYear()+'-'+("0"+(d.getMonth()+1)).slice(-2)+'-'+("0" + d.getDate()).slice(-2);
                                                       form.mutators.setValue("reminder", datestring)
                                                   }
                                                   }
                                            />
                                        </InputWrapper>
                                    )}
                                />
                                <Field
                                    name='reminder'
                                    type="date"
                                    render={({input}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Data przypomnienia</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}
                                />

                                <Field
                                    name='isSigned'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Polisa podpisana</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>


                                <Field
                                    name='paymentConfirmation_path'
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper
                                                htmlFor={input.name}
                                                onChange={(e) => {
                                                    input.onChange(e);
                                                    setPaymentConfirmation(e.target.files[0]);
                                                }}
                                            >
                                                <input {...input} id={input.name}/>
                                                <span>Potwierdzenie zapłaty składki/refinansowania*</span>
                                                <FileButton>{paymentConfirmation ? paymentConfirmation.name : "Wybierz plik"}</FileButton>
                                                {values.paymentConfirmation &&
                                                <StyledA href={values.paymentConfirmation} rel="noopener noreferrer"
                                                         target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>


                                <Field
                                    name='pictures_path'
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper
                                                htmlFor={input.name}
                                                onChange={(e) => {
                                                    input.onChange(e);
                                                    setPictures(e.target.files[0]);
                                                }}
                                            >
                                                <input {...input} id={input.name}/>
                                                <span>Zdjęcie*</span>
                                                <FileButton>{pictures ? pictures.name : "Wybierz plik"}</FileButton>
                                                {values.pictures &&
                                                <StyledA href={values.pictures} rel="noopener noreferrer"
                                                         target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>


                                <Field
                                    name='signed_path'
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper
                                                htmlFor={input.name}
                                                onChange={(e) => {
                                                    input.onChange(e);
                                                    setSigned(e.target.files[0]);
                                                }}
                                            >
                                                <input {...input} id={input.name}/>
                                                <span>Podpisana polisa</span>
                                                <FileButton>{signed ? signed.name : "Wybierz plik"}</FileButton>
                                                {values.signed &&
                                                <StyledA href={values.signed} rel="noopener noreferrer"
                                                         target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>
                                <h2>Zapytanie ofertowe</h2>
                                <Field
                                    name='time'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Czas realizacji</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='vatSumInsured'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Suma ubezpieczenia VAT</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Suma ubezpieczenia VAT</option>
                                                    <option value="Netto + 50%">Netto + 50%</option>
                                                    <option value="Brutto">Brutto</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='transfer'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Cesja</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Cesja</option>
                                                    <option value="Nie">Nie</option>
                                                    <option value="Tak">Tak</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='co_ownership'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Współwłasność</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Współwłasność</option>
                                                    <option value="Finansujący">Finansujący</option>
                                                    <option value="Inny">Inny</option>
                                                    <option value="Nie">Nie</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <h4>ZAKRES UBEZPIECZENIA</h4>

                                <Field
                                    name='oc'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxRight>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    <span>OC</span>
                                                </LabelWithCheckboxRight>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='directLossAdjustment'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>- Bezpośrednia Likwidacja Szkód</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='liabilityDiscountProtection'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>- Ochrona Zniżki OC</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>


                                <Field
                                    name='ac'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxRight>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    <span>AC + KR</span>
                                                </LabelWithCheckboxRight>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='aso'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>ASO</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='affiliateNetwork'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>Sieć Partnerska</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>
                                <Field
                                    name='estimate'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>Kosztorys</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='sumConsumption'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>- Konsumpcja sumy</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Konsumpcja sumy</option>
                                                    <option value="wprowadzona">wprowadzona</option>
                                                    <option value="zniesiona">zniesiona</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='fixedSumInsured'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>- Stała suma ubezp.</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='acDiscountProtection'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>- Ochrona zniżki AC</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='nnw'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxRight>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    <span>NNW</span>
                                                </LabelWithCheckboxRight>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>
                                <Field
                                    name='windows'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxRight>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    <span>Szyby</span>
                                                </LabelWithCheckboxRight>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>
                                <Field
                                    name='assistance'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxRight>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    <span>Holowanie / Assistance</span>
                                                </LabelWithCheckboxRight>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>
                                <Field
                                    name='assistanceCountry'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>- Assistance kraj</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>
                                <Field
                                    name='assistanceEU'
                                    type="checkbox"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapperWithoutLabel>
                                                <LabelWithCheckboxLeft>
                                                    <span>- Assistance EU</span>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </LabelWithCheckboxLeft>
                                            </InputWrapperWithoutLabel>
                                        </>
                                    )}/>

                                <Field
                                    name='foreignPolicyCost'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Koszt polisa obca</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>


                                <Field
                                    name='payment'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Płatność</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Konsumpcja sumy</option>
                                                    <option value="Jednorazowo">Jednorazowo</option>
                                                    <option value="Finansowanie Leasing">Finansowanie Leasing
                                                    </option>
                                                    <option value="Raty Ubezpieczyciel">Raty Ubezpieczyciel</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='paidPremium'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Składka płatna</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Składka płatna</option>
                                                    <option value="Składka jednorazowa">Składka jednorazowa</option>
                                                    <option value="Składka 2 raty">Składka 2 raty</option>
                                                    <option value="Składka 4 raty">Składka 4 raty</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>


                                <Field
                                    name='additionalInformation'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Informacje dodatkowe</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Button>Zapisz</Button>
                            </div>
                            <div>
                                <h2>Oferta polisy</h2>
                                <Field
                                    name="tu"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>TU</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Firma</option>
                                                    {companies.map(({id, name}) => (
                                                        <option value={id}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    type="number"
                                    parse={value => (value === "" ? null : value)}
                                    min={0}
                                    name="sumInsured"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Suma ubezpieczenia</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <InputWrapperWithCheckbox>
                                    <Label>OC</Label>
                                    <InputWithCheckbox>
                                        <Field
                                            name="isOc"
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                        <Field
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            name="secondOc"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                    </InputWithCheckbox>
                                </InputWrapperWithCheckbox>

                                <InputWrapperWithCheckbox>
                                    <Label>- Ochrona zniżki OC</Label>
                                    <InputWithCheckbox>
                                        <Field
                                            name="isLiabilityDiscountProtection"
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                        <Field
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            name="SecondLiabilityDiscountProtection"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                    </InputWithCheckbox>
                                </InputWrapperWithCheckbox>

                                <InputWrapperWithCheckbox>
                                    <Label>- BLS</Label>
                                    <InputWithCheckbox>
                                        <Field
                                            name="isBls"
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                        <Field
                                            name="bls"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                    </InputWithCheckbox>
                                </InputWrapperWithCheckbox>

                                <InputWrapperWithCheckbox>
                                    <Label>- AC + KR</Label>
                                    <InputWithCheckbox>
                                        <Field
                                            name="isAc"
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                        <Field
                                            name="secondAc"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                    </InputWithCheckbox>
                                </InputWrapperWithCheckbox>

                                <InputWrapperWithCheckbox>
                                    <Label>Stała suma</Label>
                                    <InputWithCheckbox>
                                        <Field
                                            name="isFixedSum"
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                        <Field
                                            name="fixedSum"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                    </InputWithCheckbox>
                                </InputWrapperWithCheckbox>

                                <InputWrapperWithCheckbox>
                                    <Label>- Ochrona zniżki AC</Label>
                                    <Field
                                        name="secondAcDiscountProtection"
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>

                                <InputWrapperWithCheckbox>
                                    <Label>- Konsumpcja sumy</Label>
                                    <Field
                                        name="secondSumConsumption"
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>

                                <Field
                                    name="ownShare"
                                    type="number"
                                    parse={value => (value === "" ? null : value)}
                                    min={0}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Udział własny</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <InputWrapperWithCheckbox>
                                    <Label>- Każdy: wiek/dł. jazdy</Label>
                                    <Field
                                        name="lengthDriving"
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>


                                <Field
                                    name="pzu"
                                    type="number"
                                    parse={value => (value === "" ? null : value)}
                                    min={0}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>- dla PZU</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>


                                <InputWrapperWithCheckbox>
                                    <Label>- Bagaż</Label>
                                    <InputWithCheckbox>
                                        <Field
                                            name="isLuggage"
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                        <Field
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            name="luggage"
                                            render={({input, meta}) => (
                                                <>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </>
                                            )}/>
                                    </InputWithCheckbox>
                                </InputWrapperWithCheckbox>


                                <InputWrapperWithCheckbox>
                                    <Label>- Szkoda kradzieżowa na
                                        terytorium Rosji, Białorusi,
                                        Ukrainy, Mołdawii</Label>
                                    <Field
                                        name="pityTheft"
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>

                                <Field
                                    name="secondNnw"
                                    type="number"
                                    parse={value => (value === "" ? null : value)}
                                    min={0}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>NNW</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name="secondWindows"
                                    type="number"
                                    parse={value => (value === "" ? null : value)}
                                    min={0}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Szyby</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name="towing"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Holowanie</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Rodzaj przedmiotu</option>
                                                    <option value="Samochód osobowy">Samochód
                                                        osobowy
                                                    </option>
                                                    <option
                                                        value="Samochód ciężarowy do 3,5 t">Samochód
                                                        ciężarowy
                                                        do
                                                        3,5 t
                                                    </option>
                                                    <option
                                                        value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                        ciężarowy
                                                        powyżej 3,5 t
                                                    </option>
                                                    <option value="Ciągnik samochodowy">Ciągnik
                                                        samochodowy
                                                    </option>
                                                    <option value="Naczepa">Naczepa</option>
                                                    <option value="Przyczepa ">Przyczepa</option>
                                                    <option value="Maszyna">Maszyna</option>
                                                    <option value="Motocykl">Motocykl</option>
                                                    <option value="Inne">Inne</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name="singlePremium"
                                    type="number"
                                    parse={value => (value === "" ? null : value)}
                                    min={0}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Składka jednorazowa</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name="secondAdditionalInformation"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Informacje dodatkowe</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='note'
                                    render={({input}) => (
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Notatka</Label>
                                            <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                        </InputWrapper>
                                    )}/>
                            </div>
                        </StyledForm>
                    )}
                />

                <div>
                    {client && (
                        <ClientInfoWrapper>
                            <h4>Klient:</h4>
                            <ClientContactInfo clientSimple={client}/>
                        </ClientInfoWrapper>
                    )}
                    {vehicle && (
                        <ClientInfoWrapper>
                            <h4>Pojazd:</h4>
                            <VehicleInfo vehicle={vehicle}/>
                        </ClientInfoWrapper>
                    )}
                    {toDo && (
                        <ClientInfoWrapper>
                            <h4>Zadnie:</h4>
                            <ToDoInfo toDo={toDo}/>
                        </ClientInfoWrapper>
                    )}
                </div>
            </Grid>
        </>
    );
};
