import React, {useEffect, useState} from 'react';
import {InputSearch, Table} from "../../components/Table";
import {StyledFontAwesomeIcon, StyledPanelContentWrapper} from "../../components/ListComponents";
import {faArrowRight, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import {NEW_TO_DO} from "../../actions";
import {perPage} from "../../data/variables";

const StyledButton = styled.button`
  border: none;
  background: transparent;
  color: ${({theme: {colors: {red}}}) => red};;
  font-weight: 900;
  font-size: 1.6rem;
  top: 5px;
  right: 5px;
`;

const NewStatus = styled.span`
    color: red;
`;

export const ToDoList = ({archived}) => {
    const [toDo, setToDo] = useState([]);
    const token = useSelector((store) => store.token);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [change, setChange] = useState(1);
    let timeout = 0;
    const dispatch = useDispatch();

    const search = (e) => {
        let searchText = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/toDo`, {
            params: {
                archived: archived,
                page,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {toDos, lastPage, countToDoWithNewMessages}}) => {
            setToDo(toDos);
            setPages(lastPage);
            dispatch({
                type: NEW_TO_DO,
                payload: {newToDo:countToDoWithNewMessages}
            });
        });
    }, [token, archived, page, searchParam, change])

    return (
        <>
            <div>
                <InputSearch onChange={(e) => {
                    search(e);
                }}/>
            </div>
            <ToastContainer
                position="bottom-right"
            />
            <StyledPanelContentWrapper>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Data założenia sprawy</th>
                        <th>Imię i nazwisko/Firma</th>
                        <th>Pojazd marka</th>
                        <th>Finansujący</th>
                        <th>Wiadomość</th>
                        <th>Samochód/Przedmiot</th>
                        <th>Finansowanie</th>
                        <th>Oferta polisy</th>
                        <th>Rejestracja/Płatności</th>
                        <th>Status zmieniony</th>
                        <th>Duplikat</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {toDo.map(({
                                   id,
                                   status,
                                   status_updated_at,
                                   name,
                                   createdAt,
                                   read,
                                   vehicle_status,
                                   finance_status,
                                   policy_offer_status,
                                   registration_status,
                                   duplicate,
                                   parent_id,
                                   vehicleMark,
                                   institution
                               }, key) => (
                        <tr>
                            <td>{(page-1) * perPage + key + 1}</td>
                            <td>{id}</td>
                            <td>{status ? (
                                <NewStatus>Nowe</NewStatus>
                            ):(
                                <span>-</span>
                            )}</td>
                            <td>{createdAt}</td>
                            <td>{name}</td>
                            <td>{vehicleMark ?? '-'}</td>
                            <td>{institution ?? '-'}</td>
                            <td>
                                {read === null && ("Brak wiadomości")}
                                {/* eslint-disable-next-line eqeqeq */}
                                {read == 1 && ("Brak nowych wiadomości")}
                                {/* eslint-disable-next-line eqeqeq */}
                                {read == 0 && ("Nowe wiadomości")}
                            </td>
                            <td>{vehicle_status}</td>
                            <td>{finance_status}</td>
                            <td>{policy_offer_status}</td>
                            <td>{registration_status}</td>
                            <td>{status_updated_at ?? '-'}</td>
                            <td>
                                {duplicate ? (
                                    <>
                                        {parent_id !== null ? (
                                            <Link to={`/zadania/${parent_id}`}>
                                                Tak
                                            </Link>
                                        ) : (<>Tak</>)}
                                    </>
                                ) : (
                                    <>Nie</>
                                )}
                            </td>
                            <td style={{display: "flex"}}>
                                <StyledButton onClick={() => {
                                    setOpenModal(id);
                                }}>
                                    <StyledFontAwesomeIcon size="2x" icon={faTrashAlt} fixedWidth/>
                                </StyledButton>
                                <Link to={`/zadania/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </StyledPanelContentWrapper>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/toDo/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało usunięte.");
                            setChange(change + 1);
                        }).catch((e) => {
                            toast.error("Nie możesz usunać zadania.");
                        }).finally(() => {
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
