import React, {useEffect, useState} from 'react';
import {InputSearch, Table} from "../../components/Table";
import {faArrowRight, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {StyledFontAwesomeIcon, StyledLink, StyledPanelContentWrapper} from "../../components/ListComponents";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {Button} from "../../components/Buttons";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {toast, ToastContainer} from "react-toastify";
import {perPage} from "../../data/variables";

const IconsWrapper = styled.div`
    display: flex;
`;
const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;


export const UsersList = () => {
    const token = useSelector((store) => store.token);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [change, setChange] = useState(0);
    const [searchParam, setSearchParam] = useState("");
    let timeout = 0;

    const search = (e) => {
        let searchText = e.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/user`, {
            params:{
                page,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users:newUsers,lastPage}}) => {
            setUsers(newUsers);
            setPages(lastPage);
        });
    }, [token,page,change,searchParam])

    return (
        <>
            <div>
                <InputSearch onChange={(e) => {
                    search(e);
                }}/>
            </div>
            <ToastContainer
                position="bottom-right"
            />
            <StyledPanelContentWrapper>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Imię i nazwisko</th>
                        <th>E-mail</th>
                        <th>Uprawnienie</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(({id,name,email,role}, key) => (
                        <tr key={id}>
                            <td>{(page-1) * perPage + key + 1}</td>
                            <td>{name}</td>
                            <td>{email}</td>
                            <td>{role}</td>
                            <td style={{display:"flex"}}>
                                <IconsWrapper>
                                    <StyledButton onClick={() => {
                                        setOpenModal(id);
                                    }}>
                                        <StyledFontAwesomeIcon size="2x"  icon={faTrashAlt} fixedWidth/>
                                    </StyledButton>
                                    <StyledLink to={`/uzytkownicy/${id}`}>
                                        <StyledFontAwesomeIcon size="2x"  icon={faArrowRight} fixedWidth/>
                                    </StyledLink>
                                </IconsWrapper>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </StyledPanelContentWrapper>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć użytkownika?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/user/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Użytkownik został usunięty.");
                            setChange(change+1);
                        }).catch((e)=>{
                            if (e.response?.status === 403) {
                                toast.error("Nie możesz usunać siebie.");
                            }
                            else{
                                toast.error(e.message);
                            }
                        }).finally(()=>{
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
