import {Field, Form} from "react-final-form";
import {Input, InputFloating, Label, RoundSimpleButton} from "./Form";
import {Button} from "./Buttons";
import React from "react";
import styled from "styled-components";
import arrayMutators from "final-form-arrays";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";

const StyledForm = styled.form`
  max-width: 100%;
  width: 460px;
`;

const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  margin-right: 50px;
`;

const StyledTasksWrapper = styled(TasksWrapper)`
    margin-top: -35px;
`

const TasksActionWrapper = styled.div`
  flex-direction: row;
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
  padding-bottom: 15px;
  margin: 10px 0;
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 450px;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  border: none;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InsuranceCompanyForm = ({onSubmit, initialValues}) => {
    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators
            }}
            initialValues={initialValues}
            render={({handleSubmit}) => (
                <StyledForm onSubmit={handleSubmit}>
                    <Field
                        name="name"
                        type="text"
                        render={({input, meta}) => (
                            <InputFloating label="Nazwa" {...input} {...meta}/>
                        )}
                    />
                    <FieldsWrapper>
                        <FieldArray name="constantFields">
                            {({fields}) => (
                                <TasksWrapper>
                                    {fields.map((name, index) => (
                                            <Grid key={index}>
                                                <Field
                                                    name={`${name}.name`}
                                                    type="hidden"
                                                    render={({input, meta}) => (
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    )}/>
                                                <Field
                                                    name={`${name}.title`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Tytuł</Label>
                                                                <Input {...input} id={input.name} placeholder="" readOnly/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.description`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Opis</Label>
                                                                <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                            </Grid>
                                        )
                                    )}

                                </TasksWrapper>
                            )}
                        </FieldArray>
                        <FieldArray name="towingFields">
                            {({fields}) => (
                                <StyledTasksWrapper>
                                    <TasksActionWrapper>
                                        <RoundSimpleButton
                                            type="button"
                                            onClick={() => fields.push({})}>
                                            <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                        </RoundSimpleButton>
                                        <RoundSimpleButton
                                            type="button"
                                            onClick={() => fields.pop()}>
                                            <FontAwesomeIcon icon={faMinus} color="white" size="2x"/>
                                        </RoundSimpleButton>
                                    </TasksActionWrapper>

                                    {fields.map((name, index) => (
                                            <Grid key={index}>
                                                <Field
                                                    name={`${name}.title`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Tytuł</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.description`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Opis</Label>
                                                                <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                            </Grid>
                                        )
                                    )}

                                </StyledTasksWrapper>
                            )}
                        </FieldArray>
                    </FieldsWrapper>
                    <Button>Zapisz</Button>
                </StyledForm>
            )}
        />
    );
}
