import React, {useEffect, useState} from 'react';
import {Table} from "../../components/Table";
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {Pagination} from '@material-ui/lab';
import {apiUrl} from "../api";

export const ClientsInsurancePolicy = ({clientId}) => {
    const [policies, setPolicies] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios.get(`${apiUrl}/client/${clientId}/policy`, {
            params: {
                page
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {policies: newPolicies, lastPage}}) => {
            setPolicies(newPolicies);
            setPages(lastPage);
        });
    }, [clientId, token, page])

    return (
        <>
            <div>
                <Table>
                    <thead>
                    <tr>
                        <th>TU</th>
                        <th>Nr polisy</th>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Nr rejestracyjny</th>
                        <th>Data początku</th>
                        <th>Data końca</th>
                        <th>Wystawca polisy</th>
                        <th>Klient</th>
                        <th>Doprowadzający</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {policies.map(({id,tu,number,vehicleMark,vehicleModel,vehicleRegistrationNumber,startedAt,endedAt,userName,clientName, clientLeader}) => (
                        <tr>
                            <td>{tu ?? '-'}</td>
                            <td>{number ?? '-'}</td>
                            <td>{vehicleMark ?? '-'}</td>
                            <td>{vehicleModel ?? '-'}</td>
                            <td>{vehicleRegistrationNumber ?? '-'}</td>
                            <td>{startedAt ?? '-'}</td>
                            <td>{endedAt ?? '-'}</td>
                            <td>{userName ?? '-'}</td>
                            <td>{clientName ?? '-'}</td>
                            <td>{clientLeader ?? '-'}</td>
                            <td style={{display: "flex"}}>
                                <StyledLink to={`/polisy/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </div>
        </>
    );
};
