import React, {useEffect, useState} from 'react';
import {FileButton, Input, Label, RoundButton, Select, Switch, TopFormWrapper} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 240px;
  align-items: center;
  margin-bottom: 10px;
`;

const InputFileWrapper = styled.label`
  display: grid;
  grid-template-columns: 140px auto auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
  }
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
`;


export const PolicyTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [policy, setPolicy] = useState({});
    const [clientName, setClientName] = useState(null);
    const [signed, setSigned] = useState(null);
    const [pictures, setPictures] = useState(null);
    const [paymentConfirmation, setPaymentConfirmation] = useState(null);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/policy`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {policy: newPolicy, isActive, clientFullName}}) => {
                setIsEnabled(isActive === 1);
                setPolicy(newPolicy);
                setClientName(clientFullName);
            });
        }
    }, [token, toDoId]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => {
                axios.post(`${apiUrl}/toDos/${toDoId}/policy/toggle`, {
                    isActive: !isEnabled,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(({data: {policy: newPolicy}}) => {
                    setPolicy(newPolicy);
                    toast.success("Dane zostały zapisane.");
                }).catch(() => {
                    toast.error("Popraw dane");
                });
                setIsEnabled(!isEnabled)
            }}/>
            <TopFormWrapper>
                <div>
                    <h2>Polisa</h2>
                    {isEnabled === true && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>

            {isEnabled === true && <>
                <Form

                    onSubmit={values => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            let value = values[key] ?? '';
                            if (value === true) {
                                value = 1;
                            } else if (value === false) {
                                value = 0;
                            }
                            formData.append(key, value)
                        });
                        if (signed) {
                            formData.append('signed', signed);
                        }
                        if (pictures) {
                            formData.append('pictures', pictures);
                        }
                        if (paymentConfirmation) {
                            formData.append('paymentConfirmation', paymentConfirmation);
                        }

                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }

                        axios.post(`${apiUrl}/toDos/${toDoId}/policy`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {policy: newPolicy, isActive}}) => {
                            setIsEnabled(isActive);
                            setPolicy(newPolicy);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    initialValues={{...policy, isActive: true}}
                    render={({handleSubmit, form, values}) => (
                        <form onSubmit={handleSubmit} encType='multipart/form-data'>
                            <Field
                                name={`tu`}
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>TU - Wystawca polisy</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="">TU - Wystawca polisy</option>
                                                <option value="VAZUVI">VAZUVI</option>
                                                <option value="Obce">Obce</option>
                                            </Select>
                                        </InputWrapper>
                                        {(meta.error || meta.submitError) && meta.touched && (
                                            <span>{meta.error || meta.submitError}</span>
                                        )}
                                    </>
                                )}/>
                            <Field
                                name='number'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Polisa nr*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='issuedAt'
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data wystawienia*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='insurancePremium'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Składka finalna*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='startedAt'
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data rozpoczęcia polisy*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='endedAt'
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data zakończenia polisy*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='refinanced'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Składka refinansowana*</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="">Składka refinansowana</option>
                                                <option value="Tak">Tak</option>
                                                <option value="Nie">Nie</option>
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='paidAt'
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data zapłaty*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='denunciation'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Wypowiedzenie*</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="">Wypowiedzenie</option>
                                                <option value="Tak">Tak</option>
                                                <option value="Nie">Nie</option>
                                                <option value="Nie dotyczy">Nie dotyczy</option>
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='payment_deadline'
                                type="date"
                                render={({input}) => (
                                    <InputWrapper>
                                        <Label>Termin płatności</Label>
                                        <Input {...input} id={input.name} placeholder=""/>
                                    </InputWrapper>
                                )}
                            />
                            <Field
                                name='confirmation_leasing'
                                type="date"
                                render={({input}) => (
                                    <InputWrapper>
                                        <Label>Potwierdzenie do leasingu</Label>
                                        <Input {...input} id={input.name} placeholder=""
                                               onChange={(e) => {
                                                   input.onChange(e);
                                                   const tempDate = e.target.value;
                                                   const year  = new Date(tempDate).getFullYear();
                                                   const month = new Date(tempDate).getMonth();
                                                   const day   = new Date(tempDate).getDate();
                                                   const d  = new Date(year + 1, month, day);
                                                   const datestring =d.getFullYear()+'-'+("0"+(d.getMonth()+1)).slice(-2)+'-'+("0" + d.getDate()).slice(-2);
                                                   form.mutators.setValue("reminder", datestring)
                                               }
                                               }
                                        />
                                    </InputWrapper>
                                )}
                            />
                            <Field
                                name='reminder'
                                type="date"
                                render={({input}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data przypomnienia</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}
                            />

                            <Field
                                name='isSigned'
                                type="checkbox"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Polisa podpisana</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='requiredImages'
                                type="checkbox"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Zdjęcia wymagane</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>


                            <Field
                                name='paymentConfirmation_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setPaymentConfirmation(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Potwierdzenie zapłaty składki/refinansowania*</span>
                                            <FileButton>{paymentConfirmation ? paymentConfirmation.name : "Wybierz plik"}</FileButton>
                                            {values.paymentConfirmation &&
                                            <StyledA href={values.paymentConfirmation} rel="noopener noreferrer"
                                                     target="_blank">Zobacz</StyledA>}
                                        </InputFileWrapper>
                                    </>
                                )}/>


                            <Field
                                name='pictures_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setPictures(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Zdjęcie*</span>
                                            <FileButton>{pictures ? pictures.name : "Wybierz plik"}</FileButton>
                                            {values.pictures &&
                                            <StyledA href={values.pictures} rel="noopener noreferrer"
                                                     target="_blank">Zobacz</StyledA>}
                                        </InputFileWrapper>
                                    </>
                                )}/>


                            <Field
                                name='signed_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setSigned(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Podpisana polisa</span>
                                            <FileButton>{signed ? signed.name : "Wybierz plik"}</FileButton>
                                            {values.signed && <StyledA href={values.signed} rel="noopener noreferrer"
                                                                       target="_blank">Zobacz</StyledA>}
                                        </InputFileWrapper>
                                    </>
                                )}/>

                            <Files name={`files`} index={0} setFilesToForm={setFilesToForm}/>
                            {!archived && (
                                <Button>Zapisz</Button>
                            )}
                        </form>
                    )}
                />
            </>}
        </>
    );
};
