import axios from "axios";

export const downloadFile = (url,token,name) => {
    axios.get(url, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    });
}
