import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const StyledImg = styled.img`
  width: 100px;
  margin: 0 10px;
`;

export const Link = ({url}) => {
    const [link, setLink] = useState(url);

    const checkURL = (url) => {
        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    useEffect(() => {
        setLink(url)
    }, [url])

    if (checkURL(link)) {
        return (
            <StyledImg src={link} alt=""/>
        );
    }
    return (
        <a target="_blank" href={link}>{link}</a>
    );
}
