import styled from "styled-components";

const PanelContentWrapper = styled.div`
background: ${({theme: {colors: {lightGray}}}) => lightGray};
padding: 30px 40px;
overflow: scroll;
max-height: calc(100vh - 200px);
height: 100%;
margin: 40px 20px 20px;
border-radius: 20px;
`;
export default PanelContentWrapper
