import React, {useState} from 'react';
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Redirect} from "react-router-dom";
import {InsuranceCompanyForm} from "../../components/InsuranceCompanyForm";

export const CreateInsuranceCompany = () => {
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <h2>Dodaj firmę</h2>
                <InsuranceCompanyForm
                    onSubmit={values => {
                        console.log(values);
                        axios.post(`${apiUrl}/companies`, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect(`/firmy`)
                        });
                    }}
                    initialValues={{
                        name: '',
                        constantFields: [
                            {
                                title: 'OC',
                                name: 'isOc',
                                description: '',
                            },
                            {
                                title: 'OCHRONA ZNIŻKI OC',
                                name: 'isLiabilityDiscountProtection',
                                description: '',
                            },
                            {
                                title: 'BLS',
                                name: 'isBls',
                                description: '',
                            },
                            {
                                title: 'AC + KR',
                                name: 'isAc',
                                description: '',
                            },
                            {
                                title: 'STAŁA SUMA',
                                name: 'isFixedSum',
                                description: '',
                            },
                            {
                                title: 'OCHRONA ZNIŻKI AC',
                                name: 'acDiscountProtection',
                                description: '',
                            },
                            {
                                title: 'KONSUMPCJA SUMY',
                                name: 'sumConsumption',
                                description: '',
                            },
                            {
                                title: 'UDZIAŁ WŁASNY',
                                name: 'ownShare',
                                description: '',
                            },
                            {
                                title: 'KAŻDY: WIEK/DŁ. JAZDY',
                                name: 'lengthDriving',
                                description: '',
                            },
                            {
                                title: 'DLA PZU',
                                name: 'pzu',
                                description: '',
                            },
                            {
                                title: 'BAGAŻ',
                                name: 'isLuggage',
                                description: '',
                            },
                            {
                                title: 'SZKODA KRADZIEŻOWA NA TERYTORIUM Rosji, Białorusi, Ukrainy, Mołdawii',
                                name: 'pityTheft',
                                description: '',
                            },
                            {
                                title: 'NNW',
                                name: 'nnw',
                                description: '',
                            },
                            {
                                title: 'SZYBY',
                                name: 'windows',
                                description: '',
                            },
                        ],
                        towingFields: [
                            {
                                title: '',
                                description: '',
                            }
                        ]
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
