import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import axios from "axios";
import {Button} from "../../components/Buttons";
import {ClientForm} from "./ClientForm";
import {ClientsVehicles} from "./ClientsVehicles";
import {ClientsInsurancePolicy} from "./ClientsInsurancePolicy";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {apiUrl} from "../api";
import 'react-toastify/dist/ReactToastify.css';
import {ClientInfo} from "../../components/ClientInfo";
import {ClientsTasks} from "./ClientsTasks";

const TabWrapper = styled.div`
display: grid;
grid-template-columns: repeat(5, 225px);
grid-gap: 15px;
`;
const H1 = styled.h1`
margin-bottom: 10px;
`;
const FirstTabWrapper = styled.div`
display: flex;
&>div{
margin-top: 25px;
margin-left: 20px;
}
`;
const StyledPanelContentWrapper = styled(PanelContentWrapper)`
    max-height: calc(100vh - 170px);
    margin-top: 10px;
`;


export const ClientShow = () => {
    const match = useRouteMatch()
    const token = useSelector((store) => store.token);
    const [activeTab, setActiveTab] = useState(1);
    const [client, setClient] = useState({});

    useEffect(() => {
        axios.get(`${apiUrl}/client/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {client: newClient}}) => {
            setClient(newClient);
        });

    }, [token, match])

    return (
        <>
            <StyledPanelContentWrapper>
                <H1>Dany klient</H1>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Dane klienta</Button>
                    <Button type="button" inactive={activeTab !== 2} onClick={() => {
                        setActiveTab(2)
                    }}>Lista pojazdów</Button>
                    <Button type="button" inactive={activeTab !== 3} onClick={() => {
                        setActiveTab(3)
                    }}>Lista polis</Button>
                    <Button type="button" inactive={activeTab !== 4} onClick={() => {
                        setActiveTab(4)
                    }}>Lista zadań</Button>
                </TabWrapper>
                {activeTab === 1 && <FirstTabWrapper>
                    <ClientForm
                        onSubmit={values => {
                        }}
                        initialValues={client}
                        disabled={true}
                    />
                    <div>
                        <p>Liczba pojazdów: {client.vehicleCount}</p> <br/>
                        <p>Liczba aktywnych polis: {client.policiesActiveCount}</p> <br/>
                        {client.relatedClients?.length > 0 && (
                            <p><strong>Powiązani:</strong></p>
                        )}
                        {client.relatedClients?.map((clientSimple) => (
                            <ClientInfo clientSimple={clientSimple}/>
                        ))}
                        {client.belongClients?.length > 0 && (
                            <p><strong>Przypisani:</strong></p>
                        )}
                        {client.belongClients?.map((clientSimple) => (
                            <ClientInfo clientSimple={clientSimple}/>
                        ))}
                    </div>
                </FirstTabWrapper>}

                {activeTab === 2 && <div>
                    <ClientsVehicles clientId={client.id}/>
                </div>}
                {activeTab === 3 && <div>
                    <ClientsInsurancePolicy clientId={client.id}/>
                </div>}
                {activeTab === 4 && <div>
                    <ClientsTasks clientId={client.id}/>
                </div>}
            </StyledPanelContentWrapper>
        </>
    );
};
