import React from "react";
import {StyledLink} from "./ListComponents";

export const VehicleInfo = ({vehicle}) => {
    return (
        <>
            <StyledLink to={`/pojazdy/${vehicle.id}`}>
                <p>{vehicle.type} {vehicle.model}</p>
            </StyledLink>
            <p>Rocznik: {vehicle.yearbook}</p>
            <p>Pierwsza rejestracja: {vehicle.firstRegistration}</p>
            <br/>
        </>
    )
};
