import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {VehicleForm} from "./VehicleForm";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";

const H1 = styled.h1`
margin-bottom: 10px;
`;

export const VehicleCreate = () => {
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <H1>Dodaj pojazd</H1>
                <VehicleForm withClient={false} initialValues={{}} onSubmit={(values, registrationCertificateFile,proformaFile, specificationFile, otherFile) => {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                        formData.append(key, values[key] ?? '')
                    })
                    if(registrationCertificateFile){
                        formData.append('registrationProof', registrationCertificateFile);
                    }
                    if(proformaFile){
                        formData.append('proforma', proformaFile);
                    }
                    if(specificationFile){
                        formData.append('specification', specificationFile);
                    }
                    if(otherFile){
                        formData.append('other', otherFile);
                    }
                    axios.post(`${apiUrl}/vehicle`, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(({data: {vehicle: {id}}}) => {
                        toast.success("Pojazd został dodany do bazy.");
                        setRedirect(`/pojazdy/${id}`)
                    }).catch(e => {
                        console.log(e.response);
                        toast.error("Coś poszło nie tak.");
                    });
                }}/>
            </PanelContentWrapper>
        </>
    );
};
