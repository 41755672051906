import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {FileButton, Input, Label, RoundSimpleButton, Select} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {gusApi} from "../../models/api/gusApi";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import SmartSelect from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";
import {StyledTextarea} from "../../components/styled/inputs";

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 140px 320px;
    align-items: center;
    margin-bottom: 10px;
`;

const NIPWrapper = styled(InputWrapper)`
      grid-template-columns: 140px 320px 160px;
      button{
        margin-left: 20px;
      }
`;

const StyledForm = styled.form`
  margin-top: 25px;
  display: flex;
`;

const StyledA = styled.a`
color: black;
text-decoration: none;
margin-left: 10px;
`;

const StyledDivError = styled.div`
      color: red;
      grid-column: 2;
      margin: 5px;
      font-size: 1.2rem;
`;

const InputFileWrapper = styled.label`
display: grid;
grid-template-columns: 140px auto auto;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
font-size: 1.2rem;
position: relative;
input{
position: absolute;
left: 0;
top: 0;
height: 0;
width: 0;
}
`;

const StyledSmartSelect = styled(SmartSelect)`
    div:first-child {
        border-radius: 20px;
        border: none;
    }
`;

const RepresentativesContent = styled.div`
  margin-left: 20px;
`;
const RepresentativesWrapper = styled.div`
      display: flex;
      flex-direction: row;
      max-width: 500px;
      flex-wrap: wrap;
`;

const RepresentativeWrapper = styled.div`
  margin-bottom: 15px;
  margin-right: 20px;
`;

export const ClientForm = ({initialValues, onSubmit, disabled, clientId}) => {
    const token = useSelector((store) => store.token);
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [krsFile, setKrsFile] = useState(null);
    const [rodoFile, setRodoFile] = useState(null);
    const [marketingFile, setMarketingFile] = useState(null);

    const gus = (nip, form) => {
        gusApi.get(nip,token).then((response) => {
            if(response.status === 200){
                const gusData = response.data.gus;
                form.mutators.setValue('regon',gusData.regon)
                form.mutators.setValue('name',gusData.name)
                form.mutators.setValue('street', `${gusData.street} ${gusData.propertyNumber}`)
                form.mutators.setValue('postalCode',gusData.zipCode)
                form.mutators.setValue('city',gusData.city)
                toast.success("Pobrano dane z GUS");
            }
        })
            .catch((e) => {
                if(e.response?.status === 404){
                    toast.error("Nie można pobrać danych z podanego NIP.");
                }
                else{
                    console.log(e);
                    toast.error("Coś poszło nie tak.");
                }
            });
    }

    useEffect(() => {
        axios.get(`${apiUrl}/user/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users:newUsers}}) => {
            setUsers(newUsers);
        });
        axios.get(`${apiUrl}/client/all`, {
            params: {
                clientId: clientId !== undefined ? clientId : null
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients:newClients}}) => {
            setClients(newClients);
        });
    }, [token, clientId])

    return (
        <Form
            onSubmit={values => {
                const formData = new FormData();
                Object.keys(values).forEach((key) => {
                    formData.append(key, values[key] ?? '')
                })
                createFormData(formData, 'representatives', values.representatives);
                createFormData(formData, 'clients', values.clients);
                if (krsFile) {
                    formData.append('krsFile', krsFile);
                }
                if (rodoFile) {
                    formData.append('rodoFile', rodoFile);
                }
                if (marketingFile) {
                    formData.append('marketingFile', marketingFile);
                }
                onSubmit(formData).finally(()=>{
                    setKrsFile(null);
                    setRodoFile(null);
                    setMarketingFile(null);
                });
            }}
            initialValues={initialValues}
            validate={values => {
                const errors = {}
                if (!values.name) {
                    errors.name = 'Nazwa jest wymagana'
                }
                return errors
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                },
                ...arrayMutators
            }}
            render={({handleSubmit, values, form}) => (
                <StyledForm onSubmit={handleSubmit}>
                    <div>
                        <Field
                            name='nip'
                            type="number"
                            render={({input, meta}) => (
                                <>
                                    <NIPWrapper>
                                        <Label htmlFor={input.name}>NIP</Label>
                                        <Input {...input} id={input.name} placeholder="NIP" disabled={disabled}/>

                                        {disabled !== true && (
                                            <Button type="button" inactive small onClick={() => {
                                                if(values.nip){
                                                    gus(values.nip, form);
                                                }
                                                else{
                                                    toast.error("Prosze uzupełnij NIP.");
                                                }
                                            }}>Pobierz dane</Button>
                                        )}
                                    </NIPWrapper>
                                </>
                            )}/>
                        <Field
                            name='activity'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Działalność</Label>
                                        <Select {...input} id={input.name} disabled={disabled}>
                                            <option value="">Działalność</option>
                                            <option value="JDG">JDG</option>
                                            <option value="Sp z o.o.">Sp z o.o.</option>
                                            <option value="Sp z o.o. Sp. K">Sp z o.o. Sp. K</option>
                                            <option value="S.C.">S.C.</option>
                                            <option value="Gospodarstwo rolne">Gospodarstwo rolne</option>
                                            <option value="Rolnik Indywidualny">Rolnik Indywidualny</option>
                                            <option value="Osoba Fizyczna">Osoba Fizyczna</option>
                                            <option value="Inne">Inne</option>
                                        </Select>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='name'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Imię i nazwisko/Firma</Label>
                                        <Input {...input} id={input.name} placeholder="Imię i nazwisko/Firma" disabled={disabled}/>
                                        {meta.error && meta.touched && <StyledDivError>{meta.error}</StyledDivError>}
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='regon'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Regon</Label>
                                        <Input {...input} id={input.name} placeholder="Regon" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='krs'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>KRS</Label>
                                        <Input {...input} id={input.name} placeholder="KRS" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='street'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Ulica i numer lokalu</Label>
                                        <Input {...input} id={input.name} placeholder="Ulica i numer lokalu" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='postalCode'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Kod pocztowy</Label>
                                        <Input {...input} id={input.name} placeholder="Kod pocztowy" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='city'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Miejscowość</Label>
                                        <Input {...input} id={input.name} placeholder="Miejscowość" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='phone'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Numer telefonu</Label>
                                        <Input {...input} id={input.name} placeholder="Numer telefonu" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='email'
                            type="email"
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>E-mail</Label>
                                        <Input {...input} id={input.name} placeholder="E-mail" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='acquiredDate'
                            type="date"
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Data pozyskania</Label>
                                        <Input {...input} id={input.name} placeholder="Data pozyskania" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='leader'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Doprowadzający</Label>
                                        <Select {...input} id={input.name} disabled={disabled}>
                                            <option value="">Doprowadzający</option>
                                            {users.map((user) => (
                                                <option value={user.name}>{user.name}</option>
                                            ))}
                                        </Select>
                                    </InputWrapper>
                                </>
                            )}/>
                        <Field
                            name='comments'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Uwagi</Label>
                                        <Input {...input} id={input.name} placeholder="Uwagi" disabled={disabled}/>
                                    </InputWrapper>
                                </>
                            )}/>

                        <Field
                            name='clients'
                            render={({input, meta}) => (
                                <>
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Powiązani klienci</Label>
                                        <StyledSmartSelect {...input} id={input.name}
                                                           isMulti
                                                           searchable
                                                           isDisabled={disabled}
                                                           placeholder="Powiązani klienci"
                                                           noOptionsMessage={() => 'Brak opcji'}
                                                           options={clients.map(({ id, name}) => {
                                                               return {
                                                                   value: id,
                                                                   label: name
                                                               };
                                                           })}
                                        />
                                    </InputWrapper>
                                </>
                            )}
                        />

                        <Field
                            name='note'
                            render={({input}) => (
                                <InputWrapper>
                                    <Label htmlFor={input.name}>Notatka</Label>
                                    <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                </InputWrapper>
                            )}/>

                        <Field
                            name='krs_path'
                            type="file"
                            render={({input, meta}) => (
                                <>
                                    <InputFileWrapper
                                        htmlFor={input.name}
                                        onChange={(e) => {
                                            input.onChange(e);
                                            setKrsFile(e.target.files[0]);
                                        }}
                                    >
                                        <input {...input} id={input.name}/>
                                        <span>CEIDG/KRS</span>
                                        <FileButton>{krsFile ? krsFile.name : "Wybierz plik"}</FileButton>
                                        {values.krsFile && <StyledA href={values.krsFile} rel="noopener noreferrer"
                                                                target="_blank">Zobacz</StyledA>}
                                    </InputFileWrapper>
                                </>
                            )}/>

                        <Field
                            name='rodo_path'
                            type="file"
                            render={({input, meta}) => (
                                <>
                                    <InputFileWrapper
                                        htmlFor={input.name}
                                        onChange={(e) => {
                                            input.onChange(e);
                                            setRodoFile(e.target.files[0]);
                                        }}
                                    >
                                        <input {...input} id={input.name}/>
                                        <span>Wniosek/RODO</span>
                                        <FileButton>{rodoFile ? rodoFile.name : "Wybierz plik"}</FileButton>
                                        {values.rodoFile && <StyledA href={values.rodoFile} rel="noopener noreferrer"
                                                                 target="_blank">Zobacz</StyledA>}
                                    </InputFileWrapper>
                                </>
                            )}/>

                        <Field
                            name='marketing_path'
                            type="file"
                            render={({input, meta}) => (
                                <>
                                    <InputFileWrapper
                                        htmlFor={input.name}
                                        onChange={(e) => {
                                            input.onChange(e);
                                            setMarketingFile(e.target.files[0]);
                                        }}
                                    >
                                        <input {...input} id={input.name}/>
                                        <span>Zgoda marketingowa</span>
                                        <FileButton>{marketingFile ? marketingFile.name : "Wybierz plik"}</FileButton>
                                        {values.marketingFile &&
                                        <StyledA href={values.marketingFile} rel="noopener noreferrer"
                                                 target="_blank">Zobacz</StyledA>}
                                    </InputFileWrapper>
                                </>
                            )}/>
                        {disabled !== true && (
                            <Button type="submit">Zapisz</Button>
                        )}
                    </div>
                    <RepresentativesContent>
                        <FieldArray name="representatives">
                            {({fields}) => (
                                <div>
                                    <RoundSimpleButton
                                        type="button"
                                        onClick={() => fields.push({})}>
                                        <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                    </RoundSimpleButton>
                                    <RoundSimpleButton
                                        type="button"
                                        onClick={() => fields.pop()}>
                                        <FontAwesomeIcon icon={faMinus} color="white" size="2x"/>
                                    </RoundSimpleButton>
                                    <h3>Reprezentanci</h3>
                                    <RepresentativesWrapper>
                                        {fields.map((name, index) => (
                                            <RepresentativeWrapper key={index}>
                                                <Field
                                                    name={`${name}.id`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Input {...input} id={input.name} hidden/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.name`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Imię i nazwisko</Label>
                                                                <Input {...input} id={input.name} placeholder="Imię i nazwisko"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.pesel`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Pesel</Label>
                                                                <Input {...input} id={input.name} placeholder="Pesel"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.street`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Ulica i numer lokalu</Label>
                                                                <Input {...input} id={input.name} placeholder="Ulica i numer lokalu"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.postCode`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Kod pocztowy</Label>
                                                                <Input {...input} id={input.name} placeholder="Kod pocztowy"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.city`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Miejscowość</Label>
                                                                <Input {...input} id={input.name} placeholder="Miejscowość"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.phone`}
                                                    type="tel"
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Numer telefonu</Label>
                                                                <Input {...input} id={input.name} placeholder="Numer telefonu"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                            </RepresentativeWrapper>
                                        ))}
                                    </RepresentativesWrapper>

                                </div>
                            )}
                        </FieldArray>
                    </RepresentativesContent>
                </StyledForm>
            )}
        />
    );
};
