import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Table} from "./Table";
import {Pagination} from "@material-ui/lab";
import axios from "axios";
import {apiUrl} from "../views/api";
import {useSelector} from "react-redux";

const LogsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Logs = ({todoId}) => {
    const token = useSelector((store) => store.token);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    useEffect(()=>{
        axios.get(`${apiUrl}/toDos/${todoId}/logs`, {
            params: {
                page,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {logs: newLogs, lastPage}}) => {
            setLogs(newLogs);
            setPages(lastPage);
        })
    },[token, page, todoId])

    return (
        <LogsWrapper>
            <Table>
                <thead>
                <tr>
                    <th>Użytkownik</th>
                    <th>Typ</th>
                    <th>Status</th>
                    <th>Zmiana</th>
                </tr>
                </thead>
                <tbody>
                {logs.map(({name, type, status, created_at},key) => (
                    <tr key={key}>
                        <td>{name}</td>
                        <td>{type}</td>
                        <td>{status}</td>
                        <td>{created_at}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                setPage(page);
            }}/>
        </LogsWrapper>
    );
}
