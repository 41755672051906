import React from 'react';
import {Header} from "./components/Header";
import {GlobalStyle} from "./theme/GlobalStyles";
import {Route, Switch} from "react-router-dom";
import {Login} from "./views/Login";
import {ResetPassword} from "./views/ResetPassword";
import {ThemeProvider} from "styled-components";
import theme from "./theme/theme";
import {PanelRouter} from "./views/PanelRouter/PanelRouter";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <Header/>
            <Switch>
                <Route path="/" exact component={Login}/>
                <Route path="/reset-hasla" exact component={ResetPassword}/>
                <Route path="*" exact component={PanelRouter}/>
            </Switch>
        </ThemeProvider>
    );
}

export default App;
