import React from "react";
import {StyledLink} from "./ListComponents";

export const ToDoInfo = ({toDo}) => {
    return (
        <>
            <StyledLink to={`/zadania/${toDo.id}`}>
                <p>Zadanie o ID: {toDo.id}</p>
            </StyledLink>
            <p>Zakończone: {toDo.archive_at}</p>
            <br/>
        </>
    )
};
