import React from "react";
import {StyledLink} from "./ListComponents";

export const ClientContactInfo = ({clientSimple}) => {
    return (
        <>
            <StyledLink to={`/klienci/zobacz/${clientSimple.id}`}>
                <p>{clientSimple.name}</p>
            </StyledLink>
            <p>{clientSimple.nip}</p>
            {clientSimple.phone != null && (
                <p>Telefon: {clientSimple.phone}</p>
            )}
            <p>Liczba pojazdów: {clientSimple.vehicleCount}</p>
            <p>Liczba aktywnych polis: {clientSimple.policiesActiveCount}</p>
            <br/>
        </>
    )
};
