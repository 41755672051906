import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {Table} from "../../components/Table";
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight, faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {Pagination} from "@material-ui/lab";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Select} from "../../components/Form";

const TabWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 15px;
  margin-bottom: 20px;

  ${Button} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: -15px;
      width: 15px;
      height: 1px;
      background-color: #827E7E;
    }
  }
`;
const StyledPanelContentWrapper = styled(PanelContentWrapper)`
  padding: 30px 60px;
`;
const StyledButton = styled.button`
  border: none;
  background: transparent;
  color: ${({theme: {colors: {red}}}) => red};;
  font-weight: 900;
  font-size: 1.6rem;
  top: 5px;
  right: 5px;
`;

const StatusWrapper = styled.div`
    margin: 10px 0;
`

export const Tasks = ({match}) => {
    const [activeTab, setActiveTab] = useState(1);
    const token = useSelector((store) => store.token);
    const [redirect] = useState(null);
    const [status, setStatus] = useState(match.params.status);

    const [toDoTasks, setToDoTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [changeTask, setChangeTask] = useState(1);
    const [toDoTaskModal, setToDoTaskModal] = useState(null);

    const [personalTasks, setPersonalTasks] = useState([]);
    const [p, setP] = useState(1);
    const [ps, setPs] = useState(1);

    const [toDoMissingTasks, setToDoMissingTasks] = useState([]);
    const [pM, setPM] = useState(1);
    const [pMs, setPMs] = useState(1);
    const [toDoMissingTasksModal, setToDoMissingTasksModal] = useState(null);
    const [changeToDoMissingTasks, setChangeToDoMissingTasks] = useState(1);

    const [personalTaskModal, setPersonalTaskModal] = useState(null);
    const [changePersonalTask, setChangePersonalTask] = useState(1);

    useEffect(() => {
        axios.get(`${apiUrl}/tasks/list`, {
            params: {
                page: page,
                status: status
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks, lastPage}}) => {
            setToDoTasks(tasks);
            setPages(lastPage);
        })
    }, [token, status, changeTask, page]);

    useEffect(() => {
        axios.get(`${apiUrl}/personal/tasks/list`, {
            params: {
                page: p,
                status: status
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks, lastPage}}) => {
            setPersonalTasks(tasks);
            setPs(lastPage);
        })
    }, [token, status, changePersonalTask, p]);

    useEffect(() => {
        axios.get(`${apiUrl}/tasks/list`, {
            params: {
                page: pM,
                status: status,
                type: 0
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks, lastPage}}) => {
            setToDoMissingTasks(tasks);
            setPMs(lastPage);
        })
    }, [token, status, pM, changeToDoMissingTasks]);


    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <StyledPanelContentWrapper>
                <StatusWrapper>
                    <span>Status:</span>
                    <Select onChange={(e)=>{
                        console.log(e.target.value)
                        setStatus(e.target.value)
                    }}>
                        <option selected={status == 0} value={0}>Nowe</option>
                        <option selected={status == 1} value={1}>W trakcie</option>
                        <option selected={status == 2} value={2}>Zakończone</option>
                    </Select>
                </StatusWrapper>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Zadania</Button>
                    <Button type="button" inactive={activeTab !== 2} onClick={() => {
                        setActiveTab(2)
                    }}>Zadania customowe</Button>
                    <Button type="button" inactive={activeTab !== 3} onClick={() => {
                        setActiveTab(3)
                    }}>Braki TODO</Button>
                </TabWrapper>

                {activeTab === 1 && (
                    <>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {toDoTasks.map(({
                                                id,
                                                clientName,
                                                userName,
                                                description,
                                                date,
                                                status,
                                                to_do_id: toDoId,
                                                commentsCount
                                            }, key) => (
                                <tr key={key}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {status !== 0 && (
                                            <>
                                                {status === 1 ? (
                                                    <StyledButton onClick={() => {
                                                        setToDoTaskModal(id);
                                                    }}>
                                                        <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                                    </StyledButton>
                                                ) : (
                                                    <StyledButton>
                                                        <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                                    </StyledButton>
                                                )}
                                            </>
                                        )}
                                        <StyledLink to={`/taski/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                            setPage(page);
                        }}/>
                    </>
                )}

                {activeTab === 2 && (
                    <>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {personalTasks.map(({
                                                    id,
                                                    client_id: clientId,
                                                    clientName,
                                                    userName,
                                                    description,
                                                    date,
                                                    status,
                                                    commentsCount
                                                }, key) => (
                                <tr key={key}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {status !== 0 && (
                                            <>
                                                {status === 1 ? (
                                                    <StyledButton onClick={() => {
                                                        setPersonalTaskModal(id);
                                                    }}>
                                                        <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                                    </StyledButton>
                                                ) : (
                                                    <StyledButton>
                                                        <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                                    </StyledButton>
                                                )}
                                            </>
                                        )}
                                        <StyledLink to={`/taski/osobiste/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={ps} page={p} shape="rounded" size="large" onChange={(event, page) => {
                            setP(page);
                        }}/>
                    </>
                )}

                {activeTab === 3 && (
                    <>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {toDoMissingTasks.map(({id,
                                                    client_id: clientId,
                                                    clientName,
                                                    userName,
                                                    description,
                                                    date,
                                                    status,
                                                    commentsCount
                                                }, key) => (
                                <tr key={key}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {status !== 0 && (
                                            <>
                                                {status === 1 ? (
                                                    <StyledButton onClick={() => {
                                                        setToDoMissingTasksModal(id);
                                                    }}>
                                                        <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                                    </StyledButton>
                                                ) : (
                                                    <StyledButton>
                                                        <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                                    </StyledButton>
                                                )}
                                            </>
                                        )}
                                        <StyledLink to={`/taski/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={pMs} page={pM} shape="rounded" size="large" onChange={(event, page) => {
                            setPM(page);
                        }}/>
                    </>
                )}
            </StyledPanelContentWrapper>

            {personalTaskModal && <Modal closeModal={() => {
                setPersonalTaskModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/personal/tasks/${personalTaskModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                            setChangePersonalTask(changePersonalTask + 1);
                        }).catch((e) => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setPersonalTaskModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setPersonalTaskModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {toDoTaskModal && <Modal closeModal={() => {
                setToDoTaskModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/tasks/${toDoTaskModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                            setChangeTask(changeTask + 1);
                        }).catch((e) => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setToDoTaskModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setToDoTaskModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {toDoMissingTasksModal && <Modal closeModal={() => {
                setToDoMissingTasksModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/tasks/${toDoMissingTasksModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                            setChangeToDoMissingTasks(changeToDoMissingTasks + 1);
                        }).catch((e) => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setToDoMissingTasksModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setToDoMissingTasksModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
}
