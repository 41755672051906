export default {
    uzytkownicy: [{
        name: 'Lista użytkowników',
        path: '/uzytkownicy'
    }, {
        name: 'Dodaj użytkownika',
        path: '/uzytkownicy/dodaj'
    },],
    klienci: [{
        name: 'Lista klientów',
        path: '/klienci'
    }, {
        name: 'Dodaj klientów',
        path: '/klienci/dodaj'
    }],
    pojazdy: [{
        name: 'Lista pojazdów',
        path: '/pojazdy'
    }, {
        name: 'Dodaj pojazd',
        path: '/pojazdy/dodaj'
    }],
    zadania: [{
        name: 'Zadania',
        path: '/zadania'
    }, {
        name: 'Dodaj zadanie',
        path: '/zadania/dodaj'
    }, {
        name: 'Archiwum',
        path: '/zadania/archiwum'
    }],
    firmy: [{
        name: 'Baza TU',
        path: '/firmy'
    }, {
        name: 'Dodaj TU',
        path: '/firmy/dodaj'
    }],
    polisy: [{
        name: 'Polisy',
        path: '/polisy'
    }, {
        name: 'Dodaj polise',
        path: '/polisy/dodaj'
    }],
}
