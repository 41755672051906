import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import axios from "axios";
import {
    Input,
    Label,
    RoundSimpleButton,
    Select,
    Switch,
    TopFormWrapper,
    VariantLabel
} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {maxValue} from "../../validators/base";
import {downloadFile} from "../../helpers/file";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";

const StyledForm = styled.form`
display: flex;
flex-direction: row;
position: relative;
&>div{
margin-right: 20px;
}
`;

const InputWrapper = styled.div`
display: grid;
grid-template-columns: 140px 320px;
align-items: center;
margin-bottom: 10px;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
grid-template-columns: 200px;
align-items: center;
text-align: center;
`;

const StyledButton = styled(Button)`
margin-right: 15px;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: -5px;
`;


export const FinanceTab = ({toDoId, archived}) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const token = useSelector((store) => store.token);
    const [clientName, setClientName] = useState(null);
    const [defaultNet, setDefaultNet] = useState(null);
    const [finances, setFinances] = useState([]);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/finance`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {finances: newFinances, isActive, clientFullName,statuses:newStatuses, finance_status:newStatus, defaultNet:newDefaultNet}}) => {
                setIsEnabled(isActive);
                setClientName(clientFullName);
                if (isActive) {
                    setFinances(newFinances);
                }
                else{
                    setFinances([
                        {
                            isActive: false,
                            net:newDefaultNet
                        }
                    ]);
                }
                setStatus(newStatus);
                setStatuses(newStatuses)
                setDefaultNet(newDefaultNet);
            }).catch(() => {
                toast.error("Popraw dane");
            });
        }
    }, [token, toDoId]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if(item.index === index){
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        }
        else{
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    const calcPayment = (value, name, index, form, values) => {
        const paymentPercentage = parseFloat(values.finances[index].paymentPercentage);
        if(paymentPercentage){
            const netValue = parseFloat(value);
            const paymentValue = netValue * paymentPercentage / 100;
            form.mutators.setValue(`${name}.payment`, paymentValue.toFixed(2))
        }
    }

    const calcFromPercentsPayment = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);
        if(netValue){
            const paymentValue = netValue * parseFloat(value) / 100;
            form.mutators.setValue(`${name}.payment`, paymentValue.toFixed(2))
        }
    }

    const changePayment = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);
        if(netValue){
            const paymentPercentage = parseFloat(value) / netValue * 100;
            form.mutators.setValue(`${name}.paymentPercentage`, paymentPercentage.toFixed(2))
        }
    }

    const calcRepurchase = (value, name, index, form, values) => {
        const paymentPercentage = parseFloat(values.finances[index].repurchasePercentage);
        if(paymentPercentage){
            const repurchaseValue = parseFloat(value) * paymentPercentage / 100;
            form.mutators.setValue(`${name}.repurchase`, repurchaseValue.toFixed(2))
        }
    }

    const calcRepurchaseFromPercents = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);
        if(netValue){
            const paymentValue = netValue * parseFloat(value) / 100;
            form.mutators.setValue(`${name}.repurchase`, paymentValue.toFixed(2))
        }
    }

    const changeRepurchase = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);
        if(netValue){
            const repurchasePercentage = parseFloat(value) / netValue * 100;
            form.mutators.setValue(`${name}.repurchasePercentage`, repurchasePercentage.toFixed(2))
        }
    }

    const changeFeesSumPayment = (value, name, index, form, values) => {
        const paymentValue = parseFloat(value);
        const periodValue = parseFloat(values.finances[index].period);
        const periodPrice = parseFloat(values.finances[index].periodPrice);
        const repurchase = parseFloat(values.finances[index].repurchase);
        const netValue = parseFloat(values.finances[index].net);

        if(paymentValue && periodValue && periodPrice && repurchase && netValue){
            const calc = ((paymentValue + (periodValue * periodPrice) + repurchase) - netValue);
            form.mutators.setValue(`${name}.feesSum`, calc)
            form.mutators.setValue(`${name}.feesSumPercentage`, calc/netValue)
        }
    }

    const changeFeesSumPeriod = (value, name, index, form, values) => {
        const paymentValue = parseFloat(values.finances[index].payment);
        const periodValue = parseFloat(value);
        const periodPrice = parseFloat(values.finances[index].periodPrice);
        const repurchase = parseFloat(values.finances[index].repurchase);
        const netValue = parseFloat(values.finances[index].net);

        if(paymentValue && periodValue && periodPrice && repurchase && netValue){
            const calc = ((paymentValue + (periodValue * periodPrice) + repurchase) - netValue);
            form.mutators.setValue(`${name}.feesSum`, calc)
            form.mutators.setValue(`${name}.feesSumPercentage`, calc/netValue)
        }
    }

    const changeFeesSumPeriodPrice = (value, name, index, form, values) => {
        const paymentValue = parseFloat(values.finances[index].payment);
        const periodValue = parseFloat(values.finances[index].period);
        const periodPrice = parseFloat(value);
        const repurchase = parseFloat(values.finances[index].repurchase);
        const netValue = parseFloat(values.finances[index].net);

        if(paymentValue && periodValue && periodPrice && repurchase && netValue){
            const calc = ((paymentValue + (periodValue * periodPrice) + repurchase) - netValue);
            form.mutators.setValue(`${name}.feesSum`, calc)
            form.mutators.setValue(`${name}.feesSumPercentage`, calc/netValue)
        }
    }

    const changeFeesSumRepurchase = (value, name, index, form, values) => {
        const paymentValue = parseFloat(values.finances[index].payment);
        const periodValue = parseFloat(values.finances[index].period);
        const periodPrice = parseFloat(values.finances[index].periodPrice);
        const repurchase = parseFloat(value);
        const netValue = parseFloat(values.finances[index].net);

        if(paymentValue && periodValue && periodPrice && repurchase && netValue){
            const calc = ((paymentValue + (periodValue * periodPrice) + repurchase) - netValue);
            form.mutators.setValue(`${name}.feesSum`, calc)
            form.mutators.setValue(`${name}.feesSumPercentage`, calc/netValue)
        }
    }

    const changeFeesSumNet = (value, name, index, form, values) => {
        const paymentValue = parseFloat(values.finances[index].payment);
        const periodValue = parseFloat(values.finances[index].period);
        const periodPrice = parseFloat(values.finances[index].periodPrice);
        const repurchase = parseFloat(values.finances[index].repurchase);
        const netValue = parseFloat(value);

        if(paymentValue && periodValue && periodPrice && repurchase && netValue){
            const calc = ((paymentValue + (periodValue * periodPrice) + repurchase) - netValue);
            form.mutators.setValue(`${name}.feesSum`, calc)
            form.mutators.setValue(`${name}.feesSumPercentage`, calc/netValue)
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Finansowanie</h2>
                </div>
                <div>
                    {clientName && (
                        clientName.map((item,key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{finance_status:status}}
                    nameField="finance_status"
                    statuses={statuses}
                    archived={archived}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={values => {
                        const fd = new FormData();
                        createFormData(fd, 'finances', values.finances);
                        if(globalFiles.length > 0){
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`finances[${index}][files][${file.index}][file]`,file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/finance`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {finances: newFinances, isActive}}) => {
                            setIsEnabled(isActive);
                            if (isActive) {
                                setFinances(newFinances);
                            }
                            toast.success("Dane zostały zapisane.");
                        })
                            .catch(() => {
                                toast.error("Popraw dane");
                            });
                    }}
                    initialValues={{finances}}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>

                            <FieldArray name="finances">
                                {({fields}) => (
                                    <>
                                        <ButtonsWrapper>
                                            <RoundSimpleButton
                                                type="button"
                                                onClick={() => fields.push({isActive: false,net:defaultNet})}>
                                                <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                            </RoundSimpleButton>
                                            <RoundSimpleButton
                                                type="button"
                                                onClick={() => fields.pop()}>
                                                <FontAwesomeIcon icon={faMinus} color="white" size="2x"/>
                                            </RoundSimpleButton>
                                        </ButtonsWrapper>
                                        {fields.map((name, index) => index === 0 ? (
                                            <div key={index}>
                                                <Field
                                                    name={`${name}.id`}
                                                    render={({input, meta}) => (
                                                        <Input {...input} id={input.name} hidden/>
                                                    )}/>
                                                <Field
                                                    name={`${name}.variantNumber`}
                                                    type="hidden"
                                                    initialValue={index+1}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        </>
                                                    )}
                                                />
                                                <Field
                                                    name={`${name}.isActive`}
                                                    type="checkbox"
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <VariantLabel>
                                                                    Wariant {index+1}
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </VariantLabel>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.institution`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Instytucja*</Label>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Instytucja</option>
                                                                    <option value="ALIOR LEASING SP. Z O.O.">ALIOR LEASING SP. Z O.O.</option>
                                                                    <option value="ATHLON CAR LEASE POLSA SP. Z O.O.">ATHLON CAR LEASE POLSA SP. Z O.O.</option>
                                                                    <option value="SANTANDER LEASING S.A.">SANTANDER LEASING S.A.</option>
                                                                    <option value="MLEASING SP. Z O.O.">MLEASING SP. Z O.O.</option>
                                                                    <option value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.">EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.</option>
                                                                    <option value="VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE">VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE</option>
                                                                    <option value="RCI LEASING POLSKA SP. Z O.O.">RCI LEASING POLSKA SP. Z O.O.</option>
                                                                    <option value="PKO LEASING S.A.">PKO LEASING S.A.</option>
                                                                    <option value="FCA LEASING POLSKA SP. Z O.O.">FCA LEASING POLSKA SP. Z O.O.</option>
                                                                    <option value="IDEA FINANCE SP. Z O.O.">IDEA FINANCE SP. Z O.O.</option>
                                                                    <option value="IDEA FLEET S.A.">IDEA FLEET S.A.</option>
                                                                    <option value="GETIN LEASING S.A.">GETIN LEASING S.A.</option>
                                                                    <option value="GETIN FLEET S.A.">GETIN FLEET S.A.</option>
                                                                    <option value="Alior Leasing">Alior Leasing</option>
                                                                    <option value="Athlon Car Lease Polska">Athlon Car Lease Polska</option>
                                                                    <option value="BOŚ Leasing ">BOŚ Leasing </option>
                                                                    <option value="BMW Financial Services Polska Sp. z o.o.">BMW Financial Services Polska Sp. z o.o.</option>
                                                                    <option value="BNP Paribas Lease Group Sp. z o.o.">BNP Paribas Lease Group Sp. z o.o.</option>
                                                                    <option value="BPS Leasing Spółka Akcyjna (BPS Leasing S.A.)">BPS Leasing Spółka Akcyjna (BPS Leasing S.A.)</option>
                                                                    <option value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A.">EUROPEJSKI FUNDUSZ LEASINGOWY S.A.</option>
                                                                    <option value="Idea Getin Leasing S.A.">Idea Getin Leasing S.A.</option>
                                                                    <option value="IMPULS-LEASING Polska Sp. z o.o.">IMPULS-LEASING Polska Sp. z o.o.</option>
                                                                    <option value="ING LEASE (Polska) Sp. z o.o.">ING LEASE (Polska) Sp. z o.o.</option>
                                                                    <option value="Millennium Leasing Sp. z o.o.">Millennium Leasing Sp. z o.o.</option>
                                                                    <option value="mLeasing Sp. z o.o.">mLeasing Sp. z o.o.</option>
                                                                    <option value="PEKAO LEASING Sp. z o.o.">PEKAO LEASING Sp. z o.o.</option>
                                                                    <option value="PKO LEASING S.A.">PKO LEASING S.A.</option>
                                                                    <option value="PSA Finance Polska sp. z o.o.">PSA Finance Polska sp. z o.o.</option>
                                                                    <option value="Santander Consumer Multirent Sp.z o.o.">Santander Consumer Multirent Sp.z o.o.</option>
                                                                    <option value="Santander Leasing S.A.">Santander Leasing S.A.</option>
                                                                    <option value="SGB Leasing Sp. z o.o.">SGB Leasing Sp. z o.o.</option>
                                                                    <option value="SIEMENS FINANCE Sp. z o.o.">SIEMENS FINANCE Sp. z o.o.</option>
                                                                    <option value="TOYOTA LEASING POLSKA SP. Z O.O.">TOYOTA LEASING POLSKA SP. Z O.O.</option>
                                                                </Select>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.financingType`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Rodzaj finansowania*</Label>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Rodzaj finansowania</option>
                                                                    <option value="Leasing operacyjny">Leasing
                                                                        operacyjny
                                                                    </option>
                                                                    <option value="Pożyczka">Pożyczka</option>
                                                                    <option value="CFM">CFM</option>
                                                                    <option value="Leasing finansowy">Leasing
                                                                        finansowy
                                                                    </option>
                                                                    <option value="Kredyt">Kredyt</option>
                                                                    <option value="brak">brak</option>
                                                                </Select>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.net`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Cena netto*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           calcPayment(e.target.value, name, index, form, values);
                                                                           calcRepurchase(e.target.value, name, index, form, values);
                                                                           changeFeesSumNet(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.paymentPercentage`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    validate={maxValue(100)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Wpłata%*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           calcFromPercentsPayment(e.target.value, name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.payment`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Wpłata netto PLN*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           changePayment(e.target.value, name, index, form, values);
                                                                           changeFeesSumPayment(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.periodPrice`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Rata netto*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e)=>{
                                                                           input.onChange(e);
                                                                           changeFeesSumPeriodPrice(e.target.value, name, index, form, values);
                                                                       }}
                                                                       step="1"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.period`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Okres mc*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e)=>{
                                                                           input.onChange(e);
                                                                           changeFeesSumPeriod(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="1"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.repurchasePercentage`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    validate={maxValue(100)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Wykup%*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           calcRepurchaseFromPercents(e.target.value, name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.repurchase`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Wykup netto*</Label>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           changeRepurchase(e.target.value, name, index, form, values);
                                                                           changeFeesSumRepurchase(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.provision`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Prowizja*</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>


                                                <Field
                                                    name={`${name}.policy`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Polisa*</Label>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Polisa</option>
                                                                    <option value="Polisa Grodzki">Polisa Grodzki
                                                                    </option>
                                                                    <option value="Polisa Leasing">Polisa Leasing
                                                                    </option>
                                                                    <option value="Polisa Obca">Polisa Obca</option>
                                                                    <option value="Polisa Dostawca">Polisa Dostawca
                                                                    </option>
                                                                </Select>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <p>ODPOWIEDZI</p>

                                                <Field
                                                    name={`${name}.margin`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Marża</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.netInstallment`}
                                                    type={"number"}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Rata netto*</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.leasingPolicy`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Polisa leasing*</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.additionalProduct`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Produkt dodatkowy</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.additionalFees`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Opłaty dodatkowe*</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.feesSumPercentage`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Suma opłat %</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.feesSum`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Suma opłat PLN</Label>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapper>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Files name={`${name}.files`} index={index} setFilesToForm={setFilesToForm}/>

                                                {!archived && (
                                                    <StyledButton>Zapisz</StyledButton>
                                                )}
                                                <StyledButton inactive type="button" onClick={() => {
                                                    downloadFile(
                                                        `${apiUrl}/toDos/${toDoId}/finance/print`,
                                                        token,
                                                        `${toDoId}-finansowanie.pdf`
                                                    );
                                                }}>Drukuj</StyledButton>
                                            </div>
                                        ) : (
                                            <div key={index}>
                                                <Field
                                                    name={`${name}.id`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <Input {...input} id={input.name} hidden/>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.variantNumber`}
                                                    type="hidden"
                                                    initialValue={index+1}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        </>
                                                    )}
                                                />
                                                <Field
                                                    name={`${name}.isActive`}
                                                    type="checkbox"
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <VariantLabel>
                                                                    Wariant {index+1}
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </VariantLabel>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.institution`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Instytucja</option>
                                                                    <option value="ALIOR LEASING SP. Z O.O.">ALIOR LEASING SP. Z O.O.</option>
                                                                    <option value="ATHLON CAR LEASE POLSA SP. Z O.O.">ATHLON CAR LEASE POLSA SP. Z O.O.</option>
                                                                    <option value="SANTANDER LEASING S.A.">SANTANDER LEASING S.A.</option>
                                                                    <option value="MLEASING SP. Z O.O.">MLEASING SP. Z O.O.</option>
                                                                    <option value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.">EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.</option>
                                                                    <option value="VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE">VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE</option>
                                                                    <option value="RCI LEASING POLSKA SP. Z O.O.">RCI LEASING POLSKA SP. Z O.O.</option>
                                                                    <option value="PKO LEASING S.A.">PKO LEASING S.A.</option>
                                                                    <option value="FCA LEASING POLSKA SP. Z O.O.">FCA LEASING POLSKA SP. Z O.O.</option>
                                                                    <option value="IDEA FINANCE SP. Z O.O.">IDEA FINANCE SP. Z O.O.</option>
                                                                    <option value="IDEA FLEET S.A.">IDEA FLEET S.A.</option>
                                                                    <option value="GETIN LEASING S.A.">GETIN LEASING S.A.</option>
                                                                    <option value="GETIN FLEET S.A.">GETIN FLEET S.A.</option>
                                                                    <option value="Alior Leasing">Alior Leasing</option>
                                                                    <option value="Athlon Car Lease Polska">Athlon Car Lease Polska</option>
                                                                    <option value="BOŚ Leasing ">BOŚ Leasing </option>
                                                                    <option value="BMW Financial Services Polska Sp. z o.o.">BMW Financial Services Polska Sp. z o.o.</option>
                                                                    <option value="BNP Paribas Lease Group Sp. z o.o.">BNP Paribas Lease Group Sp. z o.o.</option>
                                                                    <option value="BPS Leasing Spółka Akcyjna (BPS Leasing S.A.)">BPS Leasing Spółka Akcyjna (BPS Leasing S.A.)</option>
                                                                    <option value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A.">EUROPEJSKI FUNDUSZ LEASINGOWY S.A.</option>
                                                                    <option value="Idea Getin Leasing S.A.">Idea Getin Leasing S.A.</option>
                                                                    <option value="IMPULS-LEASING Polska Sp. z o.o.">IMPULS-LEASING Polska Sp. z o.o.</option>
                                                                    <option value="ING LEASE (Polska) Sp. z o.o.">ING LEASE (Polska) Sp. z o.o.</option>
                                                                    <option value="Millennium Leasing Sp. z o.o.">Millennium Leasing Sp. z o.o.</option>
                                                                    <option value="mLeasing Sp. z o.o.">mLeasing Sp. z o.o.</option>
                                                                    <option value="PEKAO LEASING Sp. z o.o.">PEKAO LEASING Sp. z o.o.</option>
                                                                    <option value="PKO LEASING S.A.">PKO LEASING S.A.</option>
                                                                    <option value="PSA Finance Polska sp. z o.o.">PSA Finance Polska sp. z o.o.</option>
                                                                    <option value="Santander Consumer Multirent Sp.z o.o.">Santander Consumer Multirent Sp.z o.o.</option>
                                                                    <option value="Santander Leasing S.A.">Santander Leasing S.A.</option>
                                                                    <option value="SGB Leasing Sp. z o.o.">SGB Leasing Sp. z o.o.</option>
                                                                    <option value="SIEMENS FINANCE Sp. z o.o.">SIEMENS FINANCE Sp. z o.o.</option>
                                                                    <option value="TOYOTA LEASING POLSKA SP. Z O.O.">TOYOTA LEASING POLSKA SP. Z O.O.</option>
                                                                </Select>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.financingType`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Rodzaj finansowania</option>
                                                                    <option value="Leasing operacyjny">Leasing
                                                                        operacyjny
                                                                    </option>
                                                                    <option value="Pożyczka">Pożyczka</option>
                                                                    <option value="CFM">CFM</option>
                                                                    <option value="Leasing finansowy">Leasing
                                                                        finansowy
                                                                    </option>
                                                                    <option value="Kredyt">Kredyt</option>
                                                                    <option value="brak">brak</option>
                                                                </Select>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.net`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           calcPayment(e.target.value, name, index, form, values);
                                                                           calcRepurchase(e.target.value, name, index, form, values);
                                                                           changeFeesSumNet(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.paymentPercentage`}
                                                    type="number"
                                                    validate={maxValue(100)}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           calcFromPercentsPayment(e.target.value, name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.payment`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           changePayment(e.target.value, name, index, form, values);
                                                                           changeFeesSumPayment(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.periodPrice`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e)=>{
                                                                           input.onChange(e);
                                                                           changeFeesSumPeriodPrice(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="1"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.period`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e)=>{
                                                                           input.onChange(e);
                                                                           changeFeesSumPeriod(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="1"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.repurchasePercentage`}
                                                    type="number"
                                                    validate={maxValue(100)}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           calcRepurchaseFromPercents(e.target.value, name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.repurchase`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder="" min="0"
                                                                       onChange={(e) => {
                                                                           input.onChange(e);
                                                                           changeRepurchase(e.target.value, name, index, form, values);
                                                                           changeFeesSumRepurchase(e.target.value,name, index, form, values);
                                                                       }}
                                                                       step="0.01"/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.provision`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>


                                                <Field
                                                    name={`${name}.policy`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Select {...input} id={input.name}>
                                                                    <option value="">Polisa</option>
                                                                    <option value="Polisa Grodzki">Polisa Grodzki
                                                                    </option>
                                                                    <option value="Polisa Leasing">Polisa Leasing
                                                                    </option>
                                                                    <option value="Polisa Obca">Polisa Obca</option>
                                                                    <option value="Polisa Dostawca">Polisa Dostawca
                                                                    </option>
                                                                </Select>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <p>&nbsp;</p>

                                                <Field
                                                    name={`${name}.margin`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.netInstallment`}
                                                    type="number"
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.leasingPolicy`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.additionalProduct`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.additionalFees`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.feesSumPercentage`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.feesSum`}
                                                    parse={value => (value === "" ? null : value)}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapperWithoutLabel>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </InputWrapperWithoutLabel>
                                                            {(meta.error || meta.submitError) && meta.touched && (
                                                                <span>{meta.error || meta.submitError}</span>
                                                            )}
                                                        </>
                                                    )}/>
                                                <Files name={`${name}.files`} index={index} setFilesToForm={setFilesToForm}/>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </FieldArray>


                        </StyledForm>
                    )}
                />
            </>}
        </>
    );
};
