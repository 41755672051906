import React, {useEffect, useState} from 'react';
import {Input, Label, Select, Switch, TopFormWrapper} from "../../components/Form";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {downloadFile} from "../../helpers/file";

const StyledWrapper = styled.div`
display: flex;
flex-direction: row;
div{
margin-right: 20px;
}
&>div:first-child{
padding-right: 20px;
border-right: 1px solid black;
p,h4{
margin-top: 20px;
}
}
`;

const InputWrapper = styled.div`
display: grid;
grid-template-columns: 140px 320px;
align-items: center;
margin-bottom: 10px;
font-size: 1.2rem;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
grid-template-columns: 480px;
align-items: center;
`;

const SubInfo = styled.div`
  margin-left: 10px;
`;

const LabelWithCheckboxLeft = styled(Label)`
  display: flex;
  span{
    margin-right: 5px;
  }
`
const LabelWithCheckboxRight = styled(Label)`
  display: flex;
  span{
    margin-left: 5px;
  }
`

const StyledButton = styled(Button)`
margin-right: 15px;
`;

const CustomerDataWrapper = styled.div`
  margin-bottom: 20px;
`;

export const InquiryTab = ({toDoId, archived}) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [inquiry, setInquiry] = useState({});
    const [clientName, setClientName] = useState(null);
    const [client, setClient] = useState(null);
    const [cars, setCars] = useState(null);
    const [finances, setFinances] = useState(null);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/inquiry`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {inquiry: newInquiry, isActive, clientFullName, clientData, carsData, financesData}}) => {
                setClientName(clientFullName);
                setIsEnabled(isActive);
                setInquiry(newInquiry);
                setClient(clientData);
                setCars(carsData);
                setFinances(financesData);
            });
        }
    }, [token, toDoId]);

    return (
        <>
            <Switch checked={isEnabled} onChange={() => {
                axios.post(`${apiUrl}/toDos/${toDoId}/inquiry/toggle`, {
                    isActive:!isEnabled,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(({data: {inquiry: newInquiry}}) => {
                    setInquiry(newInquiry);
                });
                setIsEnabled(!isEnabled)
            }}/>
            <TopFormWrapper>
                <div>
                    <h2>Zapytanie ofertowe</h2>
                </div>
                <div>
                    {clientName && (
                        clientName.map((item,key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>
            {!!isEnabled && <>
                <Form
                    onSubmit={values => {
                        axios.post(`${apiUrl}/toDos/${toDoId}/inquiry`,values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {inquiry: newInquiry, isActive}}) => {
                            setIsEnabled(isActive);
                            setInquiry(newInquiry);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{...inquiry,isActive:true}}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <StyledWrapper>
                                <CustomerDataWrapper>
                                    {client !== null && (
                                        <>
                                            <h4>
                                                DANE KLIENTA
                                            </h4>
                                            <p>
                                                NIP: {client.client_nip ?? '-'} <br/>
                                                Działalność: {client.client_activity ?? '-'} <br/>
                                                Nazwa: {client.client_name ?? '-'} <br/>
                                                KRS: {client.client_krs ?? '-'}<br/>
                                                Ulica i numer lokalu: {client.client_street ?? '-'}<br/>
                                                Kod pocztowy: {client.client_postalCode ?? '-'}<br/>
                                                Miejscowość: {client.client_city ?? '-'}<br/>
                                                Numer telefonu: {client.client_phone ?? '-'}<br/>
                                                E-mail: {client.client_email ?? '-'}<br/>
                                            </p>
                                        </>
                                    )}

                                    {cars !== null && cars.length > 0 && (
                                        cars.map((car) => (
                                            <>
                                                <h4>
                                                    DANE SAMOCHODU WARIANT {car.variantNumber}
                                                </h4>
                                                <p>
                                                    Rodzaj przedmiotu: {car.item ?? '-'} <br/>
                                                    Stan: {car.condition ?? '-'}<br/>
                                                    Marka: {car.mark ?? '-'}<br/>
                                                    Model: {car.model ?? '-'}<br/>
                                                    Rocznik: {car.yearbook ?? '-'}<br/>
                                                    Data 1 rejestracji: {car.firstRegistration ?? '-'}<br/>
                                                    Numer rejestracyjny: {car.registrationNumber ?? '-'}<br/>
                                                    VIN: {car.vin ?? '-'}<br/>
                                                    Wersja wyposażenia: {car.equipmentVersion ?? '-'}<br/>
                                                    Przebieg: {car.mileage ?? '-'}<br/>
                                                    Cena netto: {car.listPrice ?? '-'}<br/>
                                                </p>
                                            </>
                                        ))
                                    )}

                                    {finances !== null && finances.length > 0 && (
                                        finances.map(({variantNumber, institution, financingType}) => (
                                            <>
                                                <h4>
                                                    FINANSOWANIE WARIANT {variantNumber}
                                                </h4>
                                                <p>
                                                    Instytucja: {institution ?? '-'} <br/>
                                                    Rodzaj finansowania: {financingType ?? '-'}<br/>
                                                </p>
                                            </>
                                        ))
                                    )}
                                </CustomerDataWrapper>
                                <div>
                                    <Field
                                        name='leadTime'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Czas realizacji</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name='vat'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Suma ubezpieczenia VAT</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Suma ubezpieczenia VAT</option>
                                                        <option value="Netto">Netto</option>
                                                        <option value="Netto + 50%">Netto + 50%</option>
                                                        <option value="Brutto">Brutto</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name='transfer'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Cesja</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Cesja</option>
                                                        <option value="Nie">Nie</option>
                                                        <option value="Tak">Tak</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name='co-ownership'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Współwłasność</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Współwłasność</option>
                                                        <option value="Finansujący">Finansujący</option>
                                                        <option value="Inny">Inny</option>
                                                        <option value="Nie">Nie</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <h4>ZAKRES UBEZPIECZENIA</h4>

                                    <Field
                                        name='oc'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxRight>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        <span>OC</span>
                                                    </LabelWithCheckboxRight>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>

                                    <Field
                                        name='directLossAdjustment'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>- Bezpośrednia Likwidacja Szkód</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </SubInfo>
                                        )}/>

                                    <Field
                                        name='liabilityDiscountProtection'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>- Ochrona Zniżki OC</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </SubInfo>
                                        )}/>


                                    <Field
                                        name='ac'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxRight>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        <span>AC + KR</span>
                                                    </LabelWithCheckboxRight>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>

                                    <Field
                                        name='aso'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>ASO</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>

                                    <Field
                                        name='affiliateNetwork'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>Sieć Partnerska</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>
                                    <Field
                                        name='estimate'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>Kosztorys</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>

                                    <Field
                                        name='sumConsumption'
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>- Konsumpcja sumy</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Konsumpcja sumy</option>
                                                        <option value="wprowadzona">wprowadzona</option>
                                                        <option value="zniesiona">zniesiona</option>
                                                    </Select>
                                                </InputWrapper>
                                            </SubInfo>
                                        )}/>

                                    <Field
                                        name='fixedSumInsured'
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>- Stała suma ubezp.</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="Tak">Tak</option>
                                                        <option value="Nie">Nie</option>
                                                    </Select>
                                                </InputWrapper>
                                            </SubInfo>
                                        )}/>
                                    <Field
                                        name='acDiscountProtection'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>- Ochrona zniżki AC</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </SubInfo>
                                        )}/>

                                    <Field
                                        name='nnw'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxRight>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        <span>NNW</span>
                                                    </LabelWithCheckboxRight>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>
                                    <Field
                                        name='windows'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxRight>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        <span>Szyby</span>
                                                    </LabelWithCheckboxRight>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>
                                    <Field
                                        name='assistance'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxRight>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        <span>Holowanie / Assistance</span>
                                                    </LabelWithCheckboxRight>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>
                                    <Field
                                        name='assistanceCountry'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>- Assistance kraj</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </SubInfo>
                                        )}/>
                                    <Field
                                        name='assistanceEU'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <SubInfo>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>- Assistance EU</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </SubInfo>
                                        )}/>

                                    <Field
                                        name='foreignPolicyCost'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Koszt polisa obca</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>


                                    <Field
                                        name='payment'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Płatność</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="Jednorazowo">Jednorazowo</option>
                                                        <option value="Finansowanie Leasing">Finansowanie Leasing
                                                        </option>
                                                        <option value="Raty Ubezpieczyciel">Raty Ubezpieczyciel</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name='paidPremium'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Składka płatna</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Składka płatna</option>
                                                        <option value="Składka jednorazowa">Składka jednorazowa</option>
                                                        <option value="Składka 2 raty">Składka 2 raty</option>
                                                        <option value="Składka 4 raty">Składka 4 raty</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>


                                    <Field
                                        name='additionalInformation'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Informacje dodatkowe</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                </div>
                                <div>
                                    <Field
                                        name='urgent'
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapperWithoutLabel>
                                                    <LabelWithCheckboxLeft>
                                                        <span>Pilne</span>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </LabelWithCheckboxLeft>
                                                </InputWrapperWithoutLabel>
                                            </>
                                        )}/>


                                </div>
                            </StyledWrapper>
                            {!archived && (
                                <StyledButton>Zapisz</StyledButton>
                            )}
                            <StyledButton inactive type="button" onClick={()=>{
                                downloadFile(
                                    `${apiUrl}/toDos/${toDoId}/inquiry/print`,
                                    token,
                                    `${toDoId}-zapytanie-ofertowe.pdf`
                                );
                            }}>Generuj PDF</StyledButton>
                        </form>
                    )}
                />
            </>}
        </>
    );
};
