export const sumOfValues = (values, index, without) => {
    let sum = 0.0;
    if (without !== 'sumInsured') {
        //sum += parseFloat(values.policyOffers[index].sumInsured);
    }
    if (without !== 'oc') {
        if(values.policyOffers[index].isOc && values.policyOffers[index].oc){
            sum += parseFloat(values.policyOffers[index].oc);
        }
    }
    if (without !== 'liabilityDiscountProtection') {
        if(values.policyOffers[index].isLiabilityDiscountProtection && values.policyOffers[index].liabilityDiscountProtection){
            sum += parseFloat(values.policyOffers[index].liabilityDiscountProtection);
        }
    }
    if (without !== 'bls') {
        if(values.policyOffers[index].isBls && values.policyOffers[index].bls){
            sum += parseFloat(values.policyOffers[index].bls);
        }
    }
    if (without !== 'ac') {
        if(values.policyOffers[index].isAc && values.policyOffers[index].ac){
            sum += parseFloat(values.policyOffers[index].ac);
        }
    }
    if (without !== 'fixedSum') {
        if(values.policyOffers[index].isFixedSum && values.policyOffers[index].fixedSum){
            sum += parseFloat(values.policyOffers[index].fixedSum);
        }
    }
    if (without !== 'luggage') {
        if(values.policyOffers[index].isLuggage && values.policyOffers[index].luggage){
            sum += parseFloat(values.policyOffers[index].luggage);
        }
    }
    if (without !== 'ownShare' && values.policyOffers[index].ownShare) {
        sum += parseFloat(values.policyOffers[index].ownShare);
    }
    if (without !== 'pzu' && values.policyOffers[index].pzu) {
        sum += parseFloat(values.policyOffers[index].pzu);
    }
    if (without !== 'nnw' && values.policyOffers[index].nnw) {
        sum += parseFloat(values.policyOffers[index].nnw);
    }
    if (without !== 'windows' && values.policyOffers[index].windows) {
        sum += parseFloat(values.policyOffers[index].windows);
    }
    return sum;
}

export const calcMainValue = (value,values,index, without, force) => {
    if(without === 'oc'){
        if(force === null){
            if(values.policyOffers[index].isOc){
                return parseFloat(value)
            }
            return 0.0;
        }
        else{
            if(force){
                return parseFloat(value)
            }
            return 0.0;
        }
    }
    if(without === 'liabilityDiscountProtection'){
        if(force === null){
            if(values.policyOffers[index].isLiabilityDiscountProtection){
                return parseFloat(value)
            }
            return 0.0;
        }
        else{
            if(force){
                return parseFloat(value)
            }
            return 0.0;
        }
    }
    if(without === 'bls'){
        if(force === null){
            if(values.policyOffers[index].isBls){
                return parseFloat(value)
            }
            return 0.0;
        }
        else{
            if(force){
                return parseFloat(value)
            }
            return 0.0;
        }
    }
    if(without === 'ac'){
        if(force === null){
            if(values.policyOffers[index].isAc){
                return parseFloat(value)
            }
            return 0.0;
        }
        else{
            if(force){
                return parseFloat(value)
            }
            return 0.0;
        }
    }
    if(without === 'fixedSum'){
        if(force === null){
            if(values.policyOffers[index].isFixedSum){
                return parseFloat(value)
            }
            return 0.0;
        }
        else{
            if(force){
                return parseFloat(value)
            }
            return 0.0;
        }
    }
    if(without === 'luggage'){
        if(force === null){
            if(values.policyOffers[index].isLuggage){
                return parseFloat(value)
            }
            return 0.0;
        }
        else{
            if(force){
                return parseFloat(value)
            }
            return 0.0;
        }
    }
    return parseFloat(value);
}
