import React, {useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import styled from "styled-components";
import {FileButton, Input, Label, Select} from "../../components/Form";
import {ClientContactInfo} from "../../components/ClientContactInfo";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import {StyledTextarea} from "../../components/styled/inputs";

const Grid = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
`
const StyledForm = styled.form`
display: grid;
grid-template-columns: 400px 270px;
grid-gap: 60px;
margin-right: 60px;
`;

const InputWrapper = styled.div`
display: grid;
grid-template-columns: 160px 240px;
align-items: center;
margin-bottom: 10px;
`;

const InputSmallWrapper = styled(InputWrapper)`
grid-template-columns: 120px 150px;
`;

const InputFileWrapper = styled.label`
display: grid;
grid-template-columns: 160px auto auto;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
font-size: 1.2rem;
position: relative;
input{
position: absolute;
left: 0;
top: 0;
height: 0;
width: 0;
}
`;

const StyledA = styled.a`
color: black;
text-decoration: none;
margin-left: 10px;
`;

const ClientInfoWrapper = styled.div`
  margin-left: 20px;
`;

export const VehicleForm = ({initialValues, onSubmit, onSold, vehicleId, withClient, client}) => {
    const [registrationCertificateFile, setRegistrationCertificateFile] = useState(null);
    const [proformaFile, setProformaFile] = useState(null);
    const [specificationFile, setSpecificationFile] = useState(null);
    const [otherFile, setOtherFile] = useState(null);

    const resetFiles = () => {
        setRegistrationCertificateFile(null);
        setProformaFile(null);
        setSpecificationFile(null);
        setOtherFile(null);
    }

    return (
        <>
            <Grid>
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                    }}
                    initialValues={initialValues}
                    onSubmit={values => {
                        onSubmit(values, registrationCertificateFile,proformaFile, specificationFile, otherFile, resetFiles)
                    }}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <Field
                                    name='type'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Rodzaj przedmiotu*</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Rodzaj przedmiotu</option>
                                                    <option value="Samochód osobowy">Samochód osobowy
                                                    </option>
                                                    <option value="Samochód ciężarowy do 3,5 t">Samochód
                                                        ciężarowy do
                                                        3,5 t
                                                    </option>
                                                    <option
                                                        value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                        ciężarowy
                                                        powyżej 3,5 t
                                                    </option>
                                                    <option value="Ciągnik samochodowy">Ciągnik
                                                        samochodowy
                                                    </option>
                                                    <option value="Naczepa">Naczepa</option>
                                                    <option value="Przyczepa ">Przyczepa</option>
                                                    <option value="Maszyna">Maszyna</option>
                                                    <option value="Motocykl">Motocykl</option>
                                                    <option value="Inne">Inne</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name="condition"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Stan*</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Stan</option>
                                                    <option value="Nowy">Nowy</option>
                                                    <option value="Nowy zarejestrowany">Nowy
                                                        zarejestrowany
                                                    </option>
                                                    <option value="Używany">Używany</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name="mark"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Marka</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="model"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Model</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="capacity"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Pojemność</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="yearbook"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Rocznik</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="firstRegistration"
                                    type="date"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Data 1 rejestracji</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="registrationNumber"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Numer rejestracyjny</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="vin"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>VIN</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="equipmentVersion"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Wersja wyposażenia</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="mileage"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Przebieg</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="salesDocument"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Dokument sprzedaży</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="">Dokument sprzedaży</option>
                                                    <option value="FV VAT23%">FV VAT23%</option>
                                                    <option value="FV Marża">FV Marża</option>
                                                    <option value="Umowa sprzedaży">Umowa sprzedaży
                                                    </option>
                                                    <option value="Inny">Inny</option>
                                                </Select>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="listPrice"
                                    type="number"
                                    min="0"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Cena katalogowa</Label>
                                                <Input {...input} id={input.name} placeholder=""
                                                       step="0.01"/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="net"
                                    type="number"
                                    min="0"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Cena netto</Label>
                                                <Input {...input} id={input.name} placeholder=""
                                                       step="0.01"/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="gross"
                                    type="number"
                                    min="0"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Cena brutto</Label>
                                                <Input {...input} id={input.name} placeholder=""
                                                       step="0.01"/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="discount"
                                    type="number"
                                    min="0"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Rabat%</Label>
                                                <Input {...input} id={input.name} placeholder=""
                                                       step="0.01"/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="provider"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Dostawca</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name="nip"
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>NIP dostawcy</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name={`registration_certificate_path`}
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper htmlFor={input.name} onChange={e => {
                                                input.onChange(e)
                                                setRegistrationCertificateFile(e.target.files[0]);
                                            }}>
                                                <input {...input} id={input.name}/>
                                                <span>Dowód rejestracyjny</span>
                                                <FileButton>
                                                    {registrationCertificateFile ? registrationCertificateFile.name : "Wybierz plik"}
                                                </FileButton>
                                                {values.registrationProof && <StyledA href={values.registrationProof} rel="noopener noreferrer"
                                                                        target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name={`proforma_path`}
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper htmlFor={input.name} onChange={e => {
                                                input.onChange(e)
                                                setProformaFile(e.target.files[0])
                                            }}>
                                                <input {...input} id={input.name}/>
                                                <span>Proforma</span>
                                                <FileButton>
                                                    {proformaFile ? proformaFile.name : "Wybierz plik"}
                                                </FileButton>
                                                {values.proforma && <StyledA href={values.proforma} rel="noopener noreferrer"
                                                                                             target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name={`specification_path`}
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper htmlFor={input.name} onChange={e => {
                                                input.onChange(e)
                                                setSpecificationFile(e.target.files[0]);
                                            }}>
                                                <input {...input} id={input.name}/>
                                                <span>Specyfikacja</span>
                                                <FileButton>
                                                    {specificationFile ? specificationFile.name : "Wybierz plik"}
                                                </FileButton>
                                                {values.specification && <StyledA href={values.specification} rel="noopener noreferrer"
                                                                             target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name={`other_path`}
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileWrapper htmlFor={input.name} onChange={e => {
                                                input.onChange(e)
                                                setOtherFile(e.target.files[0]);
                                            }}>
                                                <input {...input} id={input.name}/>
                                                <span>Inne</span>
                                                <FileButton>
                                                    {otherFile ? otherFile.name : "Wybierz plik"}
                                                </FileButton>
                                                {values.other && <StyledA href={values.other} rel="noopener noreferrer"
                                                                          target="_blank">Zobacz</StyledA>}
                                            </InputFileWrapper>
                                        </>
                                    )}/>
                                <Button>Zapisz</Button>
                            </div>
                            {withClient && (
                                <div>
                                    <Field
                                        name={`institution`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Instytucja*</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Instytucja</option>
                                                        <option value="ALIOR LEASING SP. Z O.O.">ALIOR LEASING SP. Z O.O.</option>
                                                        <option value="ATHLON CAR LEASE POLSA SP. Z O.O.">ATHLON CAR LEASE POLSA SP. Z O.O.</option>
                                                        <option value="SANTANDER LEASING S.A.">SANTANDER LEASING S.A.</option>
                                                        <option value="MLEASING SP. Z O.O.">MLEASING SP. Z O.O.</option>
                                                        <option value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.">EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.</option>
                                                        <option value="VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE">VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE</option>
                                                        <option value="RCI LEASING POLSKA SP. Z O.O.">RCI LEASING POLSKA SP. Z O.O.</option>
                                                        <option value="PKO LEASING S.A.">PKO LEASING S.A.</option>
                                                        <option value="FCA LEASING POLSKA SP. Z O.O.">FCA LEASING POLSKA SP. Z O.O.</option>
                                                        <option value="IDEA FINANCE SP. Z O.O.">IDEA FINANCE SP. Z O.O.</option>
                                                        <option value="IDEA FLEET S.A.">IDEA FLEET S.A.</option>
                                                        <option value="GETIN LEASING S.A.">GETIN LEASING S.A.</option>
                                                        <option value="GETIN FLEET S.A.">GETIN FLEET S.A.</option>
                                                        <option value="Alior Leasing">Alior Leasing</option>
                                                        <option value="Athlon Car Lease Polska">Athlon Car Lease Polska</option>
                                                        <option value="BOŚ Leasing ">BOŚ Leasing </option>
                                                        <option value="BMW Financial Services Polska Sp. z o.o.">BMW Financial Services Polska Sp. z o.o.</option>
                                                        <option value="BNP Paribas Lease Group Sp. z o.o.">BNP Paribas Lease Group Sp. z o.o.</option>
                                                        <option value="BPS Leasing Spółka Akcyjna (BPS Leasing S.A.)">BPS Leasing Spółka Akcyjna (BPS Leasing S.A.)</option>
                                                        <option value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A.">EUROPEJSKI FUNDUSZ LEASINGOWY S.A.</option>
                                                        <option value="Idea Getin Leasing S.A.">Idea Getin Leasing S.A.</option>
                                                        <option value="IMPULS-LEASING Polska Sp. z o.o.">IMPULS-LEASING Polska Sp. z o.o.</option>
                                                        <option value="ING LEASE (Polska) Sp. z o.o.">ING LEASE (Polska) Sp. z o.o.</option>
                                                        <option value="Millennium Leasing Sp. z o.o.">Millennium Leasing Sp. z o.o.</option>
                                                        <option value="mLeasing Sp. z o.o.">mLeasing Sp. z o.o.</option>
                                                        <option value="PEKAO LEASING Sp. z o.o.">PEKAO LEASING Sp. z o.o.</option>
                                                        <option value="PKO LEASING S.A.">PKO LEASING S.A.</option>
                                                        <option value="PSA Finance Polska sp. z o.o.">PSA Finance Polska sp. z o.o.</option>
                                                        <option value="Santander Consumer Multirent Sp.z o.o.">Santander Consumer Multirent Sp.z o.o.</option>
                                                        <option value="Santander Leasing S.A.">Santander Leasing S.A.</option>
                                                        <option value="SGB Leasing Sp. z o.o.">SGB Leasing Sp. z o.o.</option>
                                                        <option value="SIEMENS FINANCE Sp. z o.o.">SIEMENS FINANCE Sp. z o.o.</option>
                                                        <option value="TOYOTA LEASING POLSKA SP. Z O.O.">TOYOTA LEASING POLSKA SP. Z O.O.</option>
                                                    </Select>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name={`financingType`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Rodzaj finansowania*</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Rodzaj finansowania</option>
                                                        <option value="Leasing operacyjny">Leasing
                                                            operacyjny
                                                        </option>
                                                        <option value="Pożyczka">Pożyczka</option>
                                                        <option value="CFM">CFM</option>
                                                        <option value="Leasing finansowy">Leasing
                                                            finansowy
                                                        </option>
                                                        <option value="Kredyt">Kredyt</option>
                                                        <option value="brak">brak</option>
                                                    </Select>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name={`secondNet`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Cena netto*</Label>
                                                    <Input {...input} id={input.name} placeholder="" min="0"
                                                           step="0.01"/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name={`paymentPercentage`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Wpłata%*</Label>
                                                    <Input {...input} id={input.name} placeholder="" min="0"
                                                           step="0.01"/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name={`payment`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Wpłata netto PLN*</Label>
                                                    <Input {...input} id={input.name} placeholder="" min="0"
                                                           step="0.01"/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`period`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Okres mc*</Label>
                                                    <Input {...input} id={input.name} placeholder="" min="0"
                                                           step="1"
                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               const startDate = values.purchased_at;
                                                               if(startDate){
                                                                   const d = moment(startDate).add(e.target.value, 'months').toDate();
                                                                   const datestring =d.getFullYear()+'-'+("0"+(d.getMonth()+1)).slice(-2)+'-'+("0" + d.getDate()).slice(-2);
                                                                   form.mutators.setValue('end_contract_at', datestring)
                                                               }
                                                           }}
                                                    />
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`repurchasePercentage`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Wykup%*</Label>
                                                    <Input {...input} id={input.name} placeholder="" min="0"
                                                           step="0.01"/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`repurchase`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Wykup netto*</Label>
                                                    <Input {...input} id={input.name} placeholder="" min="0"
                                                           step="0.01"/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name={`provision`}
                                        type="number"
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Prowizja*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>


                                    <Field
                                        name={`policy`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Polisa*</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Polisa</option>
                                                        <option value="Polisa Grodzki">Polisa Grodzki
                                                        </option>
                                                        <option value="Polisa Leasing">Polisa Leasing
                                                        </option>
                                                        <option value="Polisa Obca">Polisa Obca</option>
                                                        <option value="Polisa Dostawca">Polisa Dostawca
                                                        </option>
                                                    </Select>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="purchased_at"
                                        type="date"
                                        render={({input, meta}) => (
                                            <InputSmallWrapper>
                                                <Label htmlFor={input.name}>Data zakupy</Label>
                                                <Input {...input} id={input.name} placeholder=""

                                                       onChange={(e) => {
                                                           input.onChange(e);
                                                           const period = values.period;
                                                           if(period){
                                                               const d = moment(e.target.value).add(period, 'months').toDate();
                                                               const datestring =d.getFullYear()+'-'+("0"+(d.getMonth()+1)).slice(-2)+'-'+("0" + d.getDate()).slice(-2);
                                                               form.mutators.setValue('end_contract_at', datestring)
                                                           }
                                                       }}
                                                />
                                            </InputSmallWrapper>
                                        )}/>
                                    <Field
                                        name="end_contract_at"
                                        type="date"
                                        render={({input, meta}) => (
                                            <InputSmallWrapper>
                                                <Label htmlFor={input.name}>Koniec umowy</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputSmallWrapper>
                                        )}/>
                                    <p>ODPOWIEDZI</p>

                                    <Field
                                        name={`margin`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Marża</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`netInstallment`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Rata netto*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`leasingPolicy`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Polisa leasing*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`additionalProduct`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Produkt dodatkowy</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`additionalFees`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Opłaty dodatkowe*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`feesSumPercentage`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Suma opłat %</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name={`feesSum`}
                                        render={({input, meta}) => (
                                            <>
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Suma opłat PLN</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name='note'
                                        render={({input}) => (
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Notatka</Label>
                                                <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                            </InputWrapper>
                                        )}/>
                                </div>
                            )}
                        </StyledForm>
                    )}
                />
                {!!vehicleId && <Form
                    initialValues={initialValues}
                    onSubmit={values => {
                        const {sold_at} = values;
                        if(sold_at){
                            onSold(values);
                        }
                    }}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <Field
                                name="sold_at"
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <Input {...input} id={input.name} placeholder=""/>
                                    </>
                                )}/>
                            <Button>Sprzedany</Button>
                        </form>
                    )}
                />
                }
                {client && (
                    <ClientInfoWrapper>
                        <ClientContactInfo clientSimple={client}/>
                    </ClientInfoWrapper>
                )}
            </Grid>
        </>
    );
};
