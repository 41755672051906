import {useSelector} from "react-redux";
import {Field, Form} from "react-final-form";
import axios from "axios";
import {toast} from "react-toastify";
import {FileButton, Input, Label, Select} from "./Form";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {apiUrl} from "../views/api";
import {Button} from "./Buttons";

const Grid = styled.div`
  padding: 10px;
  background-color: #F0F0F0;
  margin: 8px;
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 240px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const InputFileWrapper = styled.label`
  margin-bottom: 10px;
  font-size: 1.2rem;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
  }
`;


const FileWrapper = styled.div`

`;

const StyledButton = styled(Button)`
  width: 25%;
`;

export const MissingTaskForm = ({toDoId,description, update, closeModal}) => {
    const token = useSelector((store) => store.token);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        axios.get(`${apiUrl}/personal/tasks`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers, user_id: newUserId}}) => {
            setUsers(newUsers);
            setUserId(newUserId);
        })
    }, [token])

    return (
        <>
            <Grid>
                <Form
                    initialValues={{user_id: userId, description:description}}
                    onSubmit={values => {
                        const fd = new FormData();
                        Object.keys(values).forEach((key) => {
                            fd.append(key, values[key] ?? '')
                        })
                        if (file) {
                            fd.append('file', file)
                        }
                        axios.post(`${apiUrl}/tasks/${toDoId}`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Dane zostały zapisane.");
                            update();
                            closeModal(null);
                        }).catch(() => {
                            toast.error("Uzupełnij dane");
                        });
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>

                            <Field
                                name='description'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Opis</Label>
                                            <Input {...input} id={input.name} placeholder="" />
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='date'
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data zadania</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name="user_id"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Użytkownik</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="">Użytkownik</option>
                                                {users.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name={`pathFile`}
                                type="file"
                                render={({input, meta}) => (
                                    <InputWrapper>
                                        <span>Załącznik</span>
                                        <InputFileWrapper htmlFor={input.name} onChange={e => {
                                            input.onChange(e)
                                            setFile(e.target.files[0])
                                        }}>
                                            <FileWrapper>
                                                <input {...input} id={input.name}/>
                                                <FileButton>
                                                    {file !== null ? (
                                                        file.name
                                                    ) : (
                                                        <>Wybierz plik</>
                                                    )}
                                                </FileButton>
                                            </FileWrapper>
                                        </InputFileWrapper>
                                    </InputWrapper>
                                )}
                            />
                            <StyledButton>Zapisz</StyledButton>
                        </StyledForm>
                    )}
                />
            </Grid>
        </>
    );
}
