import React, {useEffect, useState} from 'react';
import {Redirect, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {VehicleForm} from "./VehicleForm";
import axios from "axios";
import {apiUrl} from "../api";
import {toast, ToastContainer} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";


const ButtonWrapper = styled.div`
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button:first-child{
    margin-right: 20px;
    }
`;

const RemoveButton = styled.button`
    background:none;
    border: none;
    color: ${({theme: {colors: {red}}}) => red};
    font-weight: 600;
`;

const H1 = styled.h1`
  margin-bottom: 10px;
`;

export const VehicleEdit = () => {
    const token = useSelector((store) => store.token);
    const match = useRouteMatch();
    const [vehicle, setVehicle] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        axios.get(`${apiUrl}/vehicle/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicle: newVehicle}}) => {
            setVehicle(newVehicle);
        }).catch(e => {
            console.log(e.response)
        });
    }, [token, match.params.id]);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <ToastContainer
                position="bottom-right"
            />
            <ButtonWrapper>
                <RemoveButton onClick={() => {
                    setOpenModal(true)
                }}>
                    <FontAwesomeIcon icon={faTrash} fixedWidth/>
                    Usuń pojazd
                </RemoveButton>
            </ButtonWrapper>
            <PanelContentWrapper>
                <H1>Dany pojazd</H1>
                <VehicleForm
                    withClient={vehicle ? vehicle.client_id : false}
                    client={vehicle ? vehicle.client : null}
                    initialValues={vehicle}
                    vehicleId={match.params.id}
                    onSubmit={(values, registrationCertificateFile,proformaFile, specificationFile, otherFile, resetFiles) => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        })
                        formData.append('_method', 'PUT');

                        if(registrationCertificateFile){
                            formData.append('registrationProof', registrationCertificateFile);
                        }
                        if(proformaFile){
                            formData.append('proforma', proformaFile);
                        }
                        if(specificationFile){
                            formData.append('specification', specificationFile);
                        }
                        if(otherFile){
                            formData.append('other', otherFile);
                        }
                        axios.post(`${apiUrl}/vehicle/${match.params.id}`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {vehicle: newVehicle}}) => {
                            resetFiles();
                            setVehicle(newVehicle);
                            toast.success("Dane zostały zapisane");
                        }).catch(e => {
                            console.log(e.response);
                            toast.error("Coś poszło nie tak.");
                        });
                    }}
                    onSold={
                        values => {
                            axios.put(`${apiUrl}/vehicle/${match.params.id}`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                }
                            }).then(({data: {vehicle: newVehicle}}) => {
                                setVehicle(newVehicle);
                                toast.success("Dane zostały zapisane");
                            }).catch(e => {
                                toast.error("Coś poszło nie tak.");
                                console.log(e.response);
                            });
                        }
                    }
                />
            </PanelContentWrapper>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć pojazd?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/vehicle/${match.params.id}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect('/pojazdy');
                            toast.success("Pojazd został usunięty.");
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
