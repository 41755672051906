import React, {useState,useEffect} from 'react';
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Redirect} from "react-router-dom";
import {InsuranceCompanyForm} from "../../components/InsuranceCompanyForm";
import {toast} from "react-toastify";

export const EditInsuranceCompany = ({match}) => {
    const [redirect, setRedirect] = useState(null);
    const [company, setCompany] = useState(null);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios.get(`${apiUrl}/companies/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {insuranceCompany}}) => {
            console.log(insuranceCompany);
            setCompany(insuranceCompany);
        });
    }, [match.params.id, token]);


    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <h2>Edytuj firmę</h2>
                <InsuranceCompanyForm
                    onSubmit={values => {
                        axios.put(`${apiUrl}/companies/${match.params.id}`, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Firma została zaktualizowana.");
                            setRedirect(`/firmy`)
                        }).catch((e)=>{
                            toast.error(e.message);
                        });
                    }}
                    initialValues={{...company}}
                />
            </PanelContentWrapper>
        </>
    );
};
