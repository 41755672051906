import axios from "axios";
import {apiUrl} from "../../views/api";

export const gusApi = {
    get: (nip, token) => {
        return axios.get(`${apiUrl}/gus/check`, {
            params: {
                nip: nip,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    }
};
