import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, Redirect} from "react-router-dom";
import {Field, Form} from "react-final-form";
import axios from 'axios';
import {Button} from "../components/Buttons";
import Image from '../static/img/image.png';
import {InputFloating} from "../components/Form";
import {apiUrl} from "./api";
import {LOGIN} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {getToken} from "../firebase/firebase";

const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
flex-direction: column;
min-height: calc(100vh - 140px);
`;
const StyledImg = styled.img`
  align-self: flex-end;
`;
const StyledForm = styled.form`
max-width: 90%;
width: 460px;
display: flex;
flex-direction: column;
align-items: center;
`;

const LinkAndCheckboxWrapper = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
margin-bottom: 40px;
`;
const StyledLink = styled(Link)`
font-size: 1.4rem;
font-weight: 300;
color: ${({theme: {colors: {black}}}) => black};
text-decoration: none;
`;

export const Login = () => {
    const [redirect, setRedirect] = useState(null);
    const [step, setStep] = useState(1);
    const [deviceToken, setDeviceToken] = useState(null);
    const dispatch = useDispatch();
    const token = useSelector((store) => store.token);


    useEffect(() => {
        getToken().then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                setDeviceToken(currentToken);
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
        if (token) {
            setRedirect("/dashboard");
        }
    }, [token]);

    const login = async values => {
        axios.post(`${apiUrl}/login`, values, {}).then(()=>{
            setStep(2);
        }).catch(() => {
            toast.error("Niepoprawne dane logowania.");
        });
    };

    const loginSms = async values => {
        values.deviceToken = deviceToken;
        axios.post(`${apiUrl}/login/sms/${values.code}`, values, {}).then((response)=>{
            if (response.data.access_token) {
                const {access_token: newToken, expires_in: expiresIn, role} = response.data;
                dispatch({
                    type: LOGIN,
                    payload: {token: newToken, role, expiresIn: (new Date().getTime() + (expiresIn * 1000))}
                });
            }
        }).catch(() => {
            toast.error("Niepoprawne dane logowania.");
        });
    };

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <ToastContainer
                position="bottom-right"
            />
            <Wrapper>
                <h1>Logowanie</h1>
                {step === 1 && (
                    <Form
                        onSubmit={login}
                        render={({handleSubmit}) => (
                            <StyledForm onSubmit={handleSubmit}>
                                <Field
                                    name="email"
                                    type="email"
                                    render={({input, meta}) => (
                                        <InputFloating label="Login" {...input} {...meta}/>
                                    )}
                                />
                                <Field
                                    name="password"
                                    type="password"
                                    render={({input, meta}) => (
                                        <InputFloating label="Hasło" {...input} {...meta}/>
                                    )}
                                />

                                <LinkAndCheckboxWrapper>
                                    <span/>
                                    <StyledLink to="/reset-hasla">
                                        Przypomnij hasło
                                    </StyledLink>
                                </LinkAndCheckboxWrapper>
                                <Button>Zaloguj</Button>
                            </StyledForm>
                        )}
                    />
                )}

                {step === 2 && (
                    <Form
                        onSubmit={loginSms}
                        render={({handleSubmit}) => (
                            <StyledForm onSubmit={handleSubmit}>
                                <Field
                                    name="code"
                                    type="text"
                                    render={({input, meta}) => (
                                        <InputFloating label="Kod SMS" {...input} {...meta}/>
                                    )}
                                />
                                <Button>Zaloguj</Button>
                            </StyledForm>
                        )}
                    />
                )}

                <StyledImg src={Image} alt=""/>
            </Wrapper>
        </>
    );
}
