import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Input, Label, RoundSimpleButton, Select, Switch, TopFormWrapper} from "../../components/Form";
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {Redirect} from "react-router-dom";
import {Logs} from "../../components/Logs";
import {MissingTask} from "../../components/MissingTask";
import {MissingTaskForm} from "../../components/MissingTaskForm";

const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TasksActionWrapper = styled.div`
  flex-direction: row;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 480px repeat(3, 240px);
  grid-gap: 10px;
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
  padding-bottom: 15px;
  margin: 10px 0;
  align-items: baseline;
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 240px 240px;
  align-items: center;
  margin-bottom: 10px;
`;
const BottomWrapper = styled.div`
  margin-top: 20px;

  h4, p {
    padding: 10px 0;
  }

  p {
    color: ${({theme: {colors: {red}}}) => red};
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  div{
    display: flex;
  }
  label {
    margin-left: 30px;
  }

  margin-bottom: 10px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArchiveTab = ({toDoId, archived}) => {
    const [isEnabled, setIsEnabled] = useState(archived);
    const [users, setUsers] = useState([]);
    const [clientName, setClientName] = useState(null);
    const [client, setClient] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [finances, setFinances] = useState([]);
    const [registration, setRegistration] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [modal, setModal] = useState(null);
    const [change, setChange] = useState(1);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios.get(`${apiUrl}/toDos/${toDoId}/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers}}) => {
            setUsers(newUsers);
        })
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDo/${toDoId}/archive/fields`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {fields, clientFullName, tasks: newTasks}}) => {
                setClientName(clientFullName);
                if (fields.client) {
                    setClient(fields.client);
                }
                if (fields.vehicles) {
                    setVehicles(fields.vehicles);
                }
                if (fields.finances) {
                    setFinances(fields.finances);
                }
                if (fields.registration) {
                    setRegistration(fields.registration);
                }
                if (fields.policy) {
                    setPolicy(fields.policy);
                }
                setTasks(newTasks);
            });
        }
    }, [token, toDoId, change]);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Archiwizacja</h2>
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>

            <Logs
                todoId={toDoId}
            />

            {isEnabled && <>
                <Form
                    onSubmit={values => {
                        let archiveToDo = true;
                        if (!values.hasOwnProperty('createVehicle')) {
                            archiveToDo = false;
                            toast.error("Wybierz akcje związaną z pojazdem");
                        }
                        if (!values.hasOwnProperty('createPolice')) {
                            archiveToDo = false;
                            toast.error("Wybierz akcje związaną z polisą");
                        }
                        if (archiveToDo) {
                            axios.post(`${apiUrl}/toDo/${toDoId}/archive`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                }
                            }).then(() => {
                                toast.success("Twoje dane zostały zapisane.");
                                setRedirect(`/zadania/archiwum`)
                            }).catch(()=>{
                                toast.error("Coś poszło nie tak.");
                            });
                        }
                    }}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    initialValues={{tasks}}
                    render={({handleSubmit, values}) => (
                        <form onSubmit={handleSubmit}>
                            <FieldArray name="tasks">
                                {({fields}) => (
                                    <TasksWrapper>
                                        {!archived && (
                                            <TasksActionWrapper>
                                                <RoundSimpleButton
                                                    type="button"
                                                    onClick={() => fields.push({})}>
                                                    <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                                </RoundSimpleButton>
                                                <RoundSimpleButton
                                                    type="button"
                                                    onClick={() => fields.pop()}>
                                                    <FontAwesomeIcon icon={faMinus} color="white" size="2x"/>
                                                </RoundSimpleButton>
                                            </TasksActionWrapper>
                                        )}

                                        {fields.map((name, index) => (
                                                <Grid key={index}>
                                                    <Field
                                                        name={`${name}.description`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Zadanie inne</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.date`}
                                                        type="date"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.user_id`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Użytkownik</option>
                                                                        {users.map(({name, id: userId}) => (
                                                                            <option value={userId}>{name}</option>
                                                                        ))}
                                                                    </Select>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    {values.tasks[index].change_status_at && (
                                                        <span>Zakończone {values.tasks[index].change_status_at}</span>
                                                    )}
                                                </Grid>
                                            )
                                        )}

                                    </TasksWrapper>
                                )}
                            </FieldArray>
                            <BottomWrapper>
                                {client.length > 0 && (
                                    <>
                                        <h4>Klient</h4>
                                        <ErrorWrapper>
                                            {client.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={setModal(`Klient - ${item}`)}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {vehicles.length > 0 && (
                                    <>
                                        <h4>Samochód/Przedmiot</h4>
                                        <ErrorWrapper>
                                            {vehicles.map((item) => {
                                                return item.map((field) => (
                                                    <MissingTask
                                                        item={field}
                                                        closure={() => {
                                                            setModal(`Samochód/Przedmiot - ${field}`)
                                                        }}
                                                    />
                                                ))
                                            })}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {finances.length > 0 && (
                                    <>
                                        <h4>Finansowanie</h4>
                                        <ErrorWrapper>
                                            {finances.map((item) => {
                                                return item.map((field) => (
                                                    <MissingTask
                                                        item={field}
                                                        closure={() => {
                                                            setModal(`Finansowanie - ${field}`)
                                                        }}
                                                    />
                                                ))
                                            })}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {registration.length > 0 && (
                                    <>
                                        <h4>Rejestracja/Płatność</h4>
                                        <ErrorWrapper>
                                            {registration.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={() => {
                                                        setModal(`Rejestracja/Płatność - ${item}`)
                                                    }}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {policy.length > 0 && (
                                    <>
                                        <h4>Polisa</h4>
                                        <ErrorWrapper>
                                            {policy.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={() => {
                                                        setModal(`Polisa - ${item}`)
                                                    }}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                            </BottomWrapper>
                            {!archived && (
                                <>
                                    <RadioWrapper>
                                        <span>Stwórz pojazd</span>
                                        <Field
                                            name='createVehicle'
                                            type="radio"
                                            value="0"
                                            render={({input, meta}) => (
                                                <>
                                                    <div>
                                                        <Label htmlFor={`${input.name}-n`}>Nie</Label>
                                                        <Input {...input} id={`${input.name}-n`} placeholder=""/>
                                                    </div>
                                                </>
                                            )}/>
                                        <Field
                                            name='createVehicle'
                                            type="radio"
                                            value="1"
                                            render={({input, meta}) => (
                                                <>
                                                    <div>
                                                        <Label htmlFor={`${input.name}-y`}>Tak</Label>
                                                        <Input {...input} id={`${input.name}-y`} placeholder=""/>
                                                    </div>
                                                </>
                                            )}/>
                                    </RadioWrapper>

                                    <RadioWrapper>
                                        <span>Stwórz polisę</span>
                                        <Field
                                            name='createPolice'
                                            type="radio"
                                            value="0"
                                            render={({input, meta}) => (
                                                <>
                                                    <div>
                                                        <Label htmlFor={`${input.name}-n`}>Nie</Label>
                                                        <Input {...input} id={`${input.name}-n`} placeholder=""/>
                                                    </div>
                                                </>
                                            )}/>
                                        <Field
                                            name='createPolice'
                                            type="radio"
                                            value="1"
                                            render={({input, meta}) => (
                                                <>
                                                    <div>
                                                        <Label htmlFor={`${input.name}-y`}>Tak</Label>
                                                        <Input {...input} id={`${input.name}-y`} placeholder=""/>
                                                    </div>
                                                </>
                                            )}/>
                                        <Field
                                            name='createPolice'
                                            type="radio"
                                            value="2"
                                            render={({input, meta}) => (
                                                <>
                                                    <div>
                                                        <Label htmlFor={`${input.name}-x`}>Konkurencji</Label>
                                                        <Input {...input} id={`${input.name}-x`} placeholder=""/>
                                                    </div>
                                                </>
                                            )}/>
                                    </RadioWrapper>
                                    {(client.length || finances.length || registration.length || policy.length) ? (
                                        <Button type="button" onClick={() => {
                                            setOpenModal(true);
                                        }}>Archwizuj</Button>
                                    ) : (
                                        <Button>Archwizuj</Button>
                                    )}
                                </>
                            )}

                            {openModal && <Modal closeModal={() => {
                                setOpenModal(false)
                            }}>
                                <P>Czy na pewno chcesz archiwizować zadanie ?</P>

                                <ModalButtonWrapper>
                                    <Button>Tak</Button>
                                    <Button type="button" inactive onClick={() => setOpenModal(false)}>NIE</Button>
                                </ModalButtonWrapper>
                            </Modal>}
                        </form>
                    )}
                />
            </>}

            {modal && (
                <Modal closeModal={() => {
                    setModal(null)
                }}>
                    <MissingTaskForm
                        toDoId={toDoId}
                        description={modal}
                        closeModal={setModal}
                        update={() => {
                            setChange(change+1);
                        }
                        }
                    />
                </Modal>
            )}
        </>
    );
}
