import React from 'react';
import {RoundSimpleButton} from "./Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: row;
  p{
    margin-right: 10px;
  }
`;

export const MissingTask = ({item, closure}) => {
    return (
        <Content>
            <p>{item}</p>
            <RoundSimpleButton
                type="button"
                onClick={() => {
                    closure()
                }}>
                <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
            </RoundSimpleButton>
        </Content>
    );
}
