import React, {useEffect, useState} from 'react';
import {InputSearch, Table} from "../../components/Table";
import {StyledFontAwesomeIcon, StyledLink, StyledPanelContentWrapper} from "../../components/ListComponents";
import {faArrowRight, faCopy, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import styled from "styled-components";
import {Redirect} from "react-router-dom";
import {perPage} from "../../data/variables";

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

export const InsurancePoliciesList = () => {
    const [policies, setPolicies] = useState([]);
    const token = useSelector((store) => store.token);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [change, setChange] = useState(0);
    let timeout = 0;

    const search = (e) => {
        let searchText = e.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/policy`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                search: searchParam
            }
        }).then(({data: {policies: newPolicies,lastPage}}) => {
            setPolicies(newPolicies);
            setPages(lastPage);
        });
    },[token,page,searchParam, change]);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <div>
                <InputSearch onChange={(e) => {
                    search(e);
                }}/>
            </div>
            <StyledPanelContentWrapper>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>TU</th>
                        <th>Nr polisy</th>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Nr rejestracyjny</th>
                        <th>Składka</th>
                        <th>Data początku</th>
                        <th>Data końca</th>
                        <th>Wystawca polisy</th>
                        <th>Klient</th>
                        <th>Doprowadzający</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {policies.map(({id,tu,number,vehicleMark,vehicleModel,vehicleRegistrationNumber,insurancePremium,startedAt,endedAt,userName,clientName, clientLeader,to_do_id}, key) => (
                        <tr>
                            <td>{(page-1) * perPage + key + 1}</td>
                            <td>{tu ?? '-'}</td>
                            <td>{number ?? '-'}</td>
                            <td>{vehicleMark ?? '-'}</td>
                            <td>{vehicleModel ?? '-'}</td>
                            <td>{vehicleRegistrationNumber ?? '-'}</td>
                            <td>{insurancePremium ?? '-'}</td>
                            <td>{startedAt ?? '-'}</td>
                            <td>{endedAt ?? '-'}</td>
                            <td>{userName ?? '-'}</td>
                            <td>{clientName ?? '-'}</td>
                            <td>{clientLeader ?? '-'}</td>
                            <td style={{display: "flex"}}>
                                <StyledButton onClick={() => {
                                    setOpenModal(id);
                                }}>
                                    <StyledFontAwesomeIcon size="2x"  icon={faTrashAlt} fixedWidth/>
                                </StyledButton>
                                {to_do_id !== null && (
                                    <StyledButton onClick={() => {
                                        setDuplicateModal(to_do_id);
                                    }}>
                                        <StyledFontAwesomeIcon size="2x"  icon={faCopy} fixedWidth/>
                                    </StyledButton>
                                )}
                                <StyledLink to={`/polisy/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </StyledPanelContentWrapper>

            {duplicateModal && <Modal closeModal={() => {
                setDuplicateModal(false)
            }}>
                <P>Czy na pewno chcesz wznowić polisę?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.post(`${apiUrl}/toDo/${duplicateModal}/duplicate`,{}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            toast.success("Zadanie zostało zduplikowane.");
                            setRedirect(`/zadania/${response.data.toDo.id}`);
                        }).catch((e)=>{
                            toast.error("Nie możesz zduplikować zadania.");
                        }).finally(()=>{
                            setDuplicateModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setDuplicateModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć polisę?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/policy/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Polisa została usunięta.");
                            setChange(change+1);
                        }).catch((e)=>{
                            if (e.response?.status === 403) {
                                toast.error("Brak pozwolenia");
                            }
                            else{
                                toast.error(e.message);
                            }
                        }).finally(()=>{
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
