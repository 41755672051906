/* eslint-disable react-hooks/exhaustive-deps */
import {Field} from "react-final-form";
import {FileButton, RoundSimpleButton} from "./Form";
import {FieldArray} from "react-final-form-arrays";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";

const FilesContent = styled.div`
    margin-bottom: 20px;
`;
const InputFileWrapper = styled.label`
display: grid;
grid-template-columns: 140px 320px;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
font-size: 1.2rem;
position: relative;
input{
position: absolute;
left: 0;
top: 0;
height: 0;
width: 0;
}
`;
const InputFileWrapperWithoutLabel = styled(InputFileWrapper)`
grid-template-columns: 200px;
min-height: 44px;
align-items: center;
  margin-bottom: 0;
  margin-left: 20px;
`;

const StyledA = styled.a`
    color: black;
    text-decoration: none;
    margin-left: 10px;
`;

const ButtonsWrapper = styled.div`
    
`;

const FileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const TextInput = styled.input`
    border: none;
    height: 30px;
`

export const Files = ({name, setFilesToForm, index}) => {
    const [files, setFiles] = useState([]);

    useEffect(()=>{
        setFilesToForm(files, index)
    },[files])

    return (
        <FilesContent>
            <FieldArray name={name}>
                {({fields},index) => (
                    <>
                        <ButtonsWrapper key={index}>
                            <span>Załączniki:</span>
                            <RoundSimpleButton
                                type="button"
                                onClick={() => fields.push({})}>
                                <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                            </RoundSimpleButton>
                            <RoundSimpleButton
                                type="button"
                                onClick={() => {
                                    fields.pop();
                                    const temp = [...files];
                                    temp.pop();
                                    setFiles(temp);
                                }}>
                                <FontAwesomeIcon icon={faMinus} color="white" size="2x"/>
                            </RoundSimpleButton>
                        </ButtonsWrapper>
                        {fields.map((name, index) => (
                            <FileWrapper key={index}>
                                <Field
                                    name={`${name}.id`}
                                    render={({input}) => (
                                        <input {...input} id={input.name} hidden/>
                                    )}/>
                                <Field
                                    name={`${name}.name`}
                                    render={({input}) => (
                                        <TextInput {...input} id={input.name} placeholder=""/>
                                    )}/>
                                <Field
                                    name={`${name}.file`}
                                    type="file"
                                    render={({input, meta}) => {
                                        let itemFile = files.find(element => (
                                            element.index === index
                                        ));
                                        return <>
                                            <InputFileWrapperWithoutLabel htmlFor={input.name} onChange={e => {
                                                input.onChange(e)
                                                let allFiles = files;
                                                allFiles = allFiles.filter(item => {
                                                    return item.index !== index
                                                })
                                                allFiles.push({
                                                    index:index,
                                                    name: name.replace('.',''),
                                                    file: e.target.files[0],
                                                    url: null
                                                });
                                                setFiles(allFiles)
                                            }}>
                                                <FileWrapper>
                                                    <input {...input} id={input.name}/>
                                                    {(typeof itemFile !== "undefined") ? (
                                                        <FileButton>
                                                            {(itemFile && itemFile.file) ? itemFile.file.name : "Wybierz plik"}
                                                        </FileButton>
                                                    ) : (
                                                        <FileButton>
                                                            Wybierz plik
                                                        </FileButton>
                                                    )}
                                                    <Field
                                                        name={`${name}.url`}
                                                        render={({input}) => {
                                                            if(input.value){
                                                                return (
                                                                    <StyledA href={input.value} rel="noopener noreferrer"
                                                                             target="_blank">Zobacz</StyledA>
                                                                );
                                                            }
                                                        }}/>
                                                </FileWrapper>
                                            </InputFileWrapperWithoutLabel>
                                        </>;
                                    }}
                                />
                            </FileWrapper>
                        ))}
                    </>
                )}
            </FieldArray>
        </FilesContent>
    );
}
