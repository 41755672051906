import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Input, RoundButton, Select, Switch, TopFormWrapper, VariantLabel} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from 'final-form-arrays'
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {downloadFile} from "../../helpers/file";
import {Status} from "../../components/Status";
import {calcMainValue, sumOfValues} from "../../helpers/policyOffer";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    margin-right: 20px;
  }

  & > div:first-child label {
    display: flex;
    max-width: 130px;
    font-size: 1rem;
    padding: 10px 0;
    min-height: 45px;
    margin-bottom: 10px;
    align-items: center;
  }
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 320px;
  align-items: center;
  margin-bottom: 10px;
  height: 45px;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
  grid-template-columns: 240px;
  align-items: center;
  text-align: center;
`;

const InputWrapperWithCheckbox = styled(InputWrapper)`
  grid-template-columns: 40px 200px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

export const PolicyOffer = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [policyOffers, setPolicyOffers] = useState([{selected: false, fields_towing:[]}]);
    const [clientName, setClientName] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/policyOffers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             policyOffers: newPolicyOffers,
                             isActive,
                             clientFullName,
                             statuses: newStatuses,
                             policy_offer_status: newStatus,
                             insuranceCompanies
                         }
                     }) => {
                setIsEnabled(isActive);
                setClientName(clientFullName);
                if (isActive) {
                    setPolicyOffers(newPolicyOffers);
                }
                setStatus(newStatus);
                setStatuses(newStatuses)
                setCompanies(insuranceCompanies);
            });
        }
    }, [token, toDoId]);

    const calcSinglePremium = (value, name, index, form, values, without, force = null) => {
        const mainValue = calcMainValue(value,values,index,without,force);
        const rest = sumOfValues(values, index, without);
        const sum = mainValue + rest;
        form.mutators.setValue(`${name}.singlePremium`, sum.toFixed(2))
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if(item.index === index){
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        }
        else{
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Oferta polisy</h2>
                    {isEnabled && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{policy_offer_status: status}}
                    nameField="policy_offer_status"
                    statuses={statuses}
                    archived={archived}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={values => {
                        const fd = new FormData();
                        createFormData(fd, 'policyOffers', values.policyOffers);
                        if(globalFiles.length > 0){
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`policyOffers[${index}][files][${file.index}][file]`,file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/policyOffers`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {policyOffers: newPolicyOffers, isActive}}) => {
                            setIsEnabled(isActive);
                            if (isActive) {
                                setPolicyOffers(newPolicyOffers);
                            }
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{policyOffers}}
                    render={({handleSubmit, form, values}) => (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <div>
                                    <label></label>
                                    <label>TU</label>
                                    <label>Suma ubezpieczenia</label>
                                    <label>OC</label>
                                    <label>- Ochrona zniżki OC</label>
                                    <label>- BLS</label>
                                    <label>AC + KR</label>
                                    <label>- Stała suma </label>
                                    <label>- Ochrona zniżki AC</label>
                                    <label>- Konsumpcja sumy</label>
                                    <label>- Udział własny</label>
                                    <label>- Każdy: wiek/dł. jazdy</label>
                                    <label>- dla PZU</label>
                                    <label>- Bagaż</label>
                                    <label>- Szkoda kradzieżowa na
                                        terytorium Rosji, Białorusi,
                                        Ukrainy, Mołdawii</label>
                                    <label>NNW</label>
                                    <label>Szyby</label>
                                    <label>Holowanie</label>
                                    <label>Składka jednorazowa</label>
                                    <label>Informacje dodatkowe</label>
                                </div>
                                <FieldArray name="policyOffers">
                                    {({fields}) => (
                                        <>
                                            <RoundButton
                                                type="button"
                                                onClick={() => fields.push({selected: false,fields_towing:[]})}>
                                                <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                            </RoundButton>

                                            {fields.map((name, index) => (
                                                <div>
                                                    <Field
                                                        name={`${name}.id`}
                                                        render={({input, meta}) => (
                                                            <Input {...input} id={input.name} hidden/>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.variantNumber`}
                                                        type="hidden"
                                                        initialValue={index + 1}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </>
                                                        )}
                                                    />

                                                    <Field
                                                        name={`${name}.selected`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <VariantLabel>
                                                                        Wariant {index + 1}
                                                                        <Input {...input} id={input.name}
                                                                               placeholder=""/>
                                                                    </VariantLabel>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.tu`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name} onChange={(e)=>{
                                                                        input.onChange(e);
                                                                        const company = companies.find(item => item.id == e.target.value);
                                                                        if(company && company?.fields_towing){
                                                                            form.mutators.setValue(`${name}.fields_towing`, company.fields_towing)
                                                                        }
                                                                        else{
                                                                            form.mutators.setValue(`${name}.fields_towing`, [])
                                                                        }
                                                                    }}>
                                                                        <option value="">Firma</option>
                                                                        {companies.map(({id, name}) => (
                                                                            <option value={id}>
                                                                                {name}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.sumInsured`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                            //calcSinglePremium(e.target.value, name, index, form, values, 'sumInsured');
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.isOc`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                            calcSinglePremium(values.policyOffers[index].oc, name, index, form, values,'oc', e.target.checked);
                                                                        }}
                                                                    />
                                                                </>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.oc`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input
                                                                        {...input} id={input.name} placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                            calcSinglePremium(e.target.value, name, index, form, values,'oc');
                                                                        }}
                                                                    />
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.isLiabilityDiscountProtection`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(values.policyOffers[index].liabilityDiscountProtection, name, index, form, values,'liabilityDiscountProtection', e.target.checked);
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.liabilityDiscountProtection`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'liabilityDiscountProtection');
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.isBls`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(values.policyOffers[index].bls, name, index, form, values,'bls', e.target.checked);
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.bls`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'bls');
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.isAc`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(values.policyOffers[index].ac, name, index, form, values,'ac', e.target.checked);
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.ac`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'ac');
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.isFixedSum`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(values.policyOffers[index].fixedSum, name, index, form, values,'fixedSum', e.target.checked);
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.fixedSum`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'fixedSum');
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.acDiscountProtection`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.sumConsumption`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <Field
                                                        name={`${name}.ownShare`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'ownShare');
                                                                           }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.lengthDriving`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>


                                                    <Field
                                                        name={`${name}.pzu`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'pzu');
                                                                           }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>


                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.isLuggage`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(values.policyOffers[index].luggage, name, index, form, values,'luggage', e.target.checked);
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                        <Field
                                                            name={`${name}.luggage`}
                                                            type="number"
                                                            parse={value => (value === "" ? null : value)}
                                                            min={0}
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'luggage');
                                                                           }}
                                                                    />
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>


                                                    <InputWrapperWithCheckbox>
                                                        <Field
                                                            name={`${name}.pityTheft`}
                                                            type="checkbox"
                                                            render={({input, meta}) => (
                                                                <>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </>
                                                            )}/>
                                                    </InputWrapperWithCheckbox>

                                                    <Field
                                                        name={`${name}.nnw`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcSinglePremium(e.target.value, name, index, form, values,'nnw');
                                                                           }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.windows`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                            calcSinglePremium(e.target.value, name, index, form, values,'windows');
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.towing`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Holowania</option>
                                                                        {values.policyOffers[index].fields_towing.map((item) => (
                                                                            <option value={item.id}>{item.title}</option>
                                                                        ))}
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.singlePremium`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.additionalInformation`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Files name={`${name}.files`} index={index} setFilesToForm={setFilesToForm}/>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>

                            {!archived && (
                                <StyledButton>Zapisz</StyledButton>
                            )}
                            <StyledButton inactive type="button" onClick={() => {
                                downloadFile(
                                    `${apiUrl}/toDos/${toDoId}/policyOffers/print`,
                                    token,
                                    `${toDoId}-oferta-polisy.pdf`
                                );
                            }}>Drukuj</StyledButton>
                        </form>
                    )}

                />
            </>}
        </>
    );
};
