import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import PanelContentWrapper from "./PanelContentWrapper";

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
color: ${({theme: {colors: {red}}}) => red};
font-size: 2rem;
`;
export const StyledLink = styled(Link)`
display: block;
margin: auto 0 auto auto;
  cursor: pointer;
`

export const StyledPanelContentWrapper = styled(PanelContentWrapper)`
max-height: calc(100vh - 270px);
margin-top: 10px;
`;
