import firebase from 'firebase/app';
import 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyAI6uL6rX22aV7UxaF32U84XskMcvuqKLw",
    authDomain: "bmw-project-e50fd.firebaseapp.com",
    projectId: "bmw-project-e50fd",
    storageBucket: "bmw-project-e50fd.appspot.com",
    messagingSenderId: "1040082653540",
    appId: "1:1040082653540:web:8fdd237e5c62a24a1caa71",
    measurementId: "G-0LPBVNN2Y7"
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = () => {
    return messaging.getToken({vapidKey: 'BHassYy41sZCAeKW8zDIKcSHwJV3fMnc5DphlH3Ljtr8D8zV8BMzmBTZJU2bceidVOXxYPyTYGo3IGW1dhgwccs'});
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
