import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Redirect} from "react-router-dom";
import {Field, Form} from "react-final-form";
import axios from 'axios';
import {Button} from "../components/Buttons";
import Image from '../static/img/image.png';
import {InputFloating} from "../components/Form";
import {apiUrl} from "./api";
import {useSelector} from "react-redux";
import {ButtonLink} from "../components/Links";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
flex-direction: column;
min-height: calc(100vh - 140px);
`;
const StyledImg = styled.img`
  align-self: flex-end;
`;
const StyledForm = styled.form`
max-width: 90%;
width: 460px;
display: flex;
flex-direction: column;
align-items: center;
`;

const ButtonsWrapper = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
margin-bottom: 40px;
`;

const StyledEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  margin: 10px 0;
`;

const StyledDivError = styled.div`
  color: red;
`;


export const ResetPassword = () => {
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);


    useEffect(() => {
        if (token) {
            setRedirect("/dashboard");
        }
    }, [token]);

    const reset = async values => {
        await axios.post(`${apiUrl}/reset`, values, {})
            .then((response)=>{
                toast.success("Wysłano mail potwierdzający");
            })
            .catch((e)=>{
                if(e.response.status === 404){
                    toast.error("Brak użytkownika z danym mailem w bazie.");
                }
                else if(e.response.status === 422){
                    toast.warning("Uzupełnij pola.");
                }
            });
    };


    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <ToastContainer
                position="bottom-right"
            />
            <Wrapper>
                <h1>Przypomnij hasło</h1>
                <Form
                    onSubmit={reset}
                    validate={values => {
                        const errors = {}
                        if (!values.email) {
                            errors.email = 'Login jest wymagany'
                        }
                        return errors
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="email"
                                type="email"
                                render={({input, meta}) => (
                                    <StyledEmailWrapper>
                                        <InputFloating label="Login" {...input} {...meta}/>
                                        {meta.error && meta.touched && <StyledDivError>{meta.error}</StyledDivError>}
                                    </StyledEmailWrapper>
                                )}
                            />
                            <ButtonsWrapper>
                                <ButtonLink to="/login">
                                    Wróć
                                </ButtonLink>
                                <Button>Reset</Button>
                            </ButtonsWrapper>
                        </StyledForm>
                    )}
                />
                <StyledImg src={Image} alt=""/>
            </Wrapper>
        </>
    );
};
