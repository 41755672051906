import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {Button} from "../../components/Buttons";
import {ClientTab} from "./ClientTab";
import {CarTab} from "./CarTab";
import {FinanceTab} from "./FinanceTab";
import {InquiryTab} from "./InquiryTab";
import {PolicyOffer} from "./PolicyOffer";
import {RegistrationTab} from "./RegistrationTab";
import {PolicyTab} from "./PolicyTab";
import {ArchiveTab} from "./ArchiveTab";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {Chat} from "./Chat";

const TabWrapper = styled.div`
display: grid;
grid-template-columns: repeat(8, auto);
grid-gap: 15px;
margin-bottom: 20px;
${Button}{
position: relative;
display: flex;
align-items: center;
justify-content: center;
&:not(:last-child)::after{
content: '';
position: absolute;
right: -15px;
width: 15px;
height: 1px;
background-color: #827E7E;
}
}
`;
const StyledPanelContentWrapper = styled(PanelContentWrapper)`
padding: 30px 60px;
`;

export const EditToDo = ({match}) => {
    const [activeTab, setActiveTab] = useState(1);
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);
    const [archived, setArchived] = useState(false);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        if (match.params.id === "dodaj") {
            axios.post(`${apiUrl}/toDo`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {toDo: {id}}}) => {
                // setClients(newClients);
                setRedirect(`/zadania/${id}`)
            });
        }
        else{
            axios.get(`${apiUrl}/toDo/${match.params.id}/status`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {archive}}) => {
                setArchived(archive);
            });
        }
    }, [match.params.id, token]);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <StyledPanelContentWrapper>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Klient</Button>
                    <Button type="button" inactive={activeTab !== 2} onClick={() => {
                        setActiveTab(2)
                    }}>Samochód/Przedmiot</Button>
                    <Button type="button" inactive={activeTab !== 3} onClick={() => {
                        setActiveTab(3)
                    }}>Finansowanie</Button>
                    <Button type="button" inactive={activeTab !== 4} onClick={() => {
                        setActiveTab(4)
                    }}>Zapytanie ofertowe</Button>
                    <Button type="button" inactive={activeTab !== 5} onClick={() => {
                        setActiveTab(5)
                    }}>Oferta polisy</Button>
                    <Button type="button" inactive={activeTab !== 6} onClick={() => {
                        setActiveTab(6)
                    }}>Rejestracja/Płatność</Button>
                    <Button type="button" inactive={activeTab !== 7} onClick={() => {
                        setActiveTab(7)
                    }}>Polisa</Button>
                    <Button type="button" inactive={activeTab !== 8} onClick={() => {
                        setActiveTab(8)
                    }}>Archwium</Button>
                </TabWrapper>

                {activeTab === 1 && <>
                    <ClientTab toDoId={match.params.id} archived={archived} setActiveTab={setActiveTab} setLoad={setLoad} load={load}/>
                </>}

                {activeTab === 2 && <>
                    <CarTab toDoId={match.params.id} archived={archived}/>
                </>}

                {activeTab === 3 && <>
                    <FinanceTab toDoId={match.params.id} archived={archived}/>
                </>}

                {activeTab === 4 && <>
                    <InquiryTab toDoId={match.params.id} archived={archived}/>
                </>}

                {activeTab === 5 && <>
                    <PolicyOffer toDoId={match.params.id} archived={archived}/>
                </>}

                {activeTab === 6 && <>
                    <RegistrationTab toDoId={match.params.id} archived={archived}/>
                </>}

                {activeTab === 7 && <>
                    <PolicyTab toDoId={match.params.id} archived={archived}/>
                </>}

                {activeTab === 8 && <>
                    <ArchiveTab toDoId={match.params.id} archived={archived}/>
                </>}

                <Chat toDoId={match.params.id} archived={archived}/>
            </StyledPanelContentWrapper>
        </>
    );
};
