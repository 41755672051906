import React, {useEffect, useState} from 'react';
import {FileButton, Input, Label, RoundButton, Select, Switch, TopFormWrapper} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";

const InputWrapper = styled.div`
display: grid;
grid-template-columns: 140px 240px;
align-items: center;
margin-bottom: 10px;
`;

const InputWrapperWithCheckbox = styled(InputWrapper)`
grid-template-columns: 40px 200px;
align-items: center;
justify-content: center;
margin-bottom: 0;
`;

const InputFileWrapper = styled.label`
display: grid;
grid-template-columns: 140px 240px;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
font-size: 1.2rem;
position: relative;
input{
position: absolute;
left: 0;
top: 0;
height: 0;
width: 0;
}
`;

const StyledA = styled.a`
    color: black;
    text-decoration: none;
    margin-left: 10px;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RegistrationTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [registration, setRegistration] = useState({});
    const [clientName, setClientName] = useState(null);
    const [invoiceScan, setInvoiceScan] = useState(null);
    const [newRegistrationNumberScan, setNewRegistrationNumberScan] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/registration`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {registration: newRegistration, isActive, clientFullName,statuses:newStatuses, registration_status:newStatus}}) => {
                setIsEnabled(isActive);
                setRegistration(newRegistration);
                setClientName(clientFullName);
                setStatus(newStatus);
                setStatuses(newStatuses)
            });
        }
    }, [token, toDoId]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if(item.index === index){
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        }
        else{
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => {
                axios.post(`${apiUrl}/toDos/${toDoId}/registration/toggle`, {
                    isActive: !isEnabled,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(({data: {registration: newRegistration}}) => {
                    setRegistration(newRegistration);
                });
                setIsEnabled(!isEnabled)
            }}/>
            <TopFormWrapper>
                <div>
                    <h2>Rejestracja/Płatność</h2>
                    {isEnabled && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item,key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>

            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{registration_status:status}}
                    nameField="registration_status"
                    statuses={statuses}
                    archived={archived}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={values => {
                        console.log(values);
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            let value = values[key] ?? '';
                            if(value === true){
                                value = 1;
                            }
                            else if(value === false){
                                value = 0;
                            }
                            formData.append(key, value)
                        });

                        if(newRegistrationNumberScan){
                            formData.append('newRegistrationNumberScan', newRegistrationNumberScan);
                        }

                        if(invoiceScan){
                            formData.append('invoiceScan', invoiceScan);
                        }

                        createFormData(formData, 'files', values.files);
                        if(globalFiles.length > 0){
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`,file.file);
                                })
                            });
                        }

                        axios.post(`${apiUrl}/toDos/${toDoId}/registration`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {registration: newRegistration, isActive}}) => {
                            setIsEnabled(isActive);
                            setRegistration(newRegistration);
                            setInvoiceScan(null);
                            setNewRegistrationNumberScan(null);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{...registration, isActive: 1}}
                    render={({handleSubmit, form,values}) => (
                        <form onSubmit={handleSubmit}>
                            <InputWrapper>
                                <Label>Podpisanie UL*</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='ifUl'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                    <Field
                                        name='ul'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>

                            <Field
                                name='number'
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Numer umowy*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <InputWrapper>
                                <Label>Wpłata OW*</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='ifOw'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                    <Field
                                        name='ow'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>

                            <InputWrapper>
                                <Label>Zamówienie FV*</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='ifOrderInvoice'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                    <Field
                                        name='orderInvoice'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>

                            <InputWrapper>
                                <Label>Faktura*</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='ifInvoice'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                    <Field
                                        name='invoice'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>


                            <Field
                                name='paymentInvoice'
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Płatność za FV*</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="Przed rejestracją">Przed rejestracją</option>
                                                <option value="Po rejestracji">Po rejestracji</option>
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <InputWrapper>
                                <Label>Zgoda na rejestrację*</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='canRegister'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>

                            <InputWrapper>
                                <Label>Wysyłka dokumentów do rejestracji</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='ifShipDocuments'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                    <Field
                                        name='shipDocuments'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>


                            <InputWrapper>
                                <Label>Dokumenty w Wydziale Komunikacji</Label>
                                <InputWrapperWithCheckbox>
                                    <Field
                                        name='ifDocumentsInFaculty'
                                        type="checkbox"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                    <Field
                                        name='documentsInFaculty'
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </>
                                        )}/>
                                </InputWrapperWithCheckbox>
                            </InputWrapper>


                            <Field
                                name='vin'
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>VIN dla pojazd nowy*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>


                            <Field
                                name='registrationDate'
                                type="date"
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data zarejestrowania*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>


                            <Field
                                name='newRegistrationNumber'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Nowy nr rejestracyjny*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='paidAt'
                                type="date"
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Zapłacono*</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>


                            <Field
                                name='newRegistrationNumberScan_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setNewRegistrationNumberScan(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Nowy dowód rejestracyjny*</span>
                                            <FileWrapper>
                                                <FileButton>{newRegistrationNumberScan ? newRegistrationNumberScan.name : "Wybierz plik"}</FileButton>
                                                {values.newRegistrationNumberScanUrl &&
                                                <StyledA href={values.newRegistrationNumberScanUrl} rel="noopener noreferrer"
                                                         target="_blank">Zobacz</StyledA>}
                                            </FileWrapper>
                                        </InputFileWrapper>
                                    </>
                                )}/>


                            <Field
                                name='invoiceScan_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setInvoiceScan(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Faktura*</span>
                                            <FileWrapper>
                                                <FileButton>{invoiceScan ? invoiceScan.name : "Wybierz plik"}</FileButton>
                                                {values.invoiceScanUrl &&
                                                <StyledA href={values.invoiceScanUrl} rel="noopener noreferrer"
                                                         target="_blank">Zobacz</StyledA>}
                                            </FileWrapper>
                                        </InputFileWrapper>
                                    </>
                                )}/>

                            <Files name="files" index={0} setFilesToForm={setFilesToForm}/>

                            {!archived && (
                                <Button>Zapisz</Button>
                            )}
                        </form>
                    )}
                />
            </>}
        </>
    );
};
