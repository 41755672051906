import {LOGIN, LOGOUT, FETCH_ERROR, NEW_TO_DO} from '../actions';

const initialState = {
    token: localStorage.getItem('token') || null,
    expiresIn: localStorage.getItem('expiresIn') || 0,
    role: localStorage.getItem('role') || null,
    newToDo: localStorage.getItem('newToDo') || 0,
    error: '',
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            // eslint-disable-next-line no-case-declarations
            const {token, expiresIn, role} = action.payload;
            localStorage.setItem('token', token);
            localStorage.setItem('expiresIn', expiresIn);
            localStorage.setItem('role', role);
            return {...state, token, expiresIn, role};
        case FETCH_ERROR:
            return {...state, error: action.payload};
        case LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('expiresIn');
            return {...state, token: '', role: '', expiresIn: 0};
        case NEW_TO_DO:
            const {newToDo} = action.payload;
            localStorage.setItem('newToDo', newToDo);
            return {...state, newToDo: newToDo};
        default:
            return state;
    }
};

export default rootReducer;
