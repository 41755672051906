import React, {useState} from 'react';
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {Button} from "../../components/Buttons";
import {ClientForm} from "./ClientForm";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

const TabWrapper = styled.div`
display: grid;
grid-template-columns: repeat(5, 225px);
grid-gap: 15px;
`;
const H1 = styled.h1`
margin-bottom: 10px;
`;


export const ClientCreate = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <ToastContainer
                position="bottom-right"
            />
            <PanelContentWrapper>
                <H1>Dodaj klienta</H1>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Dane klienta</Button>
                </TabWrapper>

                {activeTab === 1 && <div>
                    <ClientForm
                        onSubmit={formData => {
                            return axios.post(`${apiUrl}/client`, formData, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                }
                            }).then(({data: {client: {id}}}) => {
                                toast.success("Dodanie nowego klienta.");
                                setRedirect(`/klienci/${id}`)
                            }).catch(e => {
                                toast.error("Coś poszło nie tak.");
                                console.log(e.response)
                            });
                        }}
                        initialValues={{}}
                    />
                </div>}
            </PanelContentWrapper>
        </>
    );
};
