import React, {useEffect, useState} from 'react';
import {InputSearch, Table} from "../../components/Table";
import {StyledFontAwesomeIcon, StyledLink, StyledPanelContentWrapper} from "../../components/ListComponents";
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import {perPage} from "../../data/variables";

export const VehiclesList = () => {
    const token = useSelector((store) => store.token);
    const [vehicles, setVehicles] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    let timeout = 0;

    const search = (e) => {
        let searchText = e.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/vehicle`, {
            params: {
                page,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles, lastPage}}) => {
            setVehicles(newVehicles);
            setPages(lastPage);
        });
    }, [token, page, searchParam]);
    return (
        <>
            <div>
                <InputSearch onChange={(e) => {
                    search(e);
                }}/>
            </div>
            <StyledPanelContentWrapper>
                <Table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>ID</th>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Rocznik</th>
                        <th>Nr rejestracyjny</th>
                        <th>Data zakupu</th>
                        <th>Koniec umowy</th>
                        <th>Cena zakupu</th>
                        <th>Sprzedany</th>
                        <th>Rodzaj finansowania</th>
                        <th>Kwota polisy</th>
                        <th>Klient</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map(({id, mark, model, yearbook, registrationNumber, client, net, sold_at: soldAt, leasingPolicy, financingType, purchased_at, end_contract_at, secondNet},key) => (
                        <tr key={id}>
                            <td>{(page-1) * perPage + key + 1}</td>
                            <td>{id}</td>
                            <td>{mark ?? '-'}</td>
                            <td>{model ?? '-'}</td>
                            <td>{yearbook ?? '-'}</td>
                            <td>{registrationNumber ?? '-'}</td>
                            <td>{purchased_at ?? '-'}</td>
                            <td>{end_contract_at ?? '-'}</td>
                            <td>{net ?? '-'}</td>
                            <td>{soldAt ? "Tak" : "Nie"}</td>
                            <td>{financingType ?? '-'}</td>
                            <td>{secondNet ?? '-'}</td>
                            <td>{client ?? '-'}</td>
                            <td style={{display: "flex"}}>
                                <StyledLink to={`/pojazdy/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </StyledPanelContentWrapper>
        </>
    );
};
