import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import axios from "axios";
import {apiUrl} from "../api";
import {Field, Form} from "react-final-form";
import {Label} from "../../components/Form";
import SmartSelect from "react-select";
import {Button} from "../../components/Buttons";
import {Redirect} from "react-router-dom";

const H1 = styled.h1`
  margin-bottom: 10px;
`;

const StyledForm = styled.form`
  margin-top: 25px;
  display: flex;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 140px 320px;
    align-items: center;
    margin-bottom: 10px;
`;

const StyledSmartSelect = styled(SmartSelect)`
    div:first-child {
        border-radius: 20px;
        border: none;
    }
`;

export const InsurancePoliciesCreate = () => {
    const token = useSelector((store) => store.token);
    const [vehicles, setVehicles] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        axios.get(`${apiUrl}/client/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients:newClients}}) => {
            setClients(newClients);
        });
    }, [token]);

    useEffect(() => {
        axios.get(`${apiUrl}/vehicle/all`, {
            params: {
                clientId: clientId
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles:newVehicles}}) => {
            setVehicles(newVehicles);
        });
    }, [token,clientId]);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <H1>Dodaj nową polise</H1>
                <Form
                    onSubmit={values => {
                        const form = {};
                        form.client_id = values.client_id.value;
                        form.vehicle_id = values.vehicle_id.value;
                        axios.post(`${apiUrl}/policy`,form, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {policy}}) => {
                            setRedirect(`/polisy/${policy.id}`);
                        });
                    }}
                    initialValues={{}}
                    validate={values => {
                        const errors = {}
                        if (!values.client_id) {
                            errors.client_id = 'Klient jest wymagany'
                        }
                        if (!values.vehicle_id) {
                            errors.vehicle_id = 'Samochód jest wymagany'
                        }
                        return errors
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <Field
                                    name='client_id'
                                    render={({input}) => (
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Klient</Label>
                                            <StyledSmartSelect {...input} id={input.name}
                                                               searchable
                                                               placeholder="Klient"
                                                               noOptionsMessage={() => 'Brak opcji'}
                                                               options={clients.map(({ id, name}) => {
                                                                   return {
                                                                       value: id,
                                                                       label: name
                                                                   };
                                                               })}
                                                               onChange={e => {
                                                                   input.onChange(e);
                                                                   setClientId(e.value);
                                                               }}
                                            />
                                        </InputWrapper>
                                    )}
                                />
                                {clientId !== null && (
                                    <Field
                                        name='vehicle_id'
                                        render={({input}) => (
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Samochód</Label>
                                                <StyledSmartSelect  {...input} id={input.name}
                                                                   searchable
                                                                   placeholder="Samochód"
                                                                   noOptionsMessage={() => 'Brak opcji'}
                                                                   options={vehicles.map(({ value, label}) => {
                                                                       return {
                                                                           value: value,
                                                                           label: label
                                                                       };
                                                                   })}
                                                />
                                            </InputWrapper>
                                        )}
                                    />
                                )}
                                <Button type="submit">Stwórz polisę</Button>
                            </div>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
