import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {InputFloating, SelectFloating} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Redirect} from "react-router-dom";

const StyledForm = styled.form`
max-width: 100%;
width: 460px;
`;

export const CreateUser = () => {
    const [roles, setRoles] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios.get(`${apiUrl}/role`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {roles: newRoles}}) => {
            setRoles(newRoles);
        });
    }, [token]);
    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <h2>Dodaj użytkownika</h2>
                <Form
                    onSubmit={values => {
                        axios.post(`${apiUrl}/user`, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect(`/uzytkownicy`)
                        });
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="email"
                                type="email"
                                render={({input, meta}) => (
                                    <InputFloating label="Login" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="name"
                                render={({input, meta}) => (
                                    <InputFloating label="Imię i nazwisko" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="phone"
                                render={({input, meta}) => (
                                    <InputFloating label="Numer telefonu" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="password"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Hasło" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="password_confirmation"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Powtórz hasło" {...input} {...meta}/>
                                )}
                            />

                            <Field
                                name="role_id"
                                render={({input, meta}) => (
                                    <SelectFloating label="Uprawnienia" {...input} {...meta}>
                                        <option value="">Uprawienia</option>
                                        {roles.map(({id, name}) => (
                                            <option value={id}>{name}</option>
                                        ))}
                                    </SelectFloating>
                                )}
                            />
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
