import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PanelContentWrapper from "../components/PanelContentWrapper";
import {Field, Form} from "react-final-form";
import {InputFloating} from "../components/Form";
import {Button} from "../components/Buttons";
import axios from "axios";
import {apiUrl} from "./api";
import {useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

const StyledForm = styled.form`
max-width: 100%;
width: 460px;
`;

export const Settings = () => {
    const token = useSelector((store) => store.token);
    const [me, setMe] = useState({});
    const [change, setChange] = useState(0);

    useEffect(() => {
        axios.get(`${apiUrl}/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data}) => {
            setMe(data);
        });
    }, [token,change])

    return (
        <>
            <ToastContainer
                position="bottom-right"
            />
            <PanelContentWrapper>
                <h2>Ustawienia</h2>
                <Form
                    onSubmit={(values, form) => {
                        axios.post(`${apiUrl}/me`, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Twoje dane zostały zaktualizowane.");
                            //form.reset(me);
                            setChange(change+1);
                        }).catch((e)=>{
                            if (e.response?.status === 422) {
                                toast.error("Uzupełnij pola lub popraw hasło.");
                            }
                        });
                    }}
                    initialValues={me}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="email"
                                type="email"
                                render={({input, meta}) => (
                                    <InputFloating label="Login" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="name"
                                render={({input, meta}) => (
                                    <InputFloating label="Imię i nazwisko" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="phone"
                                render={({input, meta}) => (
                                    <InputFloating label="Numer telefonu" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="oldPassword"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Aktualne hasło" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="password"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Nowe hasło" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="password_confirmation"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Powtórz nowe hasło" {...input} {...meta}/>
                                )}
                            />
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
