import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {InputFloating, SelectFloating} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast, ToastContainer} from "react-toastify";

const StyledForm = styled.form`
max-width: 100%;
width: 460px;
`;

export const EditUser = ({match}) => {
    const [roles, setRoles] = useState([]);
    const token = useSelector((store) => store.token);
    const [user, setUser] = useState({});

    useEffect(() => {
        axios.get(`${apiUrl}/role`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {roles: newRoles}}) => {
            setRoles(newRoles);
        });

        axios.get(`${apiUrl}/user/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {user: newUser}}) => {
            setUser(newUser);
        });
    }, [match.params.id, token]);


    return (
        <PanelContentWrapper>
            <ToastContainer
                position="bottom-right"
            />
            <h2>Edytuj użytkownika</h2>
            <Form
                onSubmit={values => {
                    axios.put(`${apiUrl}/user/${match.params.id}`, values,{
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(({data: {user: newUser}}) => {
                        setUser(newUser);
                        toast.success("Twoje dane zostały zapisane");
                    });
                }}
                initialValues={user}
                render={({handleSubmit}) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <Field
                            name="email"
                            type="email"
                            render={({input, meta}) => (
                                <InputFloating label="Login" {...input} {...meta}/>
                            )}
                        />
                        <Field
                            name="name"
                            render={({input, meta}) => (
                                <InputFloating label="Imię i nazwisko" {...input} {...meta}/>
                            )}
                        />
                        <Field
                            name="phone"
                            render={({input, meta}) => (
                                <InputFloating label="Numer telefonu" {...input} {...meta}/>
                            )}
                        />
                        <Field
                            name="role_id"
                            render={({input, meta}) => (
                                <SelectFloating label="Uprawnienia" {...input} {...meta}>
                                    <option value="">Uprawienia</option>
                                    {roles.map(({id, name}) => (
                                        <option value={id}>{name}</option>
                                    ))}
                                </SelectFloating>
                            )}
                        />
                        <Button>Zapisz</Button>
                    </StyledForm>
                )}
            />
        </PanelContentWrapper>
    );
};
