import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {InputSearch, Table} from "../../components/Table";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Pagination} from "@material-ui/lab";
import {Button} from "../../components/Buttons";

export const VehiclesToSelect = ({addVehicleToClient}) => {
    const [vehicles, setVehicles] = useState([]);
    const token = useSelector((store) => store.token);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    let timeout = 0;

    const search = (e) => {
        let searchText = e.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }


    useEffect(() => {
        axios.get(`${apiUrl}/vehicle/withoutClient`, {
            params: {
                page,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles,lastPage}}) => {
            setVehicles(newVehicles);
            setPages(lastPage);
        });
    }, [token,page, searchParam])

    return (
        <>
            <div>
                <div>
                    <InputSearch onChange={(e) => {
                        search(e);
                    }}/>
                </div>
                <Table>
                    <thead>
                    <tr>
                        <th>Nr rejestracyjny</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map(({id, registrationNumber}) => (
                        <tr key={id}>
                            <td>{registrationNumber ?? '-'}</td>
                            <td style={{display: "flex"}}>
                                <Button type="button" onClick={() => {
                                    addVehicleToClient(id);
                                }}>Dodaj</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </div>
        </>
    );
};
