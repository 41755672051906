import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons";
import {Table} from "../../components/Table";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Pagination} from "@material-ui/lab";

export const ClientsVehicles = ({clientId, change}) => {
    const [vehicles, setVehicles] = useState([]);
    const token = useSelector((store) => store.token);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);


    useEffect(() => {
        axios.get(`${apiUrl}/client/${clientId}/vehicle`, {
            params: {
                page
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles,lastPage}}) => {
            setVehicles(newVehicles);
            setPages(lastPage);
        });
    }, [clientId,token,page,change])

    return (
        <>
            <div>
                <Table>
                    <thead>
                    <tr>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Nr rejestracyjny</th>
                        <th>Data zakupu</th>
                        <th>Cena zakupu</th>
                        <th>Instytucja</th>
                        <th>Rodzaj finansowania</th>
                        <th>Aktualna polisa</th>
                        <th>Koniec aktualnej polisy</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map(({id, mark, model, registrationNumber, sold_at: soldAt, institution, financingType, secondNet, policy}) => (
                        <tr>
                            <td>{mark ?? '-'}</td>
                            <td>{model ?? '-'}</td>
                            <td>{registrationNumber}</td>
                            <td>{soldAt ?? '-'}</td>
                            <td>{secondNet ?? '-'}</td>
                            <td>{institution ?? '-'}</td>
                            <td>{financingType ?? '-'}</td>
                            <td>{policy ?? '-'}</td>
                            <td>-</td>
                            <td style={{display: "flex"}}>
                                <StyledLink to={`/pojazdy/${id}`}>
                                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                </StyledLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                    setPage(page);
                }}/>
            </div>
        </>
    );
};
