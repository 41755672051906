import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {
    FileButton,
    Input,
    Label,
    RoundButton,
    Select,
    Switch,
    TopFormWrapper,
    VariantLabel
} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Status} from "../../components/Status";
import {gusApi} from "../../models/api/gusApi";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 320px;
  align-items: center;
  margin-bottom: 10px;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
  grid-template-columns: 200px;
  align-items: center;
  text-align: center;
`;

const InputWrapperWithoutLabelWithButton = styled(InputWrapper)`
  grid-template-columns: 200px 160px;

  button {
    margin-left: 20px;
  }
`;

const NIPWrapper = styled(InputWrapper)`
  grid-template-columns: 140px 320px 160px;

  button {
    margin-left: 20px;
  }
`;

const AddressWrapper = styled.div`
  margin: 10px;
`;

const InputFileWrapper = styled.label`
  display: grid;
  grid-template-columns: 140px 320px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
  }
`;
const InputFileWrapperWithoutLabel = styled(InputFileWrapper)`
  grid-template-columns: 200px;
  min-height: 44px;
  align-items: center;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    margin-right: 20px;
  }
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const calcDiscount = (priceGross, listPrice, form, field) => {
    if (priceGross && listPrice) {
        form.mutators.setValue(field, Math.abs(((priceGross / listPrice - 1) * 100)).toFixed(0))
    }
}

export const CarTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [vehicles, setVehicles] = useState([
        {
            selected: false,
        }
    ]);
    const [files, setFiles] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [clientName, setClientName] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [address, setAddress] = useState([]);

    const resetFiles = (newVehicles) => {
        let allFiles = [];
        newVehicles.forEach(({attachmentUrl}, index) => {
            allFiles.push({
                index: index,
                name: null,
                file: null,
                url: attachmentUrl
            })
        })
        setFiles(allFiles);
    }

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/vehicles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             vehicles: newVehicles,
                             isActive,
                             clientFullName,
                             statuses: newStatuses,
                             vehicle_status: newStatus
                         }
                     }) => {
                setIsEnabled(isActive);
                setClientName(clientFullName);
                if (isActive) {
                    setVehicles(newVehicles);
                }
                setStatus(newStatus);
                setStatuses(newStatuses)
                resetFiles(newVehicles);
            });
        }
    }, [token, toDoId]);

    const gus = (nip, form, inputName) => {
        gusApi.get(nip, token).then((response) => {
            if (response.status === 200) {
                const gusData = response.data.gus;
                form.mutators.setValue(inputName, gusData.name)
                setAddress(`${gusData.street} ${gusData.propertyNumber}, ${gusData.city}, ${gusData.zipCode}`)
                toast.success("Pobrano dane z GUS");
            }
        })
            .catch((e) => {
                if (e.response?.status === 404) {
                    toast.error("Nie można pobrać danych z podanego NIP.");
                } else {
                    console.log(e);
                    toast.error("Coś poszło nie tak.");
                }
            });
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        // eslint-disable-next-line eqeqeq
        if (globalFiles.some(e => e.index == index)) {
            let newArr = [...globalFiles].map((item) => {
                // eslint-disable-next-line eqeqeq
                if (item.index == index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Dodaj Samochód</h2>
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>{item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{vehicle_status: status}}
                    nameField="vehicle_status"
                    statuses={statuses}
                    archived={archived}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={values => {
                        const fd = new FormData();
                        createFormData(fd, 'vehicles', values.vehicles);
                        if (files.length > 0) {
                            files.forEach(({name, file}) => {
                                fd.append(`${name}[attachment]`, file);
                            });
                        }

                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`vehicles[${index}][files][${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/vehicles`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {vehicles: newVehicles, isActive}}) => {
                            setIsEnabled(isActive);
                            if (isActive) {
                                setVehicles(newVehicles);
                            }
                            resetFiles(newVehicles);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{
                        vehicles
                    }}
                    render={({handleSubmit, form, values, initialValues}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <FieldArray name="vehicles">
                                {({fields}) => (
                                    <>
                                        <RoundButton
                                            type="button"
                                            onClick={() => fields.push({selected: false,})}>
                                            <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                        </RoundButton>
                                        {fields.map((name, index) => index !== 0 ? (
                                                <div key={index}>
                                                    <Field
                                                        name={`${name}.id`}
                                                        type="hidden"
                                                        render={({input, meta}) => (
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.variantNumber`}
                                                        type="hidden"
                                                        initialValue={index + 1}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.selected`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <VariantLabel>
                                                                        Wariant {index + 1}
                                                                        <Input {...input} id={input.name} placeholder=""/>
                                                                    </VariantLabel>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.source`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Pochodzenie</option>
                                                                        <option value="Poszukiwanie">Poszukiwanie</option>
                                                                        <option value="Stock">Stock</option>
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.item`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Rodzaj przedmiotu</option>
                                                                        <option value="Samochód osobowy">Samochód osobowy
                                                                        </option>
                                                                        <option value="Samochód ciężarowy do 3,5 t">Samochód
                                                                            ciężarowy do
                                                                            3,5 t
                                                                        </option>
                                                                        <option
                                                                            value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                                            ciężarowy
                                                                            powyżej 3,5 t
                                                                        </option>
                                                                        <option value="Ciągnik samochodowy">Ciągnik
                                                                            samochodowy
                                                                        </option>
                                                                        <option value="Naczepa">Naczepa</option>
                                                                        <option value="Przyczepa ">Przyczepa</option>
                                                                        <option value="Maszyna">Maszyna</option>
                                                                        <option value="Motocykl">Motocykl</option>
                                                                        <option value="Inne">Inne</option>
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.condition`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value={null}>Stan</option>
                                                                        <option value="Nowy">Nowy</option>
                                                                        <option value="Nowy zarejestrowany">Nowy
                                                                            zarejestrowany
                                                                        </option>
                                                                        <option value="Używany">Używany</option>
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.mark`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.model`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.capacity`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.yearbook`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.firstRegistration`}
                                                        type="date"
                                                        initialValue={null}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.registrationNumber`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.vin`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.equipmentVersion`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.mileage`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.salesDocument`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Dokument sprzedaży</option>
                                                                        <option value="FV VAT23%">FV VAT23%</option>
                                                                        <option value="FV Marża">FV Marża</option>
                                                                        <option value="Umowa sprzedaży">Umowa sprzedaży
                                                                        </option>
                                                                        <option value="Inny">Inny</option>
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.listPrice`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           step="0.01" onChange={(e) => {
                                                                        input.onChange(e);
                                                                        calcDiscount(values.vehicles[index].gross, parseFloat(e.target.value), form, `${name}.discount`);
                                                                    }}/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.net`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                   const netValue = parseFloat(e.target.value);
                                                                                   const grossValue = netValue + netValue * 0.23;
                                                                                   form.mutators.setValue(`${name}.gross`, grossValue.toFixed(2))
                                                                               }
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.gross`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                   const grossValue = parseFloat(e.target.value);
                                                                                   const netValue = grossValue - grossValue * 0.23;
                                                                                   form.mutators.setValue(`${name}.net`, netValue.toFixed(2))
                                                                               }
                                                                               calcDiscount(parseFloat(e.target.value), values.vehicles[index].listPrice, form, `${name}.discount`);
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.discount`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           step="0.01"/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.provider`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.nip`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabelWithButton>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    <Button type="button" inactive small onClick={() => {
                                                                        if (values.vehicles[index].nip) {
                                                                            gus(values.vehicles[index].nip, form, `${name}.provider`);
                                                                        } else {
                                                                            toast.error("Prosze uzupełnij NIP.");
                                                                        }
                                                                    }}>Pobierz dane</Button>
                                                                </InputWrapperWithoutLabelWithButton>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.file`}
                                                        type="file"
                                                        render={({input, meta}) => {
                                                            let itemFile = files.find(element => (
                                                                element.index === index
                                                            ));
                                                            return <>
                                                                <InputFileWrapperWithoutLabel htmlFor={input.name}
                                                                                              onChange={e => {
                                                                                                  input.onChange(e)
                                                                                                  let allFiles = files;
                                                                                                  allFiles = allFiles.filter(item => {
                                                                                                      return item.index !== index
                                                                                                  })
                                                                                                  allFiles.push({
                                                                                                      index: index,
                                                                                                      name: name,
                                                                                                      file: e.target.files[0],
                                                                                                      url: null
                                                                                                  });
                                                                                                  setFiles(allFiles)
                                                                                              }}>
                                                                    <FileWrapper>
                                                                        <input {...input} id={input.name}/>
                                                                        {(typeof itemFile !== "undefined") ? (
                                                                            <FileButton>
                                                                                {(itemFile && itemFile.file) ? itemFile.file.name : "Wybierz plik"}
                                                                            </FileButton>
                                                                        ) : (
                                                                            <FileButton>
                                                                                Wybierz plik
                                                                            </FileButton>
                                                                        )}
                                                                        {(itemFile?.url) && (
                                                                            <StyledA href={itemFile.url}
                                                                                     rel="noopener noreferrer"
                                                                                     target="_blank">Zobacz</StyledA>
                                                                        )}
                                                                    </FileWrapper>
                                                                </InputFileWrapperWithoutLabel>
                                                            </>;
                                                        }}
                                                    />

                                                    <Files name={`${name}.files`} index={index}
                                                           setFilesToForm={setFilesToForm}/>

                                                </div>
                                            ) : (
                                                <div key={index}>
                                                    <Field
                                                        name={`${name}.id`}
                                                        type="hidden"
                                                        render={({input, meta}) => (
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.variantNumber`}
                                                        type="hidden"
                                                        initialValue={index + 1}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.selected`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <VariantLabel>
                                                                        Wariant 1
                                                                        <Input {...input} id={input.name} placeholder=""/>
                                                                    </VariantLabel>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.source`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Pochodzenie*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Pochodzenie</option>
                                                                        <option value="Poszukiwanie">Poszukiwanie</option>
                                                                        <option value="Stock">Stock</option>
                                                                    </Select>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.item`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Rodzaj przedmiotu*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Rodzaj przedmiotu</option>
                                                                        <option value="Samochód osobowy">Samochód osobowy
                                                                        </option>
                                                                        <option value="Samochód ciężarowy do 3,5 t">Samochód
                                                                            ciężarowy do
                                                                            3,5 t
                                                                        </option>
                                                                        <option
                                                                            value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                                            ciężarowy
                                                                            powyżej 3,5 t
                                                                        </option>
                                                                        <option value="Ciągnik samochodowy">Ciągnik
                                                                            samochodowy
                                                                        </option>
                                                                        <option value="Naczepa">Naczepa</option>
                                                                        <option value="Przyczepa ">Przyczepa</option>
                                                                        <option value="Maszyna">Maszyna</option>
                                                                        <option value="Motocykl">Motocykl</option>
                                                                        <option value="Inne">Inne</option>
                                                                    </Select>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.condition`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Stan*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Stan</option>
                                                                        <option value="Nowy">Nowy</option>
                                                                        <option value="Nowy zarejestrowany">Nowy
                                                                            zarejestrowany
                                                                        </option>
                                                                        <option value="Używany">Używany</option>
                                                                    </Select>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.mark`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Marka*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.model`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Model*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.capacity`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Pojemność</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.yearbook`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Rocznik*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.firstRegistration`}
                                                        type="date"
                                                        initialValue={null}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Data 1 rejestracji*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.registrationNumber`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Numer rejestracyjny</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.vin`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>VIN</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.equipmentVersion`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wersja wyposażenia</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.mileage`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Przebieg</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.salesDocument`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Dokument sprzedaży*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Dokument sprzedaży</option>
                                                                        <option value="FV VAT23%">FV VAT23%</option>
                                                                        <option value="FV Marża">FV Marża</option>
                                                                        <option value="Umowa sprzedaży">Umowa sprzedaży
                                                                        </option>
                                                                        <option value="Inny">Inny</option>
                                                                    </Select>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.listPrice`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Cena katalogowa</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               calcDiscount(values.vehicles[index].gross, parseFloat(e.target.value), form, `${name}.discount`);
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.net`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Cena netto*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                   const netValue = parseFloat(e.target.value);
                                                                                   const grossValue = netValue + netValue * 0.23;
                                                                                   form.mutators.setValue(`${name}.gross`, grossValue.toFixed(2))
                                                                               }
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.gross`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Cena brutto*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                   const grossValue = parseFloat(e.target.value);
                                                                                   const netValue = grossValue / 1.23;
                                                                                   form.mutators.setValue(`${name}.net`, netValue.toFixed(2))
                                                                               }
                                                                               calcDiscount(parseFloat(e.target.value), values.vehicles[index].listPrice, form, `${name}.discount`);
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.discount`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Rabat%</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           step="0.01"/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.provider`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Dostawca</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.nip`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <NIPWrapper>
                                                                    <Label htmlFor={input.name}>NIP dostawcy</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>

                                                                    <Button type="button" inactive small onClick={() => {
                                                                        if (values.vehicles[index].nip) {
                                                                            gus(values.vehicles[index].nip, form, `${name}.provider`);
                                                                        } else {
                                                                            toast.error("Prosze uzupełnij NIP.");
                                                                        }
                                                                    }}>Pobierz dane</Button>
                                                                </NIPWrapper>
                                                                {address && (
                                                                    <AddressWrapper>
                                                                        <span>{address}</span>
                                                                    </AddressWrapper>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.file`}
                                                        type="file"
                                                        render={({input, meta}) => {
                                                            let itemFile = files.find(element => (
                                                                element.index === index
                                                            ));
                                                            return <>
                                                                <InputFileWrapper htmlFor={input.name} onChange={e => {
                                                                    input.onChange(e)
                                                                    let allFiles = files;
                                                                    allFiles = allFiles.filter(item => {
                                                                        return item.index !== index
                                                                    })
                                                                    allFiles.push({
                                                                        index: index,
                                                                        name: name,
                                                                        file: e.target.files[0],
                                                                        url: null
                                                                    });
                                                                    setFiles(allFiles)
                                                                }}>
                                                                    <span>Załącznik</span>
                                                                    <FileWrapper>
                                                                        <input {...input} id={input.name}/>
                                                                        {(typeof itemFile !== "undefined") ? (
                                                                            <FileButton>
                                                                                {(itemFile && itemFile.file) ? itemFile.file.name : "Wybierz plik"}
                                                                            </FileButton>
                                                                        ) : (
                                                                            <FileButton>
                                                                                Wybierz plik
                                                                            </FileButton>
                                                                        )}
                                                                        {(itemFile?.url) && (
                                                                            <StyledA href={itemFile.url}
                                                                                     rel="noopener noreferrer"
                                                                                     target="_blank">Zobacz</StyledA>
                                                                        )}
                                                                    </FileWrapper>
                                                                </InputFileWrapper>

                                                                <Files name={`${name}.files`} index={index}
                                                                       setFilesToForm={setFilesToForm}
                                                                       url={initialValues.vehicles?.files?.url}/>
                                                            </>;
                                                        }}
                                                    />
                                                    {!archived && (
                                                        <Button>Zapisz</Button>
                                                    )}
                                                </div>
                                            )
                                        )}

                                    </>
                                )}
                            </FieldArray>
                        </StyledForm>
                    )}
                />
            </>}
        </>
    );
};
