import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {FileButton, Input, Label, RoundSimpleButton, Select} from "../../components/Form";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Modal} from "../../components/Modal";
import {toast} from "react-toastify";
import {gusApi} from "../../models/api/gusApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faMinus} from "@fortawesome/pro-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";
import SmartSelect from "react-select";

const Grid = styled.form`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: flex-start;
`;
const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 140px 320px;
    align-items: center;
    margin-bottom: 10px;
`;
const InputFileWrapper = styled.label`
    display: grid;
    grid-template-columns: 140px auto auto;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
    position: relative;
    input{
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        width: 0;
    }
`;

const StyledSmartSelect = styled(SmartSelect)`
    div:first-child {
        border-radius: 20px;
        border: none;
    }
`;

const StyledA = styled.a`
color: black;
text-decoration: none;
margin-left: 10px;
`;

const StyledUsersList = styled.div`
margin: 10px auto;
span{
margin-right: 10px;
}
`;

const StyledSearchWrapper = styled.div`
  width: 300px;
  display: flex;
`;
const RepresentativesWrapper = styled.div`
      display: flex;
      flex-direction: row;
      max-width: 1000px;
      flex-wrap: wrap;
`;

const RepresentativeWrapper = styled.div`
  margin-bottom: 15px;
  margin-right: 20px;
`;

export const ClientTab = ({toDoId, archived, setActiveTab, load, setLoad}) => {
    const token = useSelector((store) => store.token);
    const [toDo, setToDo] = useState({});
    const [krsFile, setKrsFile] = useState(null);
    const [rodoFile, setRodoFile] = useState(null);
    const [marketingFile, setMarketingFile] = useState(null);
    const [modal, setModal] = useState(false);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);

    let timeout = 0;

    const getClients = (search = null) => {
        axios.get(`${apiUrl}/search/client`, {
            params: {
                name: search,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients}}) => {
            setClients(clients);
        });
    }

    const search = (e) => {
        let searchText = e.target.value;
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            getClients(searchText);
        }, 500);
    }

    const gus = (nip, form) => {
        gusApi.get(nip,token).then((response) => {
            if(response.status === 200){
                const gusData = response.data.gus;
                form.mutators.setValue('client_regon',gusData.regon)
                form.mutators.setValue('client_name',gusData.name)
                form.mutators.setValue('client_street', `${gusData.street} ${gusData.propertyNumber}`)
                form.mutators.setValue('client_postalCode',gusData.zipCode)
                form.mutators.setValue('client_city',gusData.city)
                toast.success("Pobrano dane z GUS");
            }
        })
            .catch((e) => {
                if(e.response.status === 404){
                    toast.error("Nie można pobrać danych z podanego NIP.");
                }
                else{
                    toast.error("Coś poszło nie tak.");
                }
            });
    }

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDo/${toDoId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {toDo:newToDo,lastTab}}) => {
                setToDo(newToDo);
                if(lastTab !== 1 && load){
                    setLoad(false);
                    setActiveTab(lastTab);
                }
            });

            axios.get(`${apiUrl}/search/client`, {
                params: {
                    name: null,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {clients}}) => {
                setClients(clients);
            });

            axios.get(`${apiUrl}/user/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {users:newUsers}}) => {
                setUsers(newUsers);
            });
        }
    }, [toDoId, token])


    return (
        <>
            <h2>Dodaj Klienta</h2>
            <Button inactive disabled={archived} onClick={() => {
                setModal(true);
            }}>Wybierz klienta z bazy</Button>
            <Form
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    },
                    ...arrayMutators
                }}
                onSubmit={values => {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                        formData.append(key, values[key] ?? '')
                    })
                    createFormData(formData, 'representatives', values.representatives);
                    createFormData(formData, 'client_clients', values.client_clients);
                    formData.append('_method', 'PUT')
                    if (krsFile) {
                        formData.append('krsFile', krsFile);
                    }
                    if (rodoFile) {
                        formData.append('rodoFile', rodoFile);
                    }
                    if (marketingFile) {
                        formData.append('marketingFile', marketingFile);
                    }
                    axios.post(`${apiUrl}/toDo/${toDoId}`, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(({data: {toDo}}) => {
                        toast.success("Twoje dane zostały zapisane.");
                        setToDo(toDo);
                        setKrsFile(null);
                        setRodoFile(null);
                        setMarketingFile(null);
                    });
                }}
                initialValues={toDo}
                render={({form,handleSubmit, values, submitting}) => (
                    <Grid onSubmit={handleSubmit}>
                        <div>
                            <Field
                                name='client_nip'
                                type="number"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>NIP</Label>
                                            <Input {...input} id={input.name} placeholder="NIP"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_activity'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Działalność</Label>
                                            <Select {...input} id={input.name} disabled={toDo.clientId}>
                                                <option value="">Działalność</option>
                                                <option value="JDG">JDG</option>
                                                <option value="Sp z o.o.">Sp z o.o.</option>
                                                <option value="Sp z o.o. Sp. K">Sp z o.o. Sp. K</option>
                                                <option value="S.C.">S.C.</option>
                                                <option value="Gospodarstwo rolne">Gospodarstwo rolne</option>
                                                <option value="Rolnik Indywidualny">Rolnik Indywidualny</option>
                                                <option value="Osoba Fizyczna">Osoba Fizyczna</option>
                                                <option value="Inne">Inne</option>
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_name'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Imię i nazwisko/Firma</Label>
                                            <Input {...input} id={input.name} placeholder="Imię i nazwisko/Firma"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_regon'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Regon</Label>
                                            <Input {...input} id={input.name} placeholder="Regon"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_krs'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>KRS</Label>
                                            <Input {...input} id={input.name} placeholder="KRS"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_street'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Ulica i numer lokalu</Label>
                                            <Input {...input} id={input.name} placeholder="Ulica i numer lokalu"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_postalCode'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Kod pocztowy</Label>
                                            <Input {...input} id={input.name} placeholder="Kod pocztowy"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_city'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Miejscowość</Label>
                                            <Input {...input} id={input.name} placeholder="Miejscowość"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_phone'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Numer telefonu</Label>
                                            <Input {...input} id={input.name} placeholder="Numer telefonu"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_email'
                                type="email"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>E-mail</Label>
                                            <Input {...input} id={input.name} placeholder="E-mail"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_acquiredDate'
                                type="date"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Data pozyskania</Label>
                                            <Input {...input} id={input.name} placeholder="Data pozyskania"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_leader'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Doprowadzający</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="">Doprowadzający</option>
                                                {users.map((user) => (
                                                    <option value={user.name}>{user.name}</option>
                                                ))}
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name='client_comments'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Uwagi</Label>
                                            <Input {...input} id={input.name} placeholder="Uwagi"
                                                   disabled={toDo.clientId}/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='client_clients'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Powiązani klienci</Label>
                                            <StyledSmartSelect {...input} id={input.name}
                                                               isMulti
                                                               searchable
                                                               placeholder="Powiązani klienci"
                                                               noOptionsMessage={() => 'Brak opcji'}
                                                               options={clients.map(({ id, name}) => {
                                                                   return {
                                                                       value: id,
                                                                       label: name
                                                                   };
                                                               })}
                                            />
                                        </InputWrapper>
                                    </>
                                )}
                            />

                            <Field
                                name='krs_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setKrsFile(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>CEIDG/KRS</span>
                                            <FileButton>{krsFile ? krsFile.name : "Wybierz plik"}</FileButton>
                                            {values.client_krsFile && <StyledA href={values.client_krsFile} rel="noopener noreferrer"
                                                                    target="_blank">Zobacz</StyledA>}
                                        </InputFileWrapper>
                                    </>
                                )}/>

                            <Field
                                name='rodo_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setRodoFile(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Wniosek/RODO</span>
                                            <FileButton>{rodoFile ? rodoFile.name : "Wybierz plik"}</FileButton>
                                            {values.client_rodoFile && <StyledA href={values.client_rodoFile} rel="noopener noreferrer"
                                                                     target="_blank">Zobacz</StyledA>}
                                        </InputFileWrapper>
                                    </>
                                )}/>

                            <Field
                                name='marketing_path'
                                type="file"
                                render={({input, meta}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setMarketingFile(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Zgoda marketingowa</span>
                                            <FileButton>{marketingFile ? marketingFile.name : "Wybierz plik"}</FileButton>
                                            {values.client_marketingFile &&
                                            <StyledA href={values.client_marketingFile} rel="noopener noreferrer"
                                                     target="_blank">Zobacz</StyledA>}
                                        </InputFileWrapper>
                                    </>
                                )}/>
                            {!archived && (
                                <Button>Zapisz</Button>
                            )}
                        </div>
                        <Button type="button" inactive small disabled={!!toDo.clientId} onClick={() => {
                            if(values.client_nip){
                                gus(values.client_nip, form);
                            }
                            else{
                                toast.error("Prosze uzupełnij NIP.");
                            }
                        }}>Pobierz dane</Button>

                        <FieldArray name="representatives">
                            {({fields}) => (
                                <div>
                                    <RoundSimpleButton
                                        type="button"
                                        onClick={() => fields.push({})}>
                                        <FontAwesomeIcon icon={faPlus} color="white" size="2x"/>
                                    </RoundSimpleButton>
                                    <RoundSimpleButton
                                        type="button"
                                        onClick={() => fields.pop()}>
                                        <FontAwesomeIcon icon={faMinus} color="white" size="2x"/>
                                    </RoundSimpleButton>
                                    <h3>Reprezentanci</h3>
                                    <RepresentativesWrapper>
                                        {fields.map((name, index) => (
                                            <RepresentativeWrapper key={index}>
                                                <Field
                                                    name={`${name}.id`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Input {...input} id={input.name} hidden/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.name`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Imię</Label>
                                                                <Input {...input} id={input.name} placeholder="Imię"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>

                                                <Field
                                                    name={`${name}.last_name`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Nazwisko</Label>
                                                                <Input {...input} id={input.name} placeholder="Nazwisko"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.pesel`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Pesel</Label>
                                                                <Input {...input} id={input.name} placeholder="Pesel"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.street`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Ulica</Label>
                                                                <Input {...input} id={input.name} placeholder="Ulica"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.apartment_number`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Numer lokalu</Label>
                                                                <Input {...input} id={input.name} placeholder="Numer lokalu"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.postCode`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Kod pocztowy</Label>
                                                                <Input {...input} id={input.name} placeholder="Kod pocztowy"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.city`}
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Miejscowość</Label>
                                                                <Input {...input} id={input.name} placeholder="Miejscowość"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                                <Field
                                                    name={`${name}.phone`}
                                                    type="tel"
                                                    render={({input, meta}) => (
                                                        <>
                                                            <InputWrapper>
                                                                <Label htmlFor={input.name}>Numer telefonu</Label>
                                                                <Input {...input} id={input.name} placeholder="Numer telefonu"/>
                                                            </InputWrapper>
                                                        </>
                                                    )}/>
                                            </RepresentativeWrapper>
                                        ))}
                                    </RepresentativesWrapper>

                                </div>
                            )}
                        </FieldArray>
                    </Grid>
                )}
            />

            {modal && <Modal closeModal={() => {
                setModal(false);
            }}>
                <StyledSearchWrapper>
                    <Input name="search" placeholder="Szukaj" onChange={e => search(e)}/>
                </StyledSearchWrapper>
                {clients.map(({id, name}) => (
                    <StyledUsersList>
                        <span>{name}</span>
                        <Button inactive small smallText onClick={() => {
                            axios.put(`${apiUrl}/toDo/${toDoId}/select`, {
                                client_id: id,
                            }, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                }
                            }).then(({data: {toDo}}) => {
                                toast.success("Wybrano klienta.");
                                setToDo(toDo);
                                setModal(false);
                                setKrsFile(null);
                                setRodoFile(null);
                                setMarketingFile(null);
                            });
                        }}>Wybierz
                        </Button>
                    </StyledUsersList>
                ))}

            </Modal>}
        </>
    );
};
