import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Link} from "../../components/Link";

const StyledButton = styled.button`
display: flex;
align-items: center;
justify-content: center;
position: fixed;
top:200px;
right: 0;
height: 600px;
background-color: #827E7E;
padding: 10px;
color: ${({theme: {colors: {white}}}) => white};
border: none;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
transition: transform .3s ease-in-out;
transform: rotate(${({isOpen}) => isOpen ? '180deg' : 0});
`;

const StyledChat = styled.div`
display: flex;
justify-content: space-between;
flex-direction: column;
position: fixed;
top:200px;
right: 0;
height: 600px;
padding: 10px 55px 10px 10px;
background-color: ${({theme: {colors: {white}}}) => white};
border: none;
border-bottom-left-radius: 20px;
border-top-left-radius: 20px;
transition: transform .3s ease-in-out;
transform: translateX(${({isOpen}) => isOpen ? 0 : "320px"});
width: 365px;
`;

const ChatHeader = styled(PanelContentWrapper)`
height: auto;
margin: 0 0 auto;
background-color: #DDDBDB;
flex-shrink: 0;
&>span{
font-size: 1.4rem;
font-weight: 600;
}
`;
const ChatHeaderInput = styled.input`
position: absolute;
left: 0;
width: 0;
height: 0;
z-index: -1000;
~label{
display: flex;
align-items: center;
justify-content: flex-start;
flex-direction: row;
font-size: 1.2rem;
margin-bottom: 5px;
&::before{
content: '';
width: 5px;
height: 15px;
background-color: ${({theme: {colors: {white}}}) => white};
margin-right: 5px;
}
}
&:checked{
~label::before{
background-color: ${({theme: {colors: {black}}}) => black};
}
}
`
const StyledUl = styled.ul`
list-style: none;
`

const StyledTextarea = styled.textarea`
resize: none;
width: 100%;
border-radius: 20px;
padding: 10px;
`;

const InputFileButton = styled.label`
display: flex;
justify-content: center;
align-items: center;
font-size: 1.2rem;
position: relative;
padding: 7px 22px;
background: ${({theme: {colors: {darkGray}}}) => darkGray};
color: ${({theme: {colors: {white}}}) => white};
margin-right: 10px;
input{
position: absolute;
left: 0;
top: 0;
height: 1px;
width: 1px;
}
`;

const ChatButtons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
margin-top: 5px;
`;

const StyledMessagesList = styled.ul`
list-style: none;
overflow-y: scroll;
margin: 10px 0;
padding: 0 10px;
li{
padding: 10px 10px 15px;
display: block;
border-bottom: 1px solid #F0F0F0;
font-size: 1.4rem;
}
  div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
h6{
font-size: 1.4rem;
font-weight: 700;
}
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled.form`
flex-shrink: 0;
`;


export const Chat = ({toDoId, archived}) => {
    const [openChat, setOpenChat] = useState(false);
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [messageFile, setMessageFile] = useState(null);
    const messagesEndRef = useRef(null)
    const token = useSelector((store) => store.token);

    const scrollToBottom = () => {
        // eslint-disable-next-line no-unused-expressions
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/users`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {users: newUsers}}) => {
                setUsers(newUsers);
            })

            axios.get(`${apiUrl}/toDos/${toDoId}/messages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {messages: newMessages}}) => {
                setMessages(newMessages);
            }).finally(()=>{
                scrollToBottom();
            });

            if(!archived){
                setInterval(() => {
                    axios.get(`${apiUrl}/toDos/${toDoId}/messages`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(({data: {messages: newMessages}}) => {
                        setMessages(newMessages);
                    }).finally(()=>{
                        scrollToBottom();
                    });
                }, 60000 * 3);
            }
        }

    }, [token, toDoId, archived]);


    return (
        <>
            <StyledChat isOpen={openChat}>
                <ChatHeader>
                    <span>Sprawa widoczna dla</span>
                    <StyledUl>
                        {users.map(({name, id: userId, active}) => (
                            <li key={userId}>
                                <ChatHeaderInput
                                    type="checkbox" checked={active} name="" id={`user${userId}`}
                                    onChange={() => {
                                        axios.post(`${apiUrl}/toDos/${toDoId}/users`, {
                                            userId,
                                        }, {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                            }
                                        }).then(({data: {users: newUsers}}) => {
                                            setUsers(newUsers);
                                        })
                                    }}/>
                                <label htmlFor={`user${userId}`}>
                                    {name}
                                </label>
                            </li>
                        ))}
                    </StyledUl>
                </ChatHeader>
                <StyledMessagesList>
                    {messages.map(({id, name, date, content, url}) => (
                        <li key={id}>
                            <div>
                                <h6>{name}</h6>
                                <h6>{date}</h6>
                            </div>
                            <MessageWrapper>
                                <span>{content}</span>

                                {url && (
                                    <Link url={url}/>
                                )}
                            </MessageWrapper>
                        </li>
                    ))}
                    <div ref={messagesEndRef} />
                </StyledMessagesList>
                <Form
                    onSubmit={(values, form) => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        });
                        if(messageFile){
                            formData.append('file', messageFile);
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/messages`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {messages: newMessages}}) => {
                            setMessages(newMessages);
                            scrollToBottom();
                            setMessageFile(null);
                            form.reset();
                        }).catch((e)=>{
                            if (e.response?.status === 422) {
                                toast.error("Wiadomość musi mieć treść");
                            }
                            else{
                                toast.error('Coś poszło nie tak.');
                            }
                        })
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name='content'
                                render={({input, meta}) => (
                                    <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                )}/>
                            <ChatButtons>
                                <Field
                                    name='file_path'
                                    type="file"
                                    render={({input, meta}) => (
                                        <>
                                            <InputFileButton htmlFor={input.name} onChange={(e) => {
                                                input.onChange(e);
                                                setMessageFile(e.target.files[0]);
                                            }}>
                                                <input {...input} id={input.name}/>
                                                {messageFile ? messageFile.name : "Wybierz plik"}
                                            </InputFileButton>
                                        </>
                                    )}/>


                                <Button type="submit" disabled={archived} small smallText>Zapisz</Button>
                            </ChatButtons>
                        </StyledForm>
                    )}
                />
            </StyledChat>
            <StyledButton onClick={() => {
                setOpenChat(!openChat)
            }}>
                <StyledFontAwesomeIcon icon={faChevronLeft} size="3x" isOpen={openChat}/>
            </StyledButton>
        </>
    );
};
